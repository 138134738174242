export const CONTRACTED_SERVICE_INPATIENT = 'INPATIENT';
export const CONTRACTED_SERVICE_OUTPATIENT = 'OUTPATIENT';
export const CONTRACTED_SERVICE_PROFESSIONAL = 'PROFESSIONAL';
export const CONTRACTED_SERVICE_BOTH = 'BOTH';

export const PAY_METHOD_2ND_DOLLAR = '2nd Dollar';
export const PAY_METHOD_MAXIMUM_CASE_RATE = 'Maximum Case Rate';
export const PAY_METHOD_MAXIMUM_CHARGE_RATE = 'Maximum Charge Rate'
export const PAY_METHOD_STOP_LOSS = 'Stop Loss';
export const PAY_METHOD_CASE_RATE = 'Case Rate';
export const PAY_METHOD_PER_DIEM = 'Per Diem';

export const MODULE_TYPE_VENDOR = 'vendor';
export const MODULE_TYPE_CONTRACT = 'contract';

export const RULE_TYPE_CARVEOUT = 'CARVEOUT';
export const RULE_TYPE_RULE = 'RULE';
export const RULE_TYPE_DEFAULT = 'DEFAULT';

export const CONFIG_TYPE_IPPS = 'IPPS';
export const CONFIG_TYPE_OPPS = 'OPPS';

export const CONFIG_TYPE_EAPG = 'EAPG';

export const PAY_SOURCE_SCHEDULE = 'Schedule';
export const PAY_SOURCE_CHARGE = 'Charge';

export const MODIFIER_PAY_METHOD_MULTI_PROCEDURE = 'MultiProcedure';

export const RULE_CRITERIA_DATE = 'date';
export const RULE_CRITERIA_INT = 'int';
export const RULE_CRITERIA_STRING = 'string';

export const CONTRACT_STATE_DRAFT = 'Draft';
export const CONTRACT_STATE_FINALIZED = 'Finalized';
export const CONTRACT_STATE_MODEL = 'Model';

export const RULE_APPLY_AT_DATE_OF_SERVICE = 'Date of Service';
