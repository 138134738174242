import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DirectoryDependencyDialogComponent from './DirectoryDependencyDialog';
import {  getContractsV2DirectoryDependencies,setContractsV2DirectoryDependencyDialog,deleteContractsV2ScheduleDirectory ,resetDirectoryDependencies} from '../../../store/actions/contractsv2';


export const DirectoryDependencyDialog = connect(
    () => {
        const selectDependencyDialog = (state) => state.contractsv2.directoryDependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (dependencyDialog) => {
                if (dependencyDialog && dependencyDialog?.open) {
                    return true;
                }
                return false;
            }
        );

        const selectDirectoryId:any = createSelector(
            selectDependencyDialog,
            (dependencyDialog) => {
                if (dependencyDialog && dependencyDialog?.directoryId) {
                    return dependencyDialog?.directoryId;
                }
                return null;
            }
        );

        const selectDirectoryName = createSelector(
            selectDependencyDialog,
            (dependencyDialog) => {
                if (dependencyDialog && dependencyDialog?.directoryName) {
                    return dependencyDialog?.directoryName
                }
                return ''
            }
        );

        return (state:any, props) => ({
            open: selectOpen(state),
            directoryId: selectDirectoryId(state, props),
            directoryDependencies: state.contractsv2.directoryDependencies?.records ?? [],
            directoryDependencyPending: state.contractsv2.getContractsV2DirectoryDependencies.pending,
            directoryDeletePending: state.contractsv2.deleteContractsV2Directory.pending,
            directoryName: selectDirectoryName(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
             setContractsV2DirectoryDependencyDialog,
            getContractsV2DirectoryDependencies,
            deleteContractsV2ScheduleDirectory,
            resetDirectoryDependencies: resetDirectoryDependencies
        }, dispatch)
    })
)(injectIntl(DirectoryDependencyDialogComponent));