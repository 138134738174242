export const CLAIM_ANALYSIS_GET = 'CLAIM_ANALYSIS_GET';
export const CLAIM_ANALYSIS_GET_PENDING = 'CLAIM_ANALYSIS_GET_PENDING';
export const CLAIM_ANALYSIS_GET_FULFILLED = 'CLAIM_ANALYSIS_GET_FULFILLED';
export const CLAIM_ANALYSIS_GET_REJECTED = 'CLAIM_ANALYSIS_GET_REJECTED';
export const CLAIM_ANALYSIS_RESET_DETAIL = 'CLAIM_ANALYSIS_RESET_DETAIL';

// get consolidated claims 
export const CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET = 'CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET';
export const CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET_PENDING = 'CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET_PENDING';
export const CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET_FULFILLED = 'CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET_FULFILLED';
export const CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET_REJECTED = 'CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET_REJECTED';
// get consolidated claims 


export const CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS = 'CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS';
export const CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_PENDING = 'CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_PENDING';
export const CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_FULFILLED = 'CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_FULFILLED';
export const CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_REJECTED = 'CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_REJECTED';

export const CLAIM_ACKNOWLEDGE = 'CLAIM_ACKNOWLEDGE';
export const CLAIM_ACKNOWLEDGE_PENDING = 'CLAIM_ACKNOWLEDGE_PENDING';
export const CLAIM_ACKNOWLEDGE_FULFILLED = 'CLAIM_ACKNOWLEDGE_FULFILLED';
export const CLAIM_ACKNOWLEDGE_REJECTED = 'CLAIM_ACKNOWLEDGE_REJECTED';

export const CLAIM_ANALYSIS_RESET = 'ROLES_RESET';

export const CLAIM_ANAYLYSIS_STATISTICS = "CLAIM_ANAYLYSIS_STATISTICS";
export const CLAIM_ANAYLYSIS_STATISTICS_PENDING = "CLAIM_ANAYLYSIS_STATISTICS_PENDING";
export const CLAIM_ANAYLYSIS_STATISTICS_FULFILLED = "CLAIM_ANAYLYSIS_STATISTICS_FULFILLED";
export const CLAIM_ANAYLYSIS_STATISTICS_REJECTED = "CLAIM_ANAYLYSIS_STATISTICS_REJECTED";

export const CLAIM_ANAYLYSIS_DOWNLOAD_REPORT = 'CLAIM_ANAYLYSIS_DOWNLOAD_REPORT';
export const CLAIM_ANAYLYSIS_DOWNLOAD_REPORT_PENDING = 'CLAIM_ANAYLYSIS_DOWNLOAD_REPORT_PENDING';
export const CLAIM_ANAYLYSIS_DOWNLOAD_REPORT_FULFILLED = 'CLAIM_ANAYLYSIS_DOWNLOAD_REPORT_FULFILLED';
export const CLAIM_ANAYLYSIS_DOWNLOAD_REPORT_REJECTED = 'CLAIM_ANAYLYSIS_DOWNLOAD_REPORT_REJECTED';

export const CLAIM_ANAYLYSIS_LANDING_FILTER = 'CLAIM_ANAYLYSIS_LANDING_FILTER';
export const CLAIM_ANAYLYSIS_DETAIL_ARR_RESET = 'CLAIM_ANAYLYSIS_DETAIL_ARR_RESET';

export const CLAIM_ANALYSIS_DRILLDOWN_SET = 'CLAIM_ANALYSIS_DRILLDOWN_SET';

export const CLAIM_FLAG = 'CLAIM_FLAG';
export const CLAIM_FLAG_PENDING = 'CLAIM_FLAG_PENDING';
export const CLAIM_FLAG_FULFILLED = 'CLAIM_FLAG_FULFILLED';
export const CLAIM_FLAG_REJECTED = 'CLAIM_FLAG_REJECTED';

export const CLAIM_ANALYSIS_PROCESS = 'CLAIM_ANALYSIS_PROCESS';
export const CLAIM_ANALYSIS_PROCESS_PENDING = 'CLAIM_ANALYSIS_PROCESS_PENDING';
export const CLAIM_ANALYSIS_PROCESS_FULFILLED = 'CLAIM_ANALYSIS_PROCESS_FULFILLED';
export const CLAIM_ANALYSIS_PROCESS_REJECTED = 'CLAIM_ANALYSIS_PROCESS_REJECTED';

export const CLAIM_ANALYSIS_BULK_PROCESS = 'CLAIM_ANALYSIS_BULK_PROCESS';
export const CLAIM_ANALYSIS_BULK_PROCESS_PENDING = 'CLAIM_ANALYSIS_BULK_PROCESS_PENDING';
export const CLAIM_ANALYSIS_BULK_PROCESS_FULFILLED = 'CLAIM_ANALYSIS_BULK_PROCESS_FULFILLED';
export const CLAIM_ANALYSIS_BULK_PROCESS_REJECTED = 'CLAIM_ANALYSIS_BULK_PROCESS_REJECTED';

export const CLAIM_ANALYSIS_JSON_VIEW = 'CLAIM_ANALYSIS_JSON_VIEW';
export const CLAIM_ANALYSIS_JSON_VIEW_PENDING = 'CLAIM_ANALYSIS_JSON_VIEW_PENDING';
export const CLAIM_ANALYSIS_JSON_VIEW_FULFILLED = 'CLAIM_ANALYSIS_JSON_VIEW_FULFILLED';
export const CLAIM_ANALYSIS_JSON_VIEW_REJECTED = 'CLAIM_ANALYSIS_JSON_VIEW_REJECTED';


export const CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID = 'CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID';
export const CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID_PENDING = 'CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID_PENDING';
export const CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID_FULFILLED = 'CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID_FULFILLED';
export const CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID_REJECTED = 'CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID_REJECTED';

export const CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID = 'CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID';
export const CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_PENDING = 'CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_PENDING';
export const CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_FULFILLED = 'CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_FULFILLED';
export const CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_REJECTED = 'CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_REJECTED';
export const CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_RESET = 'CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_RESET';

export const CLAIM_ANALYSIS_HISTORY = 'CLAIM_ANALYSIS_HISTORY';
export const CLAIM_ANALYSIS_HISTORY_PENDING = 'CLAIM_ANALYSIS_HISTORY_PENDING';
export const CLAIM_ANALYSIS_HISTORY_FULFILLED = 'CLAIM_ANALYSIS_HISTORY_FULFILLED';
export const CLAIM_ANALYSIS_HISTORY_REJECTED = 'CLAIM_ANALYSIS_HISTORY_REJECTED';

export const CLAIM_ANALYSIS_ADJUSTMENTS = 'CLAIM_ANALYSIS_ADJUSTMENTS';
export const CLAIM_ANALYSIS_ADJUSTMENTS_PENDING = 'CLAIM_ANALYSIS_ADJUSTMENTS_PENDING';
export const CLAIM_ANALYSIS_ADJUSTMENTS_FULFILLED = 'CLAIM_ANALYSIS_ADJUSTMENTS_FULFILLED';
export const CLAIM_ANALYSIS_ADJUSTMENTS_REJECTED = 'CLAIM_ANALYSIS_ADJUSTMENTS_REJECTED';

export const CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS = 'CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS';
export const CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS_PENDING = 'CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS_PENDING';
export const CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS_FULFILLED = 'CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS_FULFILLED';
export const CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS_REJECTED = 'CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS_REJECTED';

export const CLAIM_ANALYSIS_GET_DRGS = 'CLAIM_ANALYSIS_GET_DRGS';
export const CLAIM_ANALYSIS_GET_DRGS_PENDING = 'CLAIM_ANALYSIS_GET_DRGS_PENDING';
export const CLAIM_ANALYSIS_GET_DRGS_FULFILLED = 'CLAIM_ANALYSIS_GET_DRGS_FULFILLED';
export const CLAIM_ANALYSIS_GET_DRGS_REJECTED = 'CLAIM_ANALYSIS_GET_DRGS_REJECTED';

export const CLAIM_ANALYSIS_GET_CPTS = 'CLAIM_ANALYSIS_GET_CPTS';
export const CLAIM_ANALYSIS_GET_CPTS_PENDING = 'CLAIM_ANALYSIS_GET_CPTS_PENDING';
export const CLAIM_ANALYSIS_GET_CPTS_FULFILLED = 'CLAIM_ANALYSIS_GET_CPTS_FULFILLED';
export const CLAIM_ANALYSIS_GET_CPTS_REJECTED = 'CLAIM_ANALYSIS_GET_CPTS_REJECTED';

export const CLAIM_ANALYSIS_GET_REVENUE_CODES = 'CLAIM_ANALYSIS_GET_REVENUE_CODES';
export const CLAIM_ANALYSIS_GET_REVENUE_CODES_PENDING = 'CLAIM_ANALYSIS_GET_REVENUE_CODES_PENDING';
export const CLAIM_ANALYSIS_GET_REVENUE_CODES_FULFILLED = 'CLAIM_ANALYSIS_GET_REVENUE_CODES_FULFILLED';
export const CLAIM_ANALYSIS_GET_REVENUE_CODES_REJECTED = 'CLAIM_ANALYSIS_GET_REVENUE_CODES_REJECTED';

export const CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES = 'CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES';
export const CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES_PENDING = 'CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES_PENDING';
export const CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES_FULFILLED = 'CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES_FULFILLED';
export const CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES_REJECTED = 'CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES_REJECTED';

export const CLAIM_ANALYSIS_ESTIMATE = 'CLAIM_ANALYSIS_ESTIMATE';
export const CLAIM_ANALYSIS_ESTIMATE_PENDING = 'CLAIM_ANALYSIS_ESTIMATE_PENDING';
export const CLAIM_ANALYSIS_ESTIMATE_FULFILLED = 'CLAIM_ANALYSIS_ESTIMATE_FULFILLED';
export const CLAIM_ANALYSIS_ESTIMATE_REJECTED = 'CLAIM_ANALYSIS_ESTIMATE_REJECTED';

export const CLAIM_ANALYSIS_AUDIT_PROCESS = 'CLAIM_ANALYSIS_AUDIT_PROCESS';
export const CLAIM_ANALYSIS_AUDIT_PROCESS_PENDING = 'CLAIM_ANALYSIS_AUDIT_PROCESS_PENDING';
export const CLAIM_ANALYSIS_AUDIT_PROCESS_FULFILLED = 'CLAIM_ANALYSIS_AUDIT_PROCESS_FULFILLED';
export const CLAIM_ANALYSIS_AUDIT_PROCESS_REJECTED = 'CLAIM_ANALYSIS_AUDIT_PROCESS_REJECTED';

export const CLAIM_ESTIMATOR_CALCULATE_OOP = 'CLAIM_ESTIMATOR_CALCULATE_OOP';
export const CLAIM_ESTIMATOR_CALCULATE_OOP_PENDING = 'CLAIM_ESTIMATOR_CALCULATE_OOP_PENDING';
export const CLAIM_ESTIMATOR_CALCULATE_OOP_FULFILLED = 'CLAIM_ESTIMATOR_CALCULATE_OOP_FULFILLED';
export const CLAIM_ESTIMATOR_CALCULATE_OOP_REJECTED = 'CLAIM_ESTIMATOR_CALCULATE_OOP_REJECTED';

export const CLAIM_ESTIMATOR_GET_BY_ID = 'CLAIM_ESTIMATOR_GET_BY_ID';
export const CLAIM_ESTIMATOR_GET_BY_ID_PENDING = 'CLAIM_ESTIMATOR_GET_BY_ID_PENDING';
export const CLAIM_ESTIMATOR_GET_BY_ID_FULFILLED = 'CLAIM_ESTIMATOR_GET_BY_ID_FULFILLED';
export const CLAIM_ESTIMATOR_GET_BY_ID_REJECTED = 'CLAIM_ESTIMATOR_GET_BY_ID_REJECTED';

export const CLAIM_ESTIMATOR_EDIT_BY_ID = 'CLAIM_ESTIMATOR_EDIT_BY_ID';
export const CLAIM_ESTIMATOR_EDIT_BY_ID_PENDING = 'CLAIM_ESTIMATOR_EDIT_BY_ID_PENDING';
export const CLAIM_ESTIMATOR_EDIT_BY_ID_FULFILLED = 'CLAIM_ESTIMATOR_EDIT_BY_ID_FULFILLED';
export const CLAIM_ESTIMATOR_EDIT_BY_ID_REJECTED = 'CLAIM_ESTIMATOR_EDIT_BY_ID_REJECTED';

export const CLAIM_ESTIMATOR_GET_DX_CODES = 'CLAIM_ESTIMATOR_GET_DX_CODES';
export const CLAIM_ESTIMATOR_GET_DX_CODES_PENDING = 'CLAIM_ESTIMATOR_GET_DX_CODES_PENDING';
export const CLAIM_ESTIMATOR_GET_DX_CODES_FULFILLED = 'CLAIM_ESTIMATOR_GET_DX_CODES_FULFILLED';
export const CLAIM_ESTIMATOR_GET_DX_CODES_REJECTED = 'CLAIM_ESTIMATOR_GET_DX_CODES_REJECTED';

export const CLAIM_ANALYSIS_BULK_ONDEMAND = 'CLAIM_ANALYSIS_BULK_ONDEMAND';
export const CLAIM_ANALYSIS_BULK_ONDEMAND_PENDING = 'CLAIM_ANALYSIS_BULK_ONDEMAND_PENDING';
export const CLAIM_ANALYSIS_BULK_ONDEMAND_FULFILLED = 'CLAIM_ANALYSIS_BULK_ONDEMAND_FULFILLED';
export const CLAIM_ANALYSIS_BULK_ONDEMAND_REJECTED = 'CLAIM_ANALYSIS_BULK_ONDEMAND_REJECTED';

export const CLAIM_ANALYSIS_OVERRIDE_CONTRACT = 'CLAIM_ANALYSIS_OVERRIDE_CONTRACT';
export const CLAIM_ANALYSIS_OVERRIDE_CONTRACT_PENDING = 'CLAIM_ANALYSIS_OVERRIDE_CONTRACT_PENDING';
export const CLAIM_ANALYSIS_OVERRIDE_CONTRACT_FULFILLED = 'CLAIM_ANALYSIS_OVERRIDE_CONTRACT_FULFILLED';
export const CLAIM_ANALYSIS_OVERRIDE_CONTRACT_REJECTED = 'CLAIM_ANALYSIS_OVERRIDE_CONTRACT_REJECTED';

export const CLAIM_ANALYSIS_SET_COMMENTS = 'CLAIM_ANALYSIS_SET_COMMENTS';

export const CLAIM_ANALYSIS_GET_COMMENTS = 'CLAIM_ANALYSIS_GET_COMMENTS';
export const CLAIM_ANALYSIS_GET_COMMENTS_PENDING = 'CLAIM_ANALYSIS_GET_COMMENTS_PENDING';
export const CLAIM_ANALYSIS_GET_COMMENTS_FULFILLED = 'CLAIM_ANALYSIS_GET_COMMENTS_FULFILLED';
export const CLAIM_ANALYSIS_GET_COMMENTS_REJECTED = 'CLAIM_ANALYSIS_GET_COMMENTS_REJECTED';

export const CLAIM_ANAYLYSIS_PROCESSED_CLAIMS_DIALOG = 'CLAIM_ANAYLYSIS_PROCESSED_CLAIMS_DIALOG';

export const CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_RESET = 'CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_RESET';

export const CLAIM_ESTIMATOR_SET_CHARGE = 'CLAIM_ESTIMATOR_SET_CHARGE';
export const CLAIM_ESTIMATOR_SET_MODE = 'CLAIM_ESTIMATOR_SET_MODE';

export const CLAIM_ANALYSIS_SET_ADJUSTMENT = 'CLAIM_ANALYSIS_SET_ADJUSTMENT';

export const CLAIM_ANALYSIS_RESER_JSON_VIEW = 'CLAIM_ANALYSIS_RESER_JSON_VIEW';
export const CLAIM_ANALYSIS_RESET_CONSOLIDATED_CLAIMS_VIEW = 'CLAIM_ANALYSIS_RESET_CONSOLIDATED_CLAIMS_VIEW';


export const CLAIM_ANAYLYSIS_ERROR_RESET = 'CLAIM_ANAYLYSIS_ERROR_RESET';

export const CLAIM_ANAYLYSIS_AUDIT_ERROR_RESET = 'CLAIM_ANAYLYSIS_AUDIT_ERROR_RESET';

export const CLAIM_ANAYLYSIS_SET_ARCHIVED_CLAIM = 'CLAIM_ANAYLYSIS_SET_ARCHIVED_CLAIM';

export const CLAIM_ANALYSIS_RESET_LINE_ITEM = 'CLAIM_ANALYSIS_RESET_LINE_ITEM';

export const CLAIM_ANAYLYSIS_SET_ACKNOWLEDGED_DIALOG = 'CLAIM_ANAYLYSIS_SET_ACKNOWLEDGED_DIALOG';
export const CLAIM_ANAYLYSIS_SET_FLAG_DIALOG = 'CLAIM_ANAYLYSIS_SET_FLAG_DIALOG';
export const CLAIM_ANAYLYSIS_SET_SELECTED_CLAIMS = 'CLAIM_ANAYLYSIS_SET_SELECTED_CLAIMS';
export const CLAIM_ANAYLYSIS_SET_FLAG_TOGGLE = 'CLAIM_ANAYLYSIS_SET_FLAG_TOGGLE';
export const CLAIM_ANAYLYSIS_SET_BULK_FLAGGED = 'CLAIM_ANAYLYSIS_SET_BULK_FLAGGED';
export const CLAIM_ANAYLYSIS_SET_BULK_ACKNOWLEDGED = 'CLAIM_ANAYLYSIS_SET_BULK_ACKNOWLEDGED';
export const CLAIM_ANAYLYSIS_SET_PLAY_DIALOG = 'CLAIM_ANAYLYSIS_SET_PLAY_DIALOG';
export const CLAIM_ANAYLYSIS_SET_RELATIVE_FILTER = 'CLAIM_ANAYLYSIS_SET_RELATIVE_FILTER';

export const CLAIM_ANAYLYSIS_SET_HIGHLIGHTED_ROW_ID = 'CLAIM_ANAYLYSIS_SET_HIGHLIGHTED_ROW_ID';

export const CLAIM_ANALYSIS_SET_CLAIM_SERVICE_ADJUSTMENT = 'CLAIM_ANALYSIS_SET_CLAIM_SERVICE_ADJUSTMENT';
export const CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS = 'CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS';
export const CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS_PENDING = 'CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS_PENDING';
export const CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS_FULFILLED = 'CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS_FULFILLED';
export const CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS_REJECTED = 'CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS_REJECTED';

export const CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT = 'CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT';
export const CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT_PENDING = 'CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT_PENDING';
export const CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT_FULFILLED = 'CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT_FULFILLED';
export const CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT_REJECTED = 'CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT_REJECTED';

export const CLAIM_ANALYSIS_GET_STATUS_LIST = 'CLAIM_ANALYSIS_GET_STATUS_LIST';
export const CLAIM_ANALYSIS_GET_STATUS_LIST_PENDING = 'CLAIM_ANALYSIS_GET_STATUS_LIST_PENDING';
export const CLAIM_ANALYSIS_GET_STATUS_LIST_FULFILLED = 'CLAIM_ANALYSIS_GET_STATUS_LIST_FULFILLED';
export const CLAIM_ANALYSIS_GET_STATUS_LIST_REJECTED = 'CLAIM_ANALYSIS_GET_STATUS_LIST_REJECTED';

export const UPDATE_CLAIM_ANALYSIS_STATUS_LIST = 'UPDATE_CLAIM_ANALYSIS_STATUS_LIST';
export const UPDATE_CLAIM_ANALYSIS_STATUS_LIST_PENDING = 'UPDATE_CLAIM_ANALYSIS_STATUS_LIST_PENDING';
export const UPDATE_CLAIM_ANALYSIS_STATUS_LIST_FULFILLED = 'UPDATE_CLAIM_ANALYSIS_STATUS_LIST_FULFILLED';
export const UPDATE_CLAIM_ANALYSIS_STATUS_LIST_REJECTED = 'UPDATE_CLAIM_ANALYSIS_STATUS_LIST_REJECTED';

export const UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS = 'UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS';
export const UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS_PENDING = 'UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS_PENDING';
export const UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS_FULFILLED = 'UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS_FULFILLED';
export const UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS_REJECTED = 'UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS_REJECTED';

export const CLAIM_ANALYSIS_STATUS_LOGS_LIST = 'CLAIM_ANALYSIS_STATUS_LOGS_LIST';
export const CLAIM_ANALYSIS_STATUS_LOGS_LIST_PENDING = 'CLAIM_ANALYSIS_STATUS_LOGS_LIST_PENDING';
export const CLAIM_ANALYSIS_STATUS_LOGS_LIST_FULFILLED = 'CLAIM_ANALYSIS_STATUS_LOGS_LIST_FULFILLED';
export const CLAIM_ANALYSIS_STATUS_LOGS_LIST_REJECTED = 'CLAIM_ANALYSIS_STATUS_LOGS_LIST_REJECTED';

export const ESTIMATOR_HISTORY_SET_DETAILS_DIALOG = 'ESTIMATOR_HISTORY_SET_DETAILS_DIALOG';
export const CLAIM_ANAYLYSIS_UPDATE_STATUS_DIALOG ='CLAIM_ANAYLYSIS_UPDATE_STATUS_DIALOG'
export const CLAIM_ANALYSIS_STATUS_HISTORY_DIALOG ='CLAIM_ANALYSIS_STATUS_HISTORY_DIALOG'

export const CLAIM_ANALYSIS_SET_COMMENTS_CONFIRMATION_DIALOG="CLAIM_ANALYSIS_SET_COMMENTS_CONFIRMATION_DIALOG"

export const CLAIM_LOOKUP_VIEW_ID="CLAIM_LOOKUP_VIEW_ID"

export const get = (analysisId, callback) => ({
    type: CLAIM_ANALYSIS_GET,
    payload: { analysisId, callback },
});

export const getConsolidatedClaims = (claimId,claimNumber) => ({
    type: CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET,
    payload: { claimId,claimNumber },
});

export const acknowledge = (acknowledgePayload, callback, shouldReloadGrid) => ({
    type: CLAIM_ACKNOWLEDGE,
    payload: { acknowledgePayload, callback, shouldReloadGrid },
});

export const flag = (flagPayload, callback, shouldReloadGrid) => ({
    type: CLAIM_FLAG,
    payload: { flagPayload, callback, shouldReloadGrid },
});

export const claimAnalysisStats = (pagination, sort, filter) => {
    return {
        type: CLAIM_ANAYLYSIS_STATISTICS,
        payload: { pagination, sort, filter },
    }
};

export const claimDetailArrReset = () => ({
    type: CLAIM_ANAYLYSIS_DETAIL_ARR_RESET
});

export const download = (payload, showSignificantVariance,callback) => ({
    type: CLAIM_ANAYLYSIS_DOWNLOAD_REPORT,
    payload: { payload, callback,showSignificantVariance }
});

export const setDrilldown = (payload) => ({
    type: CLAIM_ANALYSIS_DRILLDOWN_SET,
    payload: { payload }
});

export const claimProcess = (payload, callback, errorCallback) => ({
    type: CLAIM_ANALYSIS_PROCESS,
    payload: { payload, callback, errorCallback }
});

export const claimBulkProcess = (payload, significanceFlag, callback, errorCallback) => ({
    type: CLAIM_ANALYSIS_BULK_PROCESS,
    payload: { payload, callback, significanceFlag, errorCallback }
});

export const resetClaimProcessError = () => ({
    type: CLAIM_ANAYLYSIS_ERROR_RESET,
});

export const getClaimJSONView = (claimNumber) => ({
    type: CLAIM_ANALYSIS_JSON_VIEW,
    payload: {
        claimNumber
    }
});

export const resetJSONView = () => ({
    type: CLAIM_ANALYSIS_RESER_JSON_VIEW
});

export const resetConsolidatedClaimsView = () => ({
    type: CLAIM_ANALYSIS_RESET_CONSOLIDATED_CLAIMS_VIEW
});

export const deleteClaimByPayerContractId = (payerContractId, callback) => ({
    type: CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID,
    payload: {
        payerContractId, callback
    }
});

export const deleteClaimByContractId = (contractId, callback) => ({
    type: CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID,
    payload: {
        contractId, callback
    }
});

export const deleteClaimResetByContractReset = () => ({
    type: CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_RESET
});

export const getHistory = (analysisId) => ({
    type: CLAIM_ANALYSIS_HISTORY,
    payload: { analysisId }
});

export const setArchivedClaim = (archivedClaim) => ({
    type: CLAIM_ANAYLYSIS_SET_ARCHIVED_CLAIM,
    payload: { archivedClaim }
});

export const getClaimAdjustments = (paymentId,analysisId) => ({
    type: CLAIM_ANALYSIS_ADJUSTMENTS,
    payload: { paymentId,analysisId }
});

export const getServiceAdjustments = (servicePaymentId,analysisId) => ({
    type: CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS,
    payload: { servicePaymentId,analysisId }
});

export const setAdjustment = (payload) => ({
    type: CLAIM_ANALYSIS_SET_ADJUSTMENT,
    payload: { payload }
});

export const getDRGS = () => ({
    type: CLAIM_ANALYSIS_GET_DRGS
});

export const getCPTS = (options) => ({
    type: CLAIM_ANALYSIS_GET_CPTS,
    payload: { options }
});

export const getRevenueCodes = () => ({
    type: CLAIM_ANALYSIS_GET_REVENUE_CODES
});

export const getAssociatedCharges = (options) => ({
    type: CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES,
    payload: { options }
});

export const setClaimEstimatorCharges = (claimChargeData) => ({
    type: CLAIM_ESTIMATOR_SET_CHARGE,
    payload: { claimChargeData }
});

export const estimate = (options, callback) => ({
    type: CLAIM_ANALYSIS_ESTIMATE,
    payload: { options, callback }
});

export const setClaimChargeMode = (claimChargeMode, claimChargeIndex) => ({
    type: CLAIM_ESTIMATOR_SET_MODE,
    payload: { claimChargeMode, claimChargeIndex }
});

export const processClaimsFromAudit = (options, callback) => ({
    type: CLAIM_ANALYSIS_AUDIT_PROCESS,
    payload: { options, callback }
});

export const auditErrorReset = () => ({
    type: CLAIM_ANAYLYSIS_AUDIT_ERROR_RESET
});

export const setOpenAcknowledgeDialog = (openAcknowledgeDialog) => ({
    type: CLAIM_ANAYLYSIS_SET_ACKNOWLEDGED_DIALOG,
    payload: { openAcknowledgeDialog }
});

export const setOpenFlagDialog = (openFlagDialog) => ({
    type: CLAIM_ANAYLYSIS_SET_FLAG_DIALOG,
    payload: { openFlagDialog }
});

export const setSelectedClaims = (selectedClaims) => ({
    type: CLAIM_ANAYLYSIS_SET_SELECTED_CLAIMS,
    payload: { selectedClaims }
});

export const setFlagToggle = (flagToggle) => ({
    type: CLAIM_ANAYLYSIS_SET_FLAG_TOGGLE,
    payload: { flagToggle }
});

export const setBulkFlagged = (isBulkFlagged) => ({
    type: CLAIM_ANAYLYSIS_SET_BULK_FLAGGED,
    payload: { isBulkFlagged }
});

export const setBulkAcknowledge = (isBulkAcknowledge) => ({
    type: CLAIM_ANAYLYSIS_SET_BULK_ACKNOWLEDGED,
    payload: { isBulkAcknowledge }
});

export const setOpenPlayDialog = (openPlayDialog) => ({
    type: CLAIM_ANAYLYSIS_SET_PLAY_DIALOG,
    payload: { openPlayDialog }
});

export const setRelativeFilter = (relativeFilterValue) => ({
    type: CLAIM_ANAYLYSIS_SET_RELATIVE_FILTER,
    payload: { relativeFilterValue }
});

export const setHighlightedRowId = (highlightedRowId) => ({
    type: CLAIM_ANAYLYSIS_SET_HIGHLIGHTED_ROW_ID,
    payload: { highlightedRowId }
});

export const setClaimServiceAdjustments = (payload) => ({
    type: CLAIM_ANALYSIS_SET_CLAIM_SERVICE_ADJUSTMENT,
    payload: { payload }
});

export const getClaimServiceAdjustments = (paymentId,claimId) => ({
    type: CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS,
    payload: { paymentId,claimId }
});

export const calculateOutOfPocketCost = (options, callback) => ({
    type: CLAIM_ESTIMATOR_CALCULATE_OOP,
    payload: { options, callback }
});

export const getEstimateById = (id) => ({
    type: CLAIM_ESTIMATOR_GET_BY_ID,
    payload: { id }
});

export const editEstimateById = (options, callback) => ({
    type: CLAIM_ESTIMATOR_EDIT_BY_ID,
    payload: { options, callback }
});

export const getDXCodes = (keyword) => ({
    type: CLAIM_ESTIMATOR_GET_DX_CODES,
    payload: { keyword }
});

export const getClaimAllDetails = (analysisId) => ({
    type: CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS,
    payload: { analysisId }
});

export const resetClaimDetails = () => ({
    type: CLAIM_ANALYSIS_RESET_DETAIL,
});

export const processBulkOnDemand = (options, callback, errorCallback) => ({
    type: CLAIM_ANALYSIS_BULK_ONDEMAND,
    payload: { options, callback, errorCallback }
});

export const setProcessedDialog = (processedBulkClaimsDialogOpen) => ({
    type: CLAIM_ANAYLYSIS_PROCESSED_CLAIMS_DIALOG,
    payload: { processedBulkClaimsDialogOpen }
});

export const overrideContract = (options, callback) => ({
    type: CLAIM_ANALYSIS_OVERRIDE_CONTRACT,
    payload: { options, callback }
});

export const resetClaimAllDetials = () => ({
    type: CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_RESET
});

export const getClaimComments = (analysisId) => ({
    type: CLAIM_ANALYSIS_GET_COMMENTS,
    payload: { analysisId }
});

export const setEstimatorHistoryDetailsDialog = (estimatorHistoryDetailsDialog) => ({
    type: ESTIMATOR_HISTORY_SET_DETAILS_DIALOG,
    payload: { estimatorHistoryDetailsDialog }
});

export const setClaimUpdateStatusDialog = (updateClaimStatusDialogOpen) => ({
    type: CLAIM_ANAYLYSIS_UPDATE_STATUS_DIALOG,
    payload: { updateClaimStatusDialogOpen }
});

export const resetClaimLineItem = () => ({
    type: CLAIM_ANALYSIS_RESET_LINE_ITEM
});

export const removeOverriddenContract = (overriddenPayload,callback) => ({
    type: CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT,
    payload: { overriddenPayload,callback }
});

export const getClaimStatuses = () => ({
    type: CLAIM_ANALYSIS_GET_STATUS_LIST
});

export const updateClaimStatuses = (claimStatusPayload,callback) => ({
    type: UPDATE_CLAIM_ANALYSIS_STATUS_LIST,
    payload: { claimStatusPayload,callback }
});

export const updateSingleClaimStatus = (claimStatusPayload,status,analysisId,callback) => ({
    type: UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS,
    payload: { claimStatusPayload,callback,status,analysisId }
});

export const listClaimStatusLogs = (analysisId) => ({
    type: CLAIM_ANALYSIS_STATUS_LOGS_LIST,
    payload: { analysisId }
});

export const setClaimStatusHistoryDialog = (claimStatusHistoryDialogOpen) => ({
    type: CLAIM_ANALYSIS_STATUS_HISTORY_DIALOG,
    payload: { claimStatusHistoryDialogOpen }
});

export const setClaimComments = (newComment) => ({
    type: CLAIM_ANALYSIS_SET_COMMENTS,
    payload: { newComment }
});

export const setClaimCommentsConfirmationDialog = (dialogData) => ({
    type: CLAIM_ANALYSIS_SET_COMMENTS_CONFIRMATION_DIALOG,
    payload: { ...dialogData }
});

export const setClaimLookupId = (lookupId) => ({
    type: CLAIM_LOOKUP_VIEW_ID,
    payload:lookupId 
});
