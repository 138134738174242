import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import ModifierEditDialogComponent from './ModifiersEditPage';
import { createEditMRFModifierReset, createMRFModifier, modifiersLanding, setMRFModifiersEditDialog, updateMRFModifier } from '../../../../store/actions/modifiers';
import { getPayerContractsTiny } from '../../../../store/actions/payers';
import { selectPayerContracts } from '../../../../store/selectors/payers';
import { CONTRACTED_SERVICE_INPATIENT, CONTRACTED_SERVICE_OUTPATIENT, CONTRACTED_SERVICE_BOTH } from '../../../../store/constants/contract';

export const ModifierEditDialog = connect(
    () => {

        const visitTypes = [
            CONTRACTED_SERVICE_INPATIENT,
            CONTRACTED_SERVICE_OUTPATIENT,
            CONTRACTED_SERVICE_BOTH
        ];

        const selectModifierEditDialog = (state, props) => state.modifiers.editDialog;
        const selectModifiers = (state) => state.modifiers.data;

        const selectModifier = createSelector(
            selectModifierEditDialog,
            (editDialog) => {
                if (editDialog && editDialog?.modifier) {
                    return editDialog?.modifier
                }
                return null
            }
        )

        const selectOpen = createSelector(
            selectModifierEditDialog,
            (editDialog) => {
                if (editDialog && editDialog?.open) {
                    return true
                }
                return false
            }
        )

        const selectMode = createSelector(
            selectModifier,
            (modifier) => {
                return modifier ? 'edit' : 'create';
            }
        )

        const selectInitialValues = createSelector(
            selectMode,
            selectModifier,
            selectPayerContracts,
            (mode, savedModifier, payerContracts) => {
                if (mode === 'edit' && savedModifier) {
                    
                    let payload = {
                        id: savedModifier.id,
                        name: savedModifier.name,
                        description: savedModifier.description,
                        percentage: savedModifier.percentage,
                        paymentDescription: savedModifier.paymentDescription,
                        visitType: savedModifier.visitType,
                        payerContract: payerContracts?.find(x=> x.id === savedModifier.payerContractId) ?? null,
                    }
                    return payload;
                }

                return {
                    name: '',
                    description: '',
                    percentage: '',
                    paymentDescription: '',
                    visitType: visitTypes[0],
                    payerContract: null,
                }
            }
        )

        return (state, props) => ({
            open: selectOpen(state, props),
            mode: selectMode(state, props),
            initialValues: selectInitialValues(state, props),
            createModifierPending: state.modifiers.createMRFModifier.pending,
            updateModifierPending: state.modifiers.updateMRFModifier.pending,
            getModifiersPending: state.modifiers.list.pending,
            getPayerContractsPending: state.payers.getPayerContractsTiny.pending,
            masterModifiers: selectModifiers(state),
            payerContracts: selectPayerContracts(state),
            visitTypes: visitTypes
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            createModifier: createMRFModifier,
            updateModifier: updateMRFModifier,
            createEditReset: createEditMRFModifierReset,
            setEditDialog: setMRFModifiersEditDialog,
            getModifiers: modifiersLanding,
            getPayerContractsTiny: getPayerContractsTiny,

        }, dispatch)
    })
)(injectIntl(ModifierEditDialogComponent));