import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { set,setMulti } from '../../store/actions/pages';

import { claimAnalysisLanding } from '../../store/actions/pages';
import { BooleanEquators, dateEquators, integerEquators, StringEquators } from '../../store/constants/pages';
import { createClaimAnalysisPatientNameField, createClaimAnalysisPhysicianNameField, createClaimAnalysisStatusField, createClaimNumberFieldWithCopyButton, createCurrencyDataField, createDateField, createIconDataField, createNumberDataField, createPlainDateField, createStringDataField } from '../../util/format';
import ClaimAnalysisComponent from './ClaimAnalysisPage';

import { getClaimStatuses, setClaimUpdateStatusDialog } from '../../store/actions/claimAnalysis';

import { claimAnalysisStats, download, flag, resetClaimProcessError, setBulkAcknowledge, setBulkFlagged, setDrilldown, setFlagToggle, setOpenAcknowledgeDialog, setOpenFlagDialog, setOpenPlayDialog, setSelectedClaims } from '../../store/actions/claimAnalysis';
import { tiny } from '../../store/actions/contracts';
import { npiRolesLanding } from '../../store/actions/npiRoles';
import { getPayersTiny } from '../../store/actions/payers';
import { getByName, reset } from '../../store/actions/search';
import { CONTRACTED_SERVICE_INPATIENT, CONTRACTED_SERVICE_OUTPATIENT, CONTRACTED_SERVICE_PROFESSIONAL } from '../../store/constants/contract';
import { SEARCH_PERIOD_ID } from '../../store/constants/settings';
import { selectDefaultCustomFilters } from '../../store/selectors/claimAnalysis';
import { selectSelectedClientName } from "../../store/selectors/clients";
import { selectSessionHasClaimProcessorAccess } from '../../store/selectors/session';
import { selectRecoveredAmountFlag, selectSettings } from '../../store/selectors/settings';
import { getPaginationFilter } from '../../util/pagination';

const DEFAULT_SORT = [{ id: 'expectedPay', direction: 'asc' }];

export const selectContractsTiny = (state) => state.contracts.tiny;
export const selectPayersTiny = (state) => state.payers.tiny;
export const selectClaimStatusDropdownValues = (state) => state.claimAnalysis.claimStatuses?.status || [];
export const selectNpiRolesDropdownValues = (state) => (state.npiRoles.data?.map((item)=>({label:item?.name,value:item?.name,name:item?.name})))


const visitTypes = [
    CONTRACTED_SERVICE_INPATIENT,
    CONTRACTED_SERVICE_OUTPATIENT,
    CONTRACTED_SERVICE_PROFESSIONAL
];

const denailDropdownValues = [{
    label: 'Yes',
    value: 'true'
}, {
    label: 'No',
    value: 'false'
}]


const selectCustomFilter = createSelector(
    (state) => state.pages['claimAnalysis-landing'],
    (table) => {
        return table.customFilter;
    }
);
const selectCustomFilerPaidStatus = createSelector(
    (state) => state.pages['claimAnalysis-landing']?.customFilter,
    (customFilter:any[]) => {
       return customFilter?.find((item)=>item?.field==="isPaid")
    }
);


const selectFields = createSelector(
    selectContractsTiny,
    selectPayersTiny,
    selectRecoveredAmountFlag,
    selectClaimStatusDropdownValues,
    selectNpiRolesDropdownValues,
    selectCustomFilerPaidStatus,

    (tinyContracts, tinyPayers, recoveredAmountFlag,claimStatuses,npiRoles,paidStatus) => {
        let isUnpaid=paidStatus?.value==="false"
        
        const fields = [
            createClaimNumberFieldWithCopyButton('claimnumber', 'Claim Number', { returnWholeRow: true, sortable: true, equators: StringEquators, group: 'Claim Information', positionFixed: true, reorder: false, customCell: true }),
            createStringDataField('renderingnpi', 'NPI Number', { sortable: true, equators: StringEquators, group: 'Claim Information', hideFromGrid: true, reorder: false }),
            createNumberDataField('age', 'Patient Age', { sortable: true, equators: integerEquators, group: 'Claim Information' }),

            createClaimAnalysisPatientNameField('patientLastname', 'Patient Name', { sortable: true, equators: StringEquators, returnWholeRow: true, hideFromFilter: true, width: '100px' }),
            createClaimAnalysisPhysicianNameField('physicianLastname', 'Physician Name', { sortable: true, equators: StringEquators, returnWholeRow: true, hideFromFilter: true, width: '100px' }),
            createStringDataField('patientFirstname', 'Patient First Name', { sortable: true, equators: StringEquators, group: 'Claim Information', hideFromGrid: true, reorder: false }),
            createStringDataField('patientLastname', 'Patient Last Name', { sortable: true, equators: StringEquators, group: 'Claim Information', hideFromGrid: true, reorder: false }),
            createStringDataField('physicianFirstname', 'Physician First Name', { sortable: true, equators: StringEquators, group: 'Claim Information', hideFromGrid: true, reorder: false }),
            createStringDataField('physicianLastname', 'Physician Last Name', { sortable: true, equators: StringEquators, group: 'Claim Information', hideFromGrid: true, reorder: false }),
            createStringDataField('rule.name', 'Rule Name', {
                sortable: true, equators: StringEquators, secondLevel: 'name', group: 'Charge Information', hideFromGrid: true, reorder: false
            }),
            createStringDataField('contract.name', 'Contract Name', {
                sortable: true, equators: StringEquators, secondLevel: 'name', onHover: true, group: 'Claim Information',
                isDropdown: true, dropdownValues: tinyContracts.map(contract => contract.name), width: '100px'
            }),
            createStringDataField('payer.name', 'Payer Name', {
                sortable: true, equators: StringEquators, secondLevel: 'name', group: 'Claim Information', width: '100px'
            }),
            createPlainDateField('servicedate', 'Service Date', { sortable: true, equators: dateEquators, group: 'Claim Information', whiteSpace: 'pre' }),
            createPlainDateField('remitDate', 'Remit Date', { sortable: true, equators: dateEquators, group: 'Claim Information', whiteSpace: 'pre',hideFromFilter:isUnpaid }),
            createStringDataField('visit', 'Visit', { sortable: true, equators: StringEquators, group: 'Claim Information', isDropdown: true, dropdownValues: visitTypes }),
            createStringDataField('npirolename', 'NPI Roles', { equators: StringEquators, hidden: true,isStrictDropdown:true,dropdownValues:npiRoles,group: 'Claim Information' }),
            createCurrencyDataField('chargedAmount', 'Charged Amount', { sortable: true, equators: integerEquators, group: 'Claim Information',hideFromFilter:isUnpaid }),
            createCurrencyDataField('expectedPay', 'Expected Pay', { sortable: true, equators: integerEquators, group: 'Claim Information' }),
            createCurrencyDataField('actualPay', 'Allowable', { sortable: true, equators: integerEquators, group: 'Claim Information',hideFromFilter:isUnpaid }),
            createCurrencyDataField('variance', 'Variance', { sortable: true, equators: integerEquators, labelStyle: 'label label-lg label-inline', group: 'Claim Information',hideFromFilter:isUnpaid }),
            createStringDataField('drg', 'Drg', { equators: StringEquators, hidden: true, group: 'Claim Information' }),
            createStringDataField('revcode', 'Revenue Code', { equators: StringEquators, hidden: true, group: 'Charge Information' }),
            createStringDataField('pxcode', 'Procedure Code', { equators: StringEquators, hidden: true, group: 'Charge Information' }),
            createStringDataField('reasoncode', 'CAS Code', { equators: StringEquators, hidden: true, group: 'Charge Information',hideFromFilter:isUnpaid }),

            createClaimAnalysisStatusField('status', 'Status', { returnWholeRow: true,equators:StringEquators, isDropdown: true, dropdownValues:claimStatuses, group: 'Claim Information'  }),

            createDateField('updatedAt', 'Updated At', { whiteSpace: 'pre', sortable: true, equators: dateEquators, group: 'Charge Information' }),
            createIconDataField('isDenial', 'Denial', {equators: BooleanEquators, isStrictDropdown: true, dropdownValues: denailDropdownValues, icon: "fas fa-times-circle", checkedClass: 'text-danger', group: 'Claim Information',hideFromFilter:isUnpaid })
        ];

        if (recoveredAmountFlag) {
            const insertIndex = fields.findIndex(field => field.id === 'variance') + 1;
            fields.splice(insertIndex, 0,
                createCurrencyDataField('recoveredAmount', 'Recovered Amount', { sortable: true, equators: integerEquators, labelStyle: 'label label-lg label-inline', group: 'Claim Information',hideFromFilter:isUnpaid })
            );
        }

        return fields;
    }
);

const selectClaimsData = createSelector(
    (state) => state.pages['claimAnalysis-landing'].list,
    (data) => {
        return data
    }
);

const selectSort = createSelector(
    (state) => state.pages['claimAnalysis-landing'],
    (table) => {
        if (table.sort.length === 0) {
            return [...DEFAULT_SORT];
        }
        return table.sort;
    },
);

const selectFilter = createSelector(
    (state) => state.pages['claimAnalysis-landing'],
    selectFields,
    (table, fields) => {
        return getPaginationFilter(fields, table.filter, null, null, table.customFilter);
    }
);

const selectRawFilters = createSelector(
    (state) => state.pages['claimAnalysis-landing'],
    (table) => table.filter
);

const selectPageRecord = createSelector(
    (state) => state.pages['claimAnalysis-landing'],
    (table) => table.page
);

const selectSearchPeriod = createSelector(
    selectSettings,
    (settings) => {
        const searchPeriod = settings.find(setting => setting.id === SEARCH_PERIOD_ID);
        return searchPeriod ? searchPeriod.value : '';
    }
)

const selectSignificance = createSelector(
    (state) => state.pages['claimAnalysis-landing'],
    (table) => {
        return table.significance;
    }
);

export const ClaimAnalysisPage = connect(
    () => {
        return (state, props) => ({
            fields: selectFields(state, props),
            claimsData: selectClaimsData(state),
            getStatsPending: state.claimAnalysis.getStats.pending,
            claimAnalysisDownloadReportPending: state.claimAnalysis.download.pending,

            claimProcessPending: state.claimAnalysis.claimprocess.pending,
            claimBulkProcessPending: state.claimAnalysis.claimBulkProcess.pending,
            hasClaimProcessorAccess: selectSessionHasClaimProcessorAccess(state),
            sort: selectSort(state),
            customFilter: selectCustomFilter(state),
            filter: selectFilter(state, props),
            rawFilters: selectRawFilters(state),
            selectedClientName: selectSelectedClientName(state),
            claimPageRecord: selectPageRecord(state),
            settings: selectSettings(state, props),
            defaultCustomFilters: selectDefaultCustomFilters(state, props),
            relativeFilter: selectSearchPeriod(state, props),
            significance: selectSignificance(state),
            claimStatusList:selectClaimStatusDropdownValues(state),
            isUnpaid:selectCustomFilerPaidStatus(state)?.value==="false"
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            claimAnalysisLanding: claimAnalysisLanding,
            getClaimAnalysisStats: claimAnalysisStats,
            claimAnalysisSetDrilldown: setDrilldown,
            getClaimStatuses:getClaimStatuses,
            getNpiRoles:npiRolesLanding,
            searchGetByName: getByName,
            searchReset: reset,
            setCustomFilter: (value) => set('claimAnalysis-landing', 'customFilter', value),
            setFilter: (value) => set('claimAnalysis-landing', 'filter', value),
            resetSearchCriteria: (value) => set('claimAnalysis-landing', 'selectedFilter', value),
            resetPagedTable: () => set('claimAnalysis-landing', 'list', []),
            resetCriteriaWaiting: () => set('claimAnalysis-landing', 'waitForCriteriaFilter', true),
            resetSearchAPICompleted: () => set('claimAnalysis-landing', 'isSearchApiCompleted', false),
            downloadAnalysisReport: download,
            flagClaims: flag,
            getTinyContracts: tiny,
            getPayersTiny: getPayersTiny,
            resetClaimProcessError: resetClaimProcessError,
            setOpenAcknowledgeDialog: setOpenAcknowledgeDialog,
            setOpenFlagDialog: setOpenFlagDialog,
            setFlagToggle: setFlagToggle,
            setBulkFlagged: setBulkFlagged,
            setBulkAcknowledge: setBulkAcknowledge,
            setSelectedClaims: setSelectedClaims,
            setOpenPlayDialog: setOpenPlayDialog,
            setClaimUpdateStatusDialog:setClaimUpdateStatusDialog,
            setMulti: obj => setMulti("claimAnalysis-landing", obj),
        }, dispatch)
    })
)(injectIntl(ClaimAnalysisComponent));