import { takeEvery, put, call } from 'redux-saga/effects';

import * as actions from '../actions/hospitalServices';
import * as API from '../api/hospitalServices';
import { sessionErrorHandling } from './session';

function* deleteBulk(action) {
    try {
        yield put({ type: actions.HOSPITAL_SERVICES_DELETE_BULK_PENDING });
        const {
            hospitalServices,
            callback,
            shouldReloadGrid
        } = action.payload;

        yield call(API.deleteHospitalServices, hospitalServices);
        yield put({ type: actions.HOSPITAL_SERVICES_DELETE_BULK_FULFILLED });

        if (callback) {
            callback(shouldReloadGrid);
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.HOSPITAL_SERVICES_DELETE_BULK_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* hospitalServicesImport(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.HOSPITAL_SERVICES_IMPORT_PENDING });
        const payload = yield call(API.importHospitalServices, options);
        yield put({ type: actions.HOSPITAL_SERVICES_IMPORT_FULFILLED, payload });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.HOSPITAL_SERVICES_IMPORT_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* hospitalServicesImportFromRedshift(action) {
    try {
        //const { options, callback } = action.payload;
        yield put({ type: actions.HOSPITAL_SERVICES_IMPORT_HISTORICAL_PENDING });
        const payload = yield call(API.importHospitalServicesFromRedshift);
        yield put({ type: actions.HOSPITAL_SERVICES_IMPORT_HISTORICAL_FULFILLED, payload });
        // if (callback) {
        //     callback();
        // }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.HOSPITAL_SERVICES_IMPORT_HISTORICAL_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* hospitalServicesLookupFromMaster(action) {
    try {
        const { options } = action.payload;
        yield put({ type: actions.HOSPITAL_SERVICES_LOOKUP_MASTER_PENDING });
        const payload = yield call(API.gethospitalServicesFromMaster, options);
        yield put({ type: actions.HOSPITAL_SERVICES_LOOKUP_MASTER_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.HOSPITAL_SERVICES_LOOKUP_MASTER_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

export default function* () {
    yield takeEvery(actions.HOSPITAL_SERVICES_DELETE_BULK, deleteBulk);
    yield takeEvery(actions.HOSPITAL_SERVICES_IMPORT, hospitalServicesImport);
    yield takeEvery(actions.HOSPITAL_SERVICES_IMPORT_HISTORICAL, hospitalServicesImportFromRedshift);
    yield takeEvery(actions.HOSPITAL_SERVICES_LOOKUP_MASTER, hospitalServicesLookupFromMaster);
}