import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import ClaimJsonViewComponent from './ClaimJsonView';
import { getClaimJSONView, resetJSONView,getConsolidatedClaims,resetConsolidatedClaimsView,setClaimLookupId } from '../../../store/actions/claimAnalysis';
import { getModelConsolidatedClaims, resetModelConsolidatedClaimsView } from '../../../store/actions/modeling';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const ClaimJsonView = connect(
    () => {

        const selectClaimNumber = (state, props) => props.match.params.cn;
        const selectClaimId = (state, props) => props.match.params.cid;
        const selectConsolidatedData = (state, props) => state.claimAnalysis.consolidatedClaimsData;
        const selectModelConsolidatedData = (state, props) => state.modeling.consolidatedClaims;
        const isFromModel = window.location?.pathname?.toLowerCase()?.includes('modeling') || false;

        const selectConsolidatedClaims = createSelector(
            selectClaimNumber,
            selectConsolidatedData,
            (claimNumber, consolidatedData) =>
                consolidatedData?.length === 1 &&
                consolidatedData[0] === claimNumber
                    ? []
                    : consolidatedData?.sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))?.reverse()
        );

        const selectConsolidatedModelClaims = createSelector(
            selectClaimNumber,
            selectModelConsolidatedData,
            (claimNumber, consolidatedData) =>
                consolidatedData?.length === 1 &&
                consolidatedData[0] === claimNumber
                    ? []
                    : consolidatedData?.sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))?.reverse()
        );

        const selectFilteredJSON = createSelector(
            (state) => state.claimAnalysis.jsonView,
            (json) => {
                if (json) {
                    const filteredClaim = json.claims?.map(claim => {
                        const filteredClaimCharges = claim.claimCharges?.map(charge => {
                            const allowed = ['svcHcpcsqualifier', 'svcRevenuecode', 'svcPxcode', 'svcMod1', 'svcMod2', 'svcMod3', 'svcMod4', 'svcNdc', 'svcChargeamount', 'svcChargeunits', 'svcServicedate', 'placeofservicecode'];

                            return Object.keys(charge)
                                .filter(key => allowed.includes(key))
                                .reduce((obj, key) => {
                                    obj[key] = charge[key];
                                    return obj;
                                }, {});
                        })
                        return {
                            ...claim,
                            claimCharges: filteredClaimCharges ?? []
                        }
                    })
                    const filteredPayments = json.payments?.map(payment => {
                        const filteredClaimServicePayments = payment.claimServicePayments?.map(csp => {
                            const allowed = ['serviceAdjustments', 'svcChargedamount', 'svcPaidmod1', 'svcPaidmod2', 'svcPaidmod3', 'svcPaidmod4', 'svcPxcode', 'svcRevenuecode', 'svcMod1', 'svcMod2', 'svcMod3', 'svcMod4', 'svcPaidamount', 'svcPaidpxdescription', 'svcPxdescription', 'svcPaidpxcode', 'svcQuantity', 'svcPaidquantity', 'servicedate'];

                            const filteredServiceAdjustments = csp.serviceAdjustments?.map(sa => {
                                const allowed = ['svcchargedamount', 'svcquantity', 'svcrevenuecode', 'svcpxcode', 'svcpaidpxcode', 'casadjgroupcode', 'casadjreasoncode', 'reasonDescription', 'reasonShortDescription', 'isActionable', 'grouping', 'casadjamount', 'casadjquantity', 'casorder']

                                return Object.keys(sa)
                                    .filter(key => allowed.includes(key))
                                    .reduce((obj, key) => {
                                        obj[key] = sa[key];
                                        return obj;
                                    }, {});
                            });

                            let tempObj = {
                                ...csp,
                                serviceAdjustments: filteredServiceAdjustments ?? []
                            }
                            return Object.keys(tempObj)
                                .filter(key => allowed.includes(key))
                                .reduce((obj, key) => {
                                    obj[key] = tempObj[key];
                                    return obj;
                                }, {});
                        });

                        return {
                            ...payment,
                            claimServicePayments: filteredClaimServicePayments ?? null
                        }
                    })
                    return {
                        ...json,
                        claims: filteredClaim,
                        payments: filteredPayments
                    }
                }
                return {}
            }
        );

        const selectJSONForEditor = createSelector(
            selectFilteredJSON,
            (result) => {
                return { json: result, text: undefined, };
            }
        );
    

        return (state, props) => ({
            getJsonPending: state.claimAnalysis.getJsonView.pending,
            getJsonError: state.claimAnalysis.getJsonView.error,
            getJsonErrorMessage: state.claimAnalysis.getJsonView.errorMessage,
            getconsolidatedClaimsPending: isFromModel ? state.claimAnalysis.getConsolidateClaims.pending : state.modeling.getModelCClaims.pending,
            claimNumber: selectClaimNumber(state, props),
            claimJson: selectJSONForEditor(state),
            consolidatedClaimsData: isFromModel ? selectConsolidatedModelClaims(state, props) : selectConsolidatedClaims(state,props),
            isFromModel: isFromModel,
            claimId: isFromModel ? localStorage.getItem('model_detail_Ids') : selectClaimId(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getClaimJSONView: getClaimJSONView,
            resetJSONView: resetJSONView,
            getModelConsolidatedClaims, 
            getConsolidatedClaims, 
            resetConsolidatedClaimsView, 
            resetModelConsolidatedClaimsView,
            resetLookupId:()=>setClaimLookupId("")
        }, dispatch)
    })
)(injectIntl(ClaimJsonViewComponent));