import { Box, Button, Grid } from '@material-ui/core';
import { useAsyncEffect as useAsync, useStatePtr } from '@pure-ptr/react';
import React from 'react';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from '../../partials/content/Portlet';

import DescriptionIcon from '@material-ui/icons/Description';
import FilterListIcon from '@material-ui/icons/FilterList';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import SaveIcon from '@material-ui/icons/Save';

import GetAppIcon from '@material-ui/icons/GetApp';
import { DateRangePicker } from '../../common/dateRangeControl';
import { getLast6Months } from '../../util/date';

import { response } from './mock-data';

import { ContentRow, SelectFromArray } from '../../common/FormControls';
import { tiny as fetchTinyContracts, TinyContract } from '../../store/api/contracts';
import { getCriteriaByPageName } from '../../store/api/search';
import { AnnualForecast } from './state';
import { Comparison, CurrentRate } from './table';

export function NewContractModelSimulator() {
    const annualForecastPtr = useStatePtr( null as AnnualForecast ),
        selectedGroupIdPtr = useStatePtr( null as string ),
        selectedContractPtr = useStatePtr(null as TinyContract ),
        selectedSearchCriteriaPtr = useStatePtr( null as { id: number, criteriaName: string } );

    const { result : lists, isPending, error } = useAsync( async () => {
        const contracts = await fetchTinyContracts();
        selectedContractPtr.set( contracts[0] );

        const searchCriteriaOptions = [
            { id: 0, criteriaName: 'Custom Search' },
            ...( await getCriteriaByPageName({ name: 'claimAnalysis-landing' }) )
        ];
        selectedSearchCriteriaPtr.set( searchCriteriaOptions[0] );

        annualForecastPtr.set( 
            AnnualForecast.fromResponse( response[0], 3 )
         );

        return {
            contracts,
            searchCriteriaOptions
        }
    }, []);

    const dateRangePtr = useStatePtr( getLast6Months );

    const isCustomDateRange = selectedSearchCriteriaPtr.value?.id === 0;

    return isPending ? <div>Loading...</div> : (
            <Portlet fluidHeight>
                <PortletHeader
                    toolbar={
                        <PortletHeaderToolbar fullWidth>
                            <ContentRow spacing={2} 
                                right={<>
                                    <Button startIcon={<SaveIcon />}>
                                        Save
                                    </Button>
                                    <Button startIcon={<OpenInBrowserIcon />}>
                                        Open
                                    </Button>
                                    <Button startIcon={<GetAppIcon />}>
                                        Download
                                    </Button>
                                </>}
                            >
                                <Box display="flex" width={200}>
                                    <SelectFromArray
                                        options={lists.searchCriteriaOptions}
                                        disableClearable
                                        icon={<FilterListIcon />}
                                        getOptionLabel={ x => x.criteriaName }
                                        placeholder="Custom Search Criteria"
                                        valuePtr={selectedSearchCriteriaPtr}
                                        label="Search Criteria"
                                    />
                                </Box>

                                <Box display="flex" width={200}>
                                    <SelectFromArray
                                        disabled={!isCustomDateRange}
                                        options={lists.contracts}
                                        icon={<DescriptionIcon />}
                                        disableClearable
                                        getOptionLabel={ x => x.name }
                                        valuePtr={selectedContractPtr}
                                        label="Contract"
                                    />
                                </Box>

                                <Box display="flex" width={200}>
                                    <DateRangePicker
                                        disabled={!isCustomDateRange}
                                        valuePtr={dateRangePtr} 
                                        label="Date Range" 
                                    />
                                </Box>
                            </ContentRow>
                        </PortletHeaderToolbar>
                    }
                />
                <PortletBody>
                        <Grid container spacing={0} style={{ height: '100%', overflowY: 'scroll'}}>
                            <Grid xs={5} item>
                                <h6>Current reimbursement</h6>
                                <Grid>
                                    <CurrentRate 
                                        selectedGroupIdPtr={selectedGroupIdPtr}   
                                        selectedContractForCurrent={annualForecastPtr.value}/>
                                </Grid>
                            </Grid>

                            <Grid xs={7} item style={{ overflowX: 'scroll' }}>
                                <Grid container style={{ flexWrap: 'nowrap' }}>
                                    { annualForecastPtr.value.years.map( ( year, index ) => (
                                        <Grid item className='pl-3' key={index}>
                                            <h6>{ year }</h6>
                                            <Grid>
                                                <Comparison
                                                    periodPtr={annualForecastPtr}
                                                    selectedGroupIdPtr={selectedGroupIdPtr}
                                                    index={index + 1 }
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>

                </PortletBody>
            </Portlet>
    )
}