import {
    Button,
    Chip,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    ListSubheader,
    MenuItem,
    Paper,
    Select,
    TextField, makeStyles, SwipeableDrawer,
    Typography,
    Box,
    IconButton
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import { Formik, FormikHandlers, FormikHelpers } from "formik";
import React, { useEffect, useState } from 'react';
import SaveCriteriaDialog from './SaveCriteriaDialog';

import { Autocomplete } from '@material-ui/lab';
import { Card } from "react-bootstrap";


import { isArray } from 'lodash';
import { FIELD_TYPE_DATE, FIELD_TYPE_INT, FIELD_TYPE_STRING } from '../../store/constants/pages';
import { PopoverLink } from '../popoverLink';
import { SplitButton } from '../splitButton';
import AlphaSearchFields from './AlphaSearchFields';

const claimTopFiltersEnums = { 'isDenial': 'Denial', 'isPaid': 'Paid', 'contract.contractState.name': 'Contract State' }

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '93%',
    },
    input: {
        marginTop: theme.spacing(2),
        //marginLeft: theme.spacing(2),
        width: '90%',
    },
    iconButton: {
        marginTop: theme.spacing(1),
        padding: 10,
    },
    autocomplete: {
        width: '90%',
    },
    rootChip: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        maxHeight: 80,
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    rootChipPrimaryFilters: {
        display: 'flex',
        justifyContent: 'left',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        maxHeight: 120,
        flexWrap: "wrap"
    },

    button: {
        marginTop: theme.spacing(2),
        //marginLeft: theme.spacing(1),
    },

    deleteButton: {
        marginTop: theme.spacing(2),
        //marginLeft: theme.spacing(2),
        color: '#d9534f',
        height: '31.91px'
    },
    saveButton: {
        marginTop: theme.spacing(2),
    },
    splitButton: {
        marginTop: theme.spacing(2),
    },
    searchButton: {
        marginTop: theme.spacing(2),
        width: '120px',
        background: "#196540",
        padding: 6,
    },
    groupName: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '2',
        pointerEvents: 'none',
        color: 'black',
        background: 'white'
    },
    drawer: {
        '& .MuiDrawer-paper': {
            width: '40%',
            background: '#F8F9FA',
            padding: "10px 20px 10px 20px",


        },
    },
    card: {
        padding: '20px',
        borderRadius: "10px"

    },
    cardHeader: {
        fontWeight: 500,
        paddingBottom: '10px',
        borderBottom: '0.5px solid #6B7280',
        color: '#6B7280',
        marginBottom: '15px'

    },
    formContainer: {
        marginTop: '15px'
    },
    filterChip: {
        color: '#196540',
        borderColor: '#196540',
        '& .MuiSvgIcon-root': {
            color: '#196540'
        }
    },
    customFilterChip: {
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    disabledAddBtn: {
        opacity: 0.5
    },
    noBorder: {
        border: 'none'
    },
    closePanelBtn: {
        height: '30px',
        width: '30px',
        cursor: 'pointer'
    },
    additionalFiltersIcon: {
        '& .MuiSvgIcon-root': {
            fontSize: '40px',
            color: '#196540'
        }
    },
    closeBtnContainer: {
        display: "flex",
        justifyContent: 'flex-end'

    },
    submitBtnContainer: {
        display: "flex", justifyContent: 'flex-end', width: '100%', columnGap: '20px'

    },
    helperTextSelect: {
        fontSize: "10px", color: "red"
    }
}));

const nullItemDropdown = { name: 'none', value: 'none', label: 'none' }

const actions = ['Search', 'Save', 'Save As'];

export default function DataTableHeader({ fields, intl, onFilterChange, onFilterDelete, pending, customFilter, pageName, significance,
    onFilterClear, filter, onFilterSave, filterSaveState, criteria, saveEnabled, onFilterUpdate, openFiltersPanel, actions: { setOpenAdvanceFiltersDialog } }) {
    const classes = useStyles();
    const [equators, setEquators] = useState([]);
    const [open, setOpen] = useState(false);
    const [searchAction, setSearchAction] = useState('Search');
    const [groupedFields, setGroupedFields] = useState([]);
    const [areFieldsGrouped, setAreFieldsGrouped] = useState(false);
    const [isDropdown, setIsDropdown] = useState(false);
    const [isMultiSelect, setIsMultiSelect] = useState(false);
    const [isStrictDropdown, setIsStrictDropdown] = useState(false);
    const [dropdownValues, setDropdownValues] = useState([]);
    const [isFilterUpdate, setFilterUpdate] = useState(false);
    const [updatingFieldIndex, setUpdateFieldIndex] = useState(null);
    interface FormValues {
        field: string;
        equator: string;
        search: string;
        isBetween?: boolean;
        startsAt?: string;
        endsAt?: string;
        addFilter?: boolean;
    }

    const [formValues, setFormValues] = useState<FormValues>({ field: '', equator: '', search: '', isBetween: false, startsAt: '', endsAt: '', addFilter: false });
    const [fieldType, setFieldType] = useState(FIELD_TYPE_STRING);

    let setField = null;

    useEffect(() => {
        const groupFields = fields.filter(field => field.options.group).map(field => field.options.group);
        const groupNames = Array.from(new Set(groupFields));
        if (groupNames && groupNames.length > 0) {
            const tempFields = groupNames.map(groupName => {
                return {
                    groupName: groupName,
                    fields: fields.filter(field => field.options.group === groupName)
                }
            })
            setAreFieldsGrouped(true)
            setGroupedFields(tempFields)
        }

    }, [fields]);

    useEffect(() => {
        return () => {
            setOpenAdvanceFiltersDialog(false)
        };

    }, []);


    function handleDelete(f, index) {
        setFormValues({
            field: '', equator: '', search: ''
        });

        setFilterUpdate(false);
        setUpdateFieldIndex(null);
        onFilterDelete(f, index);

    }

    function handleFilterChange(newFilter, isAddFilter) {
        onFilterChange(newFilter, isAddFilter);
    }

    function handleFilterClear() {
        onFilterClear();
    }

    function getLabel({ field, value, equator }) {
        const { label, options } = fields?.find(x => x.id === field) || {};
        let extractedEquator = null;
        let extractedValue = null;
        if (options) {
            const { equators: { equatorArr } } = options;
            if (equatorArr && isArray(equatorArr)) {
                extractedEquator = equatorArr?.find(eq => eq.value === equator);
            }
            if (options.isStrictDropdown) {
                extractedValue = options.dropdownValues?.find(x => x.value === value);
            }
        }
        if (extractedEquator) {
            if (extractedValue) {
                return `${label} : '${extractedEquator.label}' ${extractedValue.label}`;
            }
            return `${label} : '${extractedEquator.label}' ${value}`;
        }

        // fallback if we don't get the equator.
        return `${label} : '${equator}' ${value}`;
    }


    function getPrimaryFiltersLabel({ field, value, equator }) {
        const { field: label, options } = customFilter?.find(x => x.field === field);
        let extractedEquator = null;
        let extractedValue = null;
        if (options) {
            const { equators: { equatorArr } } = options;
            if (equatorArr && isArray(equatorArr)) {
                extractedEquator = equatorArr.find(eq => eq.value === equator);
            }
            if (options.isStrictDropdown) {
                extractedValue = options.dropdownValues.find(x => x.value === value);
            }
        }
        if (extractedEquator) {
            if (extractedValue) {
                return `${label} : '${extractedEquator.label}' ${extractedValue.label}`;
            }
            return `${label} : '${extractedEquator.label}' ${value}`;
        }

        // fallback if we don't get the equator.
        return `${claimTopFiltersEnums[label] || label} : '${equator}' ${value}`;
    }

    const getEquators = (value) => {
        const { options } = fields.find(field => field.id === value);
        if (options) {
            const { equators: { equatorArr, type } } = options;
            if (equatorArr && isArray(equatorArr)) {
                setEquators(equatorArr);
                setField('equator', equatorArr[0]?.value);
                setField('isBetween', equatorArr[0]?.value === 'alpha_between' || equatorArr[0]?.value === 'in_between' ? true : false);
                setFieldType(type);
            }

            // set isDropdown flag and array
            if ((options.isDropdown || options.isStrictDropdown) && options.dropdownValues) {
                const nullableDropdownValue = options.isStrictDropdown ? nullItemDropdown : 'none'
                setDropdownValues([nullableDropdownValue, ...options.dropdownValues.sort()])
                setIsDropdown(options.isDropdown ? true : false);

                setIsStrictDropdown(options.isStrictDropdown ? true : false);
                setIsMultiSelect(options?.isMultiSelection ? true : false)
            } else {
                setIsMultiSelect(false)
                setIsDropdown(false);
                setIsStrictDropdown(false);
                setDropdownValues([]);
            }
        }
    }

    const bindSetFeild = (setFieldValue) => {
        setField = setFieldValue;
    };

    const handleSaveSearch = (values, handleClose, existingCriteria) => {
        let criteria;
        if (pageName === 'claimAnalysis-landing') {
            let tempFilters = [];
            const keys = ["acknowledged", "flag", "isDenial", "isPaid", "contract.contractState.name"];

            for (let key of keys) {
                const foundItem = customFilter.find(item => item.field === key);
                if (foundItem) {
                    tempFilters.push({
                        field: key,
                        equator: 'equals',
                        value: foundItem.value // true || false
                    });
                } else {
                    tempFilters.push({
                        field: key,
                        equator: 'equals',
                        value: 'both'
                    });
                }
            }

            const dates = customFilter.filter(cf => cf.field === 'remitdate' || cf.field === 'servicedate');

            if (dates.length > 0) {
                tempFilters.push({
                    field: dates[0]?.field,
                    type: 'DATE',
                    relativeFilter: dates[0]?.relativeFilter,
                    dates: [
                        { equator: dates[0].equator, value: dates[0].value },
                        { equator: dates[1].equator, value: dates[1].value },
                    ]
                });
            }

            tempFilters.push({
                field: 'significance',
                type: 'SIGNIFICANCE',
                value: significance
            });

            criteria = {
                criteria: JSON.stringify(
                    [
                        ...filter.map(item => ({ ...item, actualFilter: true })),
                        ...tempFilters.map(item => ({ ...item, customFilter: true }))
                    ]),
                ...values,
            }
        } else {
            criteria = {
                criteria: JSON.stringify([...filter.map(item => ({ ...item, actualFilter: true }))]),
                ...values,
            }
        }

        onFilterSave(criteria, handleClose, existingCriteria);
    }

    const handleClose = () => {
        setOpen(false);
        setSearchAction("Search")
    }

    const onMenuClick = (option, callback) => {
        setSearchAction(option);
        setOpen(true);

        if (callback) {
            callback();
        }
    }

    const handleUpdateClick = (f, index) => {
        if (f.equator === 'alpha_between' || f.equator === 'in_between') {
            const splitedValue = f.value.split('&');
            setFormValues({
                field: f.field,
                equator: f.equator,
                search: '',
                startsAt: splitedValue[0],
                endsAt: splitedValue[1],
                isBetween: true,
                addFilter: false
            });
        } else {
            setFormValues({
                field: f.field,
                equator: f.equator,
                search: f.value,
                isBetween: false,
                startsAt: '',
                endsAt: '',
                addFilter: false
            });
        }

        getEquators(f.field);

        setFilterUpdate(true);
        setUpdateFieldIndex(index);
    }

    const handleUpdate = (values) => {
        let newFilterObj = {
            field: values.field,
            equator: values.equator,
            value: ''
        }
        if (values.isBetween) {
            newFilterObj = {
                ...newFilterObj,
                value: `${values.startsAt}&${values.endsAt}`,
            }
        } else {
            newFilterObj = {
                ...newFilterObj,
                value: values.search,
            }
        }
        onFilterUpdate(newFilterObj, updatingFieldIndex);

        setFilterUpdate(false);
        setFormValues({
            field: '',
            equator: '',
            search: '',
            startsAt: '',
            endsAt: ''
        });
        setUpdateFieldIndex(null);
    }

    const handleCancelUpdate = () => {
        setFilterUpdate(false);
        setFormValues({
            field: '',
            equator: '',
            search: '',
            startsAt: '',
            endsAt: ''
        });
        setUpdateFieldIndex(null);
    }

    const handleEquatorChange = (setFieldValue) => (e) => {
        const eqValue = e.target.value;
        setFieldValue('equator', eqValue);
        setFieldValue('isBetween', eqValue === 'alpha_between' || eqValue === 'in_between' ? true : false);
    }

    const handleFieldChange = (setFieldValue, value) => {
        const fieldArr = [...fields]
        const fieldType = fieldArr?.find((item) => item?.id === value)
        setFieldValue('field', value);
        setFieldValue('search', (fieldType?.options?.isStrictDropdown || fieldType?.options?.isDropdown) ? fieldType?.options?.isMultiSelection ? ['none'] : 'none' : '');
        setFieldValue('startsAt', '');
        setFieldValue('endsAt', '');
    }

    function hoverColumn(text) {
        return (
            <PopoverLink
                columnData={<i className={`fas fa-question-circle text-info`}></i>}
                hoverData={[
                    {
                        data: text
                    }
                ]}
            />
        );
    }

    const closeAdvancedFiltersPanel = () => {
        setOpenAdvanceFiltersDialog(false)

    }
    const disableAddBtn = (values) => {
        if (values?.equator === "alpha_between" || values?.equator === "in_between") {
            return !values?.field || !values?.startsAt || !values?.endsAt
        }
        return !values?.field || !values?.equator || values?.search === ''
    }

    const doesHaveFilters = (values) => {
        if (values?.equator === "alpha_between") {
            return filter?.length>0 && !values?.field && !values?.startsAt && !values?.endsAt
        }
        return filter?.length>0 && !values?.field && !values?.equator && values?.search === ''

    }

    return (
        <Paper>
            <Formik
                initialValues={formValues}
                enableReinitialize
                validate={values => {
                    
                    if (doesHaveFilters(values)) { return }

                    const errors: { field?: string; startsAt?: string; endsAt?: string; search?: string } = {};

                    if (!values.field) {
                        errors.field = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if (values.isBetween) {
                        if (values.startsAt === '') {
                            errors.startsAt = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        if (values.endsAt === '') {
                            errors.endsAt = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        if (fieldType === FIELD_TYPE_STRING && values.startsAt && values.endsAt && (values.endsAt.localeCompare(values.startsAt) === -1)) {
                            errors.endsAt = intl.formatMessage({
                                id: "BETWEEN.FILTER.LESS.THAN.CHARACTER.MESSAGE"
                            });
                        } else if (fieldType === FIELD_TYPE_INT && values.startsAt !== '' && values.endsAt < values.startsAt) {
                            errors.endsAt = intl.formatMessage({
                                id: "BETWEEN.FILTER.LESS.THAN.INTEGER.MESSAGE"
                            });
                        } else if (fieldType === FIELD_TYPE_DATE && values.startsAt && (new Date(values.endsAt) < new Date(values.startsAt))) {
                            errors.endsAt = intl.formatMessage({
                                id: "BETWEEN.FILTER.LESS.THAN.DATE.MESSAGE"
                            });
                        }
                    } else {
                        if (values.search === '') {
                            errors.search = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                    }

                    return errors;
                }}
                onSubmit={(values: FormValues, { setSubmitting, resetForm }) => {
                    if(isDropdown && values?.search===null)
                    {return}
                    if (isFilterUpdate) {
                        handleUpdate(values);
                    } else {
                        let newFilter = {
                            field: values.field,
                            equator: values.equator,
                            value: ''
                        }
                        if (values.isBetween) {
                            newFilter = {
                                ...newFilter,
                                value: `${values.startsAt}&${values.endsAt}`,
                            }
                        } else {
                            newFilter = {
                                ...newFilter,
                                value: values.search,
                            }
                        }
                        handleFilterChange(newFilter, values.addFilter);
                        if (!values?.addFilter) {
                            closeAdvancedFiltersPanel()
                        }
                        resetForm({ values: { field: '', search: '', equator: '', startsAt: '', endsAt: '', addFilter: false } });
                        setFieldType(FIELD_TYPE_STRING);
                    }
                    setSubmitting(false);
                    setIsDropdown(false);
                    setIsStrictDropdown(false);
                    setDropdownValues([]);
                }}
            >

                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue
                    } = props;
                    bindSetFeild(setFieldValue);
                    return (
                        <SwipeableDrawer
                            className={classes.drawer}
                            anchor={'right'}
                            open={openFiltersPanel}
                            onClose={() => closeAdvancedFiltersPanel()}
                            onOpen={() => undefined}
                        >

                            <Box className={classes.closeBtnContainer}>

                                <IconButton
                                    className={classes.closePanelBtn}
                                    onClick={() => closeAdvancedFiltersPanel()}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>

                            <Card className={`mt-3 ${classes.card}`}>
                                <Typography variant="h5" className={classes.cardHeader}>Advanced Filters</Typography>
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={1} className={classes.formContainer}>

                                        <Grid item xs={12}>
                                            <div className={classes.rootChip}>
                                                {filter.map((f, index) => {
                                                    return <Chip variant="outlined"
                                                        size="medium"
                                                        className={classes.filterChip}
                                                        key={index}
                                                        label={getLabel(f)}
                                                        disabled={pending}
                                                        onDelete={() => handleDelete(f, index)}
                                                        onClick={() => handleUpdateClick(f, index)}
                                                    />
                                                })}
                                            </div>
                                        </Grid>
                                        <Grid xs={8} item >
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="field">Select Field</InputLabel>
                                                <Select
                                                    name='field'
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        handleFieldChange(setFieldValue, event.target.value);
                                                        getEquators(event.target.value);
                                                    }}
                                                    value={values.field}
                                                    error={Boolean(touched.field && errors.field)}
                                                    inputProps={{
                                                        name: "field",
                                                        id: "field"
                                                    }}

                                                >
                                                    {areFieldsGrouped ?
                                                        groupedFields.map((group) => (
                                                            [
                                                                group.groupName && <ListSubheader className={classes.groupName}>{group.groupName}</ListSubheader>,
                                                                group.fields.map(({ id, label, options }) => (
                                                                    !options.hideFromFilter && <MenuItem
                                                                        className='ml-3'
                                                                        key={label}
                                                                        value={id}
                                                                    >
                                                                        {label}
                                                                    </MenuItem>
                                                                ))
                                                            ]
                                                        )) :
                                                        fields.map(({ id, label, options }) => (
                                                            !options.hideFromFilter && <MenuItem
                                                                key={label}
                                                                value={id}
                                                            >
                                                                {label}
                                                            </MenuItem>
                                                        ))
                                                    }

                                                </Select>
                                                {touched.field && errors.field && <Box component="span" className={classes.helperTextSelect} > Required Field</Box>}

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="equator">Select Equator</InputLabel>
                                                <Select
                                                    onBlur={handleBlur}
                                                    onChange={handleEquatorChange(setFieldValue)}
                                                    value={values.equator}
                                                    error={Boolean(touched.equator && errors.equator)}
                                                    inputProps={{
                                                        name: "equator",
                                                        id: "equator"
                                                    }}
                                                >
                                                    {equators && equators.map(({ value, label }) => (
                                                        <MenuItem
                                                            key={label}
                                                            value={value}
                                                        >
                                                            {label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={10} >

                                                    {isDropdown && (values.equator === 'equals' || values.equator === 'not_equal_to') ? <>
                                                        <Autocomplete
                                                            multiple={isMultiSelect}
                                                            className={classes.autocomplete}
                                                            onChange={(e, value) => setFieldValue("search", value)}
                                                            value={isMultiSelect ? values?.search || [] : values?.search}
                                                            options={dropdownValues}
                                                            getOptionLabel={(option) => option === 'none' ? `--None--` : option}
                                                            getOptionSelected={(option, value) => option === value}

                                                            renderTags={(value, getTagProps) =>
                                                                value.length > 2
                                                                    ? [
                                                                        ...value?.slice(0, 2)?.map((option, index) => (
                                                                            <Chip
                                                                                key={index}
                                                                                label={option}
                                                                                {...getTagProps({ index })}
                                                                            />
                                                                        )),
                                                                        <Chip label={`+${value.length - 2}`} key={value.length} />
                                                                    ]
                                                                    : value?.map((option, index) => (
                                                                        <Chip
                                                                            key={index}
                                                                            label={option}
                                                                            {...getTagProps({ index })}
                                                                        />
                                                                    ))
                                                            }
                                                            renderInput={params => (
                                                                <TextField {...params}
                                                                    className={classes.input + "mt-0"}
                                                                    error={Boolean(touched.search && errors.search)}
                                                                    helperText={touched.search && errors.search}
                                                                    label="Select Option"
                                                                />
                                                            )}
                                                        />
                                                    </> : isStrictDropdown && (values.equator === 'equals' || values.equator === 'not_equal_to') ? <>
                                                        <FormControl className={classes.formControl}>
                                                            <InputLabel htmlFor="equator">Select Option</InputLabel>
                                                            <Select
                                                                onBlur={handleBlur}
                                                                onChange={e => setFieldValue('search', e.target.value)}
                                                                value={values.search}
                                                                error={Boolean(touched.search && errors.search)}
                                                                inputProps={{
                                                                    name: "search",
                                                                    id: "search"
                                                                }}
                                                            >
                                                                {dropdownValues && dropdownValues.map(({ value, label }) => (
                                                                    <MenuItem
                                                                        key={label}
                                                                        value={value}
                                                                    >
                                                                        {label === 'none' ? `--None--` : label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </> : values.equator === 'alpha_between' || values.equator === 'in_between' ? <>
                                                        <AlphaSearchFields
                                                            handleBlur={handleBlur}
                                                            handleChange={handleChange}
                                                            errors={errors}
                                                            touched={touched}
                                                            values={values}
                                                            classes={classes}
                                                            fieldType={fieldType}
                                                        />
                                                    </>
                                                        :
                                                        <TextField
                                                            type={fieldType}
                                                            name="search"
                                                            className={classes.input}
                                                            placeholder="Search"
                                                            color="secondary"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.search}
                                                            helperText={touched.search && errors.search}
                                                            error={Boolean(touched.search && errors.search)}
                                                            InputLabelProps={fieldType === FIELD_TYPE_DATE ? {
                                                                shrink: true,
                                                            } : {}}
                                                            InputProps={{
                                                                endAdornment: (fieldType && fieldType !== FIELD_TYPE_DATE) ? <InputAdornment position="end">
                                                                    <span>
                                                                        {hoverColumn(fieldType === FIELD_TYPE_INT ?
                                                                            'Only single numeric value is allowed' :
                                                                            'Add single string value or use comma separation for multiple values'
                                                                        )}
                                                                    </span>
                                                                </InputAdornment> : <></>
                                                            }}
                                                        />
                                                    }
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <IconButton
                                                        disabled={disableAddBtn(values)}
                                                        onClick={async () => {
                                                            setFieldValue('addFilter', true)
                                                            await Promise.resolve()
                                                            handleSubmit()

                                                        }}
                                                        className={classes.additionalFiltersIcon}

                                                        name="add">
                                                        <AddCircleIcon
                                                            className={`${disableAddBtn(values) ? classes.disabledAddBtn : ''}`}
                                                        />
                                                    </IconButton>

                                                </Grid>

                                                {open && <SaveCriteriaDialog
                                                    intl={intl}
                                                    open={open}
                                                    handleClose={handleClose}
                                                    handleSaveSearch={handleSaveSearch}
                                                    criteria={searchAction === 'Save' ? criteria : null}
                                                    searchAction={searchAction}
                                                />
                                                }
                                                <Box className={classes.submitBtnContainer}>
                                                    <Grid item>
                                                        <Grid container>
                                                            <Grid item sm={7} xs={6}>
                                                                {isFilterUpdate ? <Button
                                                                    type="submit"
                                                                    className={classes.searchButton}
                                                                    color="primary"
                                                                    variant="contained"
                                                                    size="small"
                                                                    startIcon={<RefreshIcon />}
                                                                    disabled={pending}
                                                                >
                                                                    Update
                                                                </Button> :
                                                                    saveEnabled ? (
                                                                        <SplitButton
                                                                            className={classes.splitButton}
                                                                            options={!criteria ? actions?.filter((item) => item !== "Save") : actions} //In case of no criteria, We don't show the option to update it
                                                                            handleSubmit={handleSubmit}
                                                                            onMenuClick={onMenuClick}
                                                                            disabled={pageName !== 'claimAnalysis-landing' || pending}
                                                                            searchAction={searchAction}
                                                                            pageName={pageName}
                                                                        />
                                                                    ) : (
                                                                        <Button
                                                                            type="submit"
                                                                            className={classes.searchButton}
                                                                            color="primary"
                                                                            variant="contained"
                                                                            size="small"
                                                                            startIcon={<SearchIcon />}
                                                                            disabled={pending}
                                                                        >
                                                                            Search
                                                                        </Button>
                                                                    )}
                                                            </Grid>

                                                        </Grid>


                                                    </Grid>
                                                    <Grid item >
                                                        {isFilterUpdate ? <Button
                                                            variant="outlined"
                                                            size="small"
                                                            color="default"
                                                            onClick={handleCancelUpdate}
                                                            className={classes.deleteButton}
                                                            startIcon={<CancelIcon />}
                                                            disabled={pending}
                                                        >
                                                            Cancel
                                                        </Button> : <Button
                                                            variant="outlined"
                                                            size="small"
                                                            color="default"
                                                            onClick={handleFilterClear}
                                                            className={classes.deleteButton}
                                                            endIcon={<DeleteIcon />}
                                                            disabled={pending}
                                                        >
                                                            Clear
                                                        </Button>}

                                                    </Grid>
                                                </Box>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </form>


                            </Card>
                        </SwipeableDrawer>
                    );
                }}
            </Formik>

        </Paper >
    )
}