import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormGroup, Grid, IconButton, InputAdornment, makeStyles, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { DataTile } from '../../common/widgets/DataTile';
import { Portlet, PortletBody } from '../../partials/content/Portlet';
import { createFloatingPoint } from '../../util/format';

import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(theme => {
    return {
        portlet: {
            'box-shadow': 'none',
        },
        parentBox: {
            border: `1px solid #ECE5E5`,
            padding: '15px',
            borderRadius: '15px',
            marginTop: '10px',
            "& th": {
                padding: '10px 24px 10px 16px',
                '@media (max-width: 1440px)': {
                    padding: "10px 5px 10px 5px"
                },
                '@media (max-width: 1280px)': {
                    padding: "3px 5px 3px 5px"
                },
                '@media (max-width: 1024px)': {
                    padding: "6px 5px 6px 5px"
                },
            },
            "& td": {
                padding: '10px 24px 10px 16px',
                '@media (max-width: 1440px)': {
                    padding: "10px 5px 10px 5px"
                },
                '@media (max-width: 1280px)': {
                    padding: "3px 5px 3px 5px"
                },
                '@media (max-width: 1024px)': {
                    padding: "6px 5px 6px 5px"
                }
            },
        },
        childBox: {
            padding: '10px',
        },
        childBorder: {
            borderRadius: '15px',
            border: `1px solid #ECE5E5`,
        },
        formControl: {
            width: '90%',
        },
        groupRow: {
            backgroundColor: '#e8f0e6',

        },
        groupColor: {
            color: '#1e7c4f !important',
            fontWeight: '600'
        },
        inputNumberControl: {
            width: '100px',
            height: '20px',
            margin: '0px',
            paddingLeft: '5px',
            paddingRight: '5px'
        }
    }
});


function ContractModelSimulator() {
    const classes = useStyles();

    const [nameType, setNameType] = useState('ruleName');
    const [selectedContractForCurrent, setSelectedContract] = useState(null);
    const [selectedContractForDifference24, setSelectedContractForDifference24] = useState(null);
    const [selectedContractForDifference25, setSelectedContractForDifference25] = useState(null);
    const [selectedContractForDifference26, setSelectedContractForDifference26] = useState(null);

    const [isDialogOpen, setDialogOpen] = useState(false);
    const [selectedYear, setSelectedYear] = useState(null);
    const [newRateValue, setNewRateValue] = useState('');
    const [newVolumeValue, setNewVolumeValue] = useState('');

    useEffect(() => {
        setSelectedContract(response[0]);
        setSelectedContractForDifference24(response[0]);
        setSelectedContractForDifference25(response[0]);
        setSelectedContractForDifference26(response[0]);
    }, [response]);

    const getCurrentTotal = () => {
        let total = 0;
        if (selectedContractForCurrent && selectedContractForCurrent.groups) {
            for (let group of selectedContractForCurrent.groups) {
                for (let claim of group.claims) {
                    total = total + (parseFloat(claim.units) * parseFloat(claim.actualPay));
                }
            }
        }

        return `$ ${createFloatingPoint(total)}`;
    }

    const getDifferenceTotal = (_diffObj) => {
        let total = 0;
        if (_diffObj && _diffObj.groups) {
            for (let group of _diffObj.groups) {
                for (let claim of group.claims) {
                    total = total + parseFloat(claim.newCalc ?? 0);
                }
            }
        }

        return `$ ${createFloatingPoint(total)}`;
    }

    const handleNewRateChange24 = (e, gIndex, cIndex) => {
        const newRate = parseFloat(e.target.value) * parseFloat(selectedContractForDifference24.groups[gIndex].claims[cIndex].units);
        const total = parseFloat(selectedContractForDifference24.groups[gIndex].claims[cIndex].units) * parseFloat(selectedContractForDifference24.groups[gIndex].claims[cIndex].actualPay);
        const difference = newRate - total;
        const Percentage = difference / total * 100;

        let temp = JSON.parse(JSON.stringify(selectedContractForDifference24));
        temp.groups[gIndex].claims[cIndex].newCalc = newRate;
        temp.groups[gIndex].claims[cIndex].newRate = e.target.value;
        temp.groups[gIndex].claims[cIndex].difference = difference;
        temp.groups[gIndex].claims[cIndex].Percentage = isFinite(Percentage) ? Percentage : newRate;

        setSelectedContractForDifference24(temp);
    }

    const handleNewRateChange25 = (e, gIndex, cIndex) => {
        const newRate = parseFloat(e.target.value) * parseFloat(selectedContractForDifference25.groups[gIndex].claims[cIndex].units);
        const total = parseFloat(selectedContractForDifference25.groups[gIndex].claims[cIndex].units) * parseFloat(selectedContractForDifference25.groups[gIndex].claims[cIndex].actualPay);
        const difference = newRate - total;
        const Percentage = difference / total * 100;

        let temp = JSON.parse(JSON.stringify(selectedContractForDifference25));
        temp.groups[gIndex].claims[cIndex].newCalc = newRate;
        temp.groups[gIndex].claims[cIndex].newRate = e.target.value;
        temp.groups[gIndex].claims[cIndex].difference = difference;
        temp.groups[gIndex].claims[cIndex].Percentage = isFinite(Percentage) ? Percentage : newRate;


        setSelectedContractForDifference25(temp);
    }

    const handleNewRateChange26 = (e, gIndex, cIndex) => {
        const newRate = parseFloat(e.target.value) * parseFloat(selectedContractForDifference26.groups[gIndex].claims[cIndex].units);
        const total = parseFloat(selectedContractForDifference26.groups[gIndex].claims[cIndex].units) * parseFloat(selectedContractForDifference26.groups[gIndex].claims[cIndex].actualPay);
        const difference = newRate - total;
        const Percentage = difference / total * 100;

        let temp = JSON.parse(JSON.stringify(selectedContractForDifference26));
        temp.groups[gIndex].claims[cIndex].newCalc = newRate;
        temp.groups[gIndex].claims[cIndex].newRate = e.target.value;
        temp.groups[gIndex].claims[cIndex].difference = difference;
        temp.groups[gIndex].claims[cIndex].Percentage = isFinite(Percentage) ? Percentage : newRate;


        setSelectedContractForDifference26(temp);
    }


    const handleNextClaim = () => {

        const currentIndex = response?.findIndex(item => item.id === selectedContractForCurrent?.id);

        const nextindex = currentIndex + 1;
        const nextOj = response[nextindex];
        if (nextOj) {
            setSelectedContract(nextOj);
            setSelectedContractForDifference24(nextOj);
            setSelectedContractForDifference25(nextOj);
            setSelectedContractForDifference26(nextOj);
        }
    }

    const handlePreviousClaim = () => {

        const currentIndex = response?.findIndex(item => item.id === selectedContractForCurrent?.id);


        const prevIndex = currentIndex - 1;
        const prevobj = response[prevIndex];
        if (prevobj) {
            setSelectedContract(prevobj);
            setSelectedContractForDifference24(prevobj);
            setSelectedContractForDifference25(prevobj);
            setSelectedContractForDifference26(prevobj);
        }
    }

    const isPreviousButtonAvailable = () => {
        const currentIndex = response?.findIndex(item => item.id === selectedContractForCurrent?.id);
        return currentIndex > 0;
    }

    const isNextButtonAvailable = () => {
        const currentIndex = response?.findIndex(item => item.id === selectedContractForCurrent?.id);
        return currentIndex !== (response.length - 1);
    }

    const getRowCurrentTotal = ({ units, actualPay }) => {
        return createFloatingPoint(parseFloat(units) * parseFloat(actualPay));
    }

    const handleDialogOpen = (year) => {
        setSelectedYear(year)
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedYear(null);
        setNewRateValue('');
        setNewVolumeValue('');
    }

    const handleApplyCacl = () => {
        if (selectedYear === 24) {

            let newModifiedObj = JSON.parse(JSON.stringify(selectedContractForDifference24));

            const groups = newModifiedObj.groups.map(group => {

                const claims = group.claims.map(claim => {

                    let newUnits = newVolumeValue ? parseFloat(claim.units) + (parseFloat(claim.units) * newVolumeValue / 100) : parseFloat(claim.units);
                    newUnits = Math.round(newUnits);

                    let newRate = newRateValue ? parseFloat(claim.actualPay) + (parseFloat(claim.actualPay) * newRateValue / 100) : parseFloat(claim.actualPay);
                    newRate = Math.round(newRate * 100) / 100;

                    const prevTotal = parseFloat(claim.units) * parseFloat(claim.actualPay);

                    const total = parseFloat(newUnits * newRate);
                    const difference = total - prevTotal;
                    const Percentage = difference / total * 100;

                    return {
                        ...claim,
                        units: newUnits,
                        newRate,
                        newCalc: total,
                        difference,
                        Percentage
                    }
                })

                return {
                    ...group,
                    claims
                }
            });

            setSelectedContractForDifference24({
                ...newModifiedObj,
                groups
            });

            handleDialogClose();

        } else if (selectedYear === 25) {

            let newModifiedObj = JSON.parse(JSON.stringify(selectedContractForDifference25));

            const groups = newModifiedObj.groups.map(group => {

                const claims = group.claims.map(claim => {

                    let newUnits = newVolumeValue ? parseFloat(claim.units) + (parseFloat(claim.units) * newVolumeValue / 100) : parseFloat(claim.units);
                    newUnits = Math.round(newUnits);

                    let newRate = newRateValue ? parseFloat(claim.actualPay) + (parseFloat(claim.actualPay) * newRateValue / 100) : parseFloat(claim.actualPay);
                    newRate = Math.round(newRate * 100) / 100;
                    const prevTotal = parseFloat(claim.units) * parseFloat(claim.actualPay);

                    const total = parseFloat(newUnits * newRate);
                    const difference = total - prevTotal;
                    const Percentage = difference / total * 100;

                    return {
                        ...claim,
                        units: newUnits,
                        newRate,
                        newCalc: total,
                        difference,
                        Percentage
                    }
                })

                return {
                    ...group,
                    claims
                }
            });

            setSelectedContractForDifference25({
                ...newModifiedObj,
                groups
            });

            handleDialogClose();

        } else {
            let newModifiedObj = JSON.parse(JSON.stringify(selectedContractForDifference26));

            const groups = newModifiedObj.groups.map(group => {

                const claims = group.claims.map(claim => {

                    let newUnits = newVolumeValue ? parseFloat(claim.units) + (parseFloat(claim.units) * newVolumeValue / 100) : parseFloat(claim.units);
                    newUnits = Math.round(newUnits);

                    let newRate = newRateValue ? parseFloat(claim.actualPay) + (parseFloat(claim.actualPay) * newRateValue / 100) : parseFloat(claim.actualPay);
                    newRate = Math.round(newRate * 100) / 100;

                    const prevTotal = parseFloat(claim.units) * parseFloat(claim.actualPay);

                    const total = parseFloat(newUnits * newRate);
                    const difference = total - prevTotal;
                    const Percentage = difference / total * 100;

                    return {
                        ...claim,
                        units: newUnits,
                        newRate,
                        newCalc: total,
                        difference,
                        Percentage
                    }
                })

                return {
                    ...group,
                    claims
                }
            });

            setSelectedContractForDifference26({
                ...newModifiedObj,
                groups
            });

            handleDialogClose();
        }
    }

    return (
        <div>
            <Portlet className={classes.portlet}>
                <PortletBody>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <label className='my-2'><b>Search Criteria</b></label>
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    disableClearable
                                    // onChange={handleChange}
                                    value={{ id: 1, criteriaName: 'Variance Report' }}
                                    options={[{ id: 1, criteriaName: 'Variance Report' }, { id: 2, criteriaName: 'Professional Report' }]}
                                    className={classes.formControl}
                                    getOptionLabel={(option) => option.criteriaName}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            name="criteria"
                                            label='Search Criteria'
                                        />
                                    )}
                                    renderOption={(option) => (
                                        <Grid container>
                                            <Grid item xs={11}>
                                                {option.criteriaName}
                                            </Grid>
                                            {option.isPublic && (
                                                <Grid item xs={1}>
                                                    <Tooltip title="Public">
                                                        <i className={classNames("fa fa-user-friends", classes.icon)}></i>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <DataTile title="Current Reimbursement" value={`${getCurrentTotal()}`} isTwoDecimal={false} color="info" />
                        </Grid>
                        <Grid item xs={3}>
                            <DataTile title="Contract" value={selectedContractForCurrent?.name} isTwoDecimal={false} color="success" />
                        </Grid>
                        <Grid item xs={3}>
                            <DataTile title="Date" value={`01/01/2023 - 06/01/2023`} isTwoDecimal={false} color="success" />
                        </Grid>
                    </Grid>

                    <Grid className='pt-4' container>
                        <Grid item xs={6}>
                            <Grid className='d-flex align-items-center'>
                                <h5 className='mb-0 mr-2'> {selectedContractForCurrent?.name}</h5>
                                {<IconButton disabled={!isPreviousButtonAvailable()} onClick={handlePreviousClaim} className='p-1'>
                                    <ArrowBackIcon />
                                </IconButton>}
                                {<IconButton disabled={!isNextButtonAvailable()} onClick={handleNextClaim} className='p-1' >
                                    <ArrowForwardIcon />
                                </IconButton>}
                                <Grid className='pl-3'>
                                    <FormGroup>
                                        <Typography component="div">
                                            <Grid component="label" className='mb-0' container alignItems="center" spacing={0}>
                                                <Grid item>Rule</Grid>
                                                <Grid item>
                                                    <Switch
                                                        checked={nameType === 'revcodeDescription'}
                                                        onChange={e => {
                                                            setNameType(e.target.checked ? 'revcodeDescription' : 'ruleName');
                                                        }}
                                                        name="active" />
                                                </Grid>
                                                <Grid item>RevCode</Grid>
                                            </Grid>
                                        </Typography>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid className='d-flex justify-content-end'>
                                <Grid item>
                                    <Tooltip title="Export">
                                        <IconButton aria-label="export" size='small'>
                                            <GetAppIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid className={classes.parentBox}>
                        <Grid container spacing={2}>
                            <Grid xs={6} item className={classes.childBox}>
                                <h6>Current Rate</h6>
                                <Grid className={classes.childBorder}>
                                    <Table aria-label="MultiProcedure Percentage Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='left'>Group </TableCell>
                                                <TableCell align='left'>Unit </TableCell>
                                                <TableCell align='left'>Pay Method </TableCell>
                                                <TableCell align='left'>Current Rate </TableCell>
                                                <TableCell align='left'>Current Calc. </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedContractForCurrent?.groups?.map(group => {
                                                return <React.Fragment key={group.name}>
                                                    {nameType === 'ruleName' && <TableRow className={classes.groupRow}>
                                                        <TableCell className={classes.groupColor} colSpan={5} align='left'>{group.name} </TableCell>
                                                    </TableRow>
                                                    }
                                                    {group.claims.map(claim => {
                                                        return <TableRow key={claim.id}>
                                                            <TableCell align='left'>{claim[nameType]} {nameType === 'revcodeDescription' && ` - ${claim.revcode}`} </TableCell>
                                                            <TableCell align='left'>{claim.units ?? 5} </TableCell>
                                                            <TableCell align='left'>{claim.payMethodName} </TableCell>
                                                            <TableCell align='left'>{claim.actualPay} </TableCell>
                                                            <TableCell align='left'>{getRowCurrentTotal(claim)} </TableCell>
                                                        </TableRow>
                                                    }
                                                    )}

                                                </React.Fragment>
                                            })}
                                            <TableRow>
                                                <TableCell className='border-0'><b></b></TableCell>
                                                <TableCell className='border-0'></TableCell>
                                                <TableCell className='border-0'></TableCell>
                                                <TableCell className='border-0'></TableCell>
                                                <TableCell className='border-0' align='left'><b> {getCurrentTotal()} </b> </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                            <Grid xs={6} item className={classes.childBox} style={{ overflowX: 'scroll' }}>
                                <Grid style={{ width: '1800px' }} container>
                                    <Grid item className='pl-3'>
                                        <Grid container className='p-1'>
                                            <Grid item xs={8}>
                                                <h6>Difference Rate - Year 1</h6>
                                            </Grid>
                                            <Grid item xs={4} className='text-right'>
                                                <Tooltip title="Modify Units and current calculations">
                                                    <IconButton onClick={() => handleDialogOpen(24)} color='default' aria-label="export" size='small'>
                                                        <i className='fas fa-calculator'></i>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.childBorder}>
                                            <Table aria-label="MultiProcedure Percentage Table">
                                                <TableHead style={{ backgroundColor: nameType === 'revcodeDescription' && '#b7d7b7' }}>
                                                    <TableRow>
                                                        <TableCell align='left'>Units </TableCell>
                                                        <TableCell align='left'>New Rate </TableCell>
                                                        <TableCell align='left'>New Calc. </TableCell>
                                                        <TableCell align='left'>Difference </TableCell>
                                                        <TableCell align='left'>Percentage </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {selectedContractForDifference24?.groups?.map((group, gIndex) => {
                                                        return <React.Fragment key={group.name}>
                                                            {nameType === 'ruleName' && <TableRow className={classes.groupRow}>
                                                                <TableCell className={classes.groupColor} colSpan={5} align='left'> <span style={{ visibility: 'hidden' }}> {group.name} </span> </TableCell>
                                                            </TableRow>
                                                            }
                                                            {group.claims.map((claim, cIndex) => {
                                                                return <TableRow key={claim.id}>
                                                                    <TableCell align='left'>{claim.units ?? 5} </TableCell>
                                                                    <TableCell className='py-0' align='left'>
                                                                        <input value={claim.newRate ?? ''} onChange={(e) => handleNewRateChange24(e, gIndex, cIndex)} className={`form-control ${classes.inputNumberControl}`} type='number' />
                                                                    </TableCell>
                                                                    <TableCell align='left'>{createFloatingPoint(parseFloat(claim.newCalc ?? 0))} </TableCell>
                                                                    <TableCell align='left'>{createFloatingPoint(parseFloat(claim.difference ?? 0))} </TableCell>
                                                                    <TableCell align='left'>{createFloatingPoint(parseFloat(claim.Percentage ?? 0))}% </TableCell>
                                                                </TableRow>
                                                            }
                                                            )}

                                                        </React.Fragment>
                                                    })}
                                                    <TableRow>
                                                        <TableCell className='border-0'><b></b></TableCell>
                                                        <TableCell className='border-0'><b></b></TableCell>
                                                        <TableCell className='border-0' align='left'><b> {getDifferenceTotal(selectedContractForDifference24)} </b> </TableCell>
                                                        <TableCell className='border-0'></TableCell>
                                                        <TableCell className='border-0'></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                    <Grid item className='pl-3'>
                                        <Grid container className='p-1'>
                                            <Grid item xs={8}>
                                                <h6>Difference Rate - Year 2</h6>
                                            </Grid>
                                            <Grid item xs={4} className='text-right'>
                                                <Tooltip title="Modify Units and current calculations">
                                                    <IconButton onClick={() => handleDialogOpen(25)} color='default' aria-label="export" size='small'>
                                                        <i className='fas fa-calculator'></i>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>                                        <Grid className={classes.childBorder}>
                                            <Table aria-label="MultiProcedure Percentage Table">
                                                <TableHead style={{ backgroundColor: nameType === 'revcodeDescription' && '#a0a0ff' }}>
                                                    <TableRow>
                                                        <TableCell align='left'>Units </TableCell>
                                                        <TableCell align='left'>New Rate </TableCell>
                                                        <TableCell align='left'>New Calc. </TableCell>
                                                        <TableCell align='left'>Difference </TableCell>
                                                        <TableCell align='left'>Percentage </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {selectedContractForDifference25?.groups?.map((group, gIndex) => {
                                                        return <React.Fragment key={group.name}>
                                                            {nameType === 'ruleName' && <TableRow className={classes.groupRow}>
                                                                <TableCell className={classes.groupColor} colSpan={5} align='left'> <span style={{ visibility: 'hidden' }}> {group.name} </span> </TableCell>
                                                            </TableRow>
                                                            }
                                                            {group.claims.map((claim, cIndex) => {
                                                                return <TableRow key={claim.id}>
                                                                    <TableCell align='left'>{claim.units ?? 5} </TableCell>
                                                                    <TableCell className='py-0' align='left'>
                                                                        <input onChange={(e) => handleNewRateChange25(e, gIndex, cIndex)} className={`form-control ${classes.inputNumberControl}`} type='number' />
                                                                    </TableCell>
                                                                    <TableCell align='left'>{createFloatingPoint(parseFloat(claim.newRate ?? 0))} </TableCell>
                                                                    <TableCell align='left'>{createFloatingPoint(parseFloat(claim.difference ?? 0))} </TableCell>
                                                                    <TableCell align='left'>{createFloatingPoint(parseFloat(claim.Percentage ?? 0))}% </TableCell>
                                                                </TableRow>
                                                            }
                                                            )}

                                                        </React.Fragment>
                                                    })}
                                                    <TableRow>
                                                        <TableCell className='border-0'><b></b></TableCell>
                                                        <TableCell className='border-0'><b></b></TableCell>
                                                        <TableCell className='border-0' align='left'><b> {getDifferenceTotal(selectedContractForDifference25)} </b> </TableCell>
                                                        <TableCell className='border-0'></TableCell>
                                                        <TableCell className='border-0'></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                    <Grid item className='pl-3'>
                                        <Grid container className='p-1'>
                                            <Grid item xs={8}>
                                                <h6>Difference Rate - Year 3</h6>
                                            </Grid>
                                            <Grid item xs={4} className='text-right'>
                                                <Tooltip title="Modify Units and current calculations">
                                                    <IconButton onClick={() => handleDialogOpen(26)} color='default' aria-label="export" size='small'>
                                                        <i className='fas fa-calculator'></i>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>                                        <Grid className={classes.childBorder}>
                                            <Table aria-label="MultiProcedure Percentage Table">
                                                <TableHead style={{ backgroundColor: nameType === 'revcodeDescription' && '#d2e1ef' }}>
                                                    <TableRow>
                                                        <TableCell align='left'>Units </TableCell>
                                                        <TableCell align='left'>New Rate </TableCell>
                                                        <TableCell align='left'>New Calc. </TableCell>
                                                        <TableCell align='left'>Difference </TableCell>
                                                        <TableCell align='left'>Percentage </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {selectedContractForDifference26?.groups?.map((group, gIndex) => {
                                                        return <React.Fragment key={group.name}>
                                                            {nameType === 'ruleName' && <TableRow className={classes.groupRow}>
                                                                <TableCell className={classes.groupColor} colSpan={5} align='left'> <span style={{ visibility: 'hidden' }}> {group.name} </span> </TableCell>
                                                            </TableRow>
                                                            }
                                                            {group.claims.map((claim, cIndex) => {
                                                                return <TableRow key={claim.id}>
                                                                    <TableCell align='left'>{claim.units ?? 5} </TableCell>
                                                                    <TableCell className='py-0' align='left'>
                                                                        <input onChange={(e) => handleNewRateChange26(e, gIndex, cIndex)} className={`form-control ${classes.inputNumberControl}`} type='number' />
                                                                    </TableCell>
                                                                    <TableCell align='left'>{createFloatingPoint(parseFloat(claim.newRate ?? 0))} </TableCell>
                                                                    <TableCell align='left'>{createFloatingPoint(parseFloat(claim.difference ?? 0))} </TableCell>
                                                                    <TableCell align='left'>{createFloatingPoint(parseFloat(claim.Percentage ?? 0))}% </TableCell>
                                                                </TableRow>
                                                            }
                                                            )}

                                                        </React.Fragment>
                                                    })}
                                                    <TableRow>
                                                        <TableCell className='border-0'><b></b></TableCell>
                                                        <TableCell className='border-0'><b></b></TableCell>
                                                        <TableCell className='border-0' align='left'><b> {getDifferenceTotal(selectedContractForDifference26)} </b> </TableCell>
                                                        <TableCell className='border-0'></TableCell>
                                                        <TableCell className='border-0'></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </PortletBody>
            </Portlet>
            <Dialog

                fullWidth
                maxWidth="sm"
                open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>
                    Apply Rate Change
                </DialogTitle>
                <DialogContent dividers>
                    <Grid>
                        <TextField
                            value={newRateValue}
                            onChange={e => setNewRateValue(e.target.value)}
                            label="Rate Change "
                            name="username"
                            className={classes.textField}
                            margin="normal"
                            type='number'
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid>
                        <TextField
                            value={newVolumeValue}
                            onChange={e => setNewVolumeValue(e.target.value)}
                            label="Volume Change"
                            name="username"
                            className={classes.textField}
                            margin="normal"
                            type='number'
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions className='my-2'>
                    <Button onClick={handleDialogClose} color="default">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleApplyCacl}
                        color="primary">
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ContractModelSimulator


export const response = [{
    id: 10,
    name: "Blue Cross PPO",
    terminationDate: "2023-12-31 00:00:00",
    contractState: {
        id: 3,
        name: "Finalized"
    },
    effectiveDate: "2021-01-01 00:00:00",
    groups: [
        {
            claims: [
                {
                    acknowledged: false,
                    actualPay: 530,
                    analysisNotes: "Service payment information not available for claim 483498",
                    chargedAmount: 1377.6,
                    claimanalysisid: 10940,
                    claimchargeid: "584a8186-9146-455a-a5a3-9ef6e833f637",
                    claimPaidamount: null,
                    comments: "",
                    contract: null,
                    contractid: null,
                    drg: "",
                    drgDescription: null,
                    expectedPay: 2060,
                    feeScheduleId: null,
                    id: 58846,
                    isEapgApplied: false,
                    isOppsApplied: false,
                    otherAllowable: null,
                    patientResponsibility: null,
                    paymentfileid: null,
                    payMethodName: "Per Diem",
                    principalDx: "J189",
                    principalDxDescription: "\&quot;Pneumonia, unspecified organism\&quot;",
                    pxcode: "",
                    pxcodeDescription: null,
                    remitDate: null,
                    revcode: "0120",
                    revcodeDescription: "Room &amp; Board ? Semi-private, 2 Beds",
                    rule: {
                        name: "Medical IP",
                        payMethod: '',
                        payValue: 1030,
                        ruleType: ''
                    },
                    ruleid: 144,
                    ruleName: "Medical IP",
                    rulePayValue: 1030,
                    ruleTypeName: "RULE",
                    servicepaymentid: null,
                    total: "59360",
                    units: 112,
                    variance: 1130
                }],
            name: 'INPATIENT'
        },

        {
            claims: [{
                acknowledged: false,
                actualPay: 7,
                analysisNotes: "N/A",
                chargedAmount: 15,
                claimanalysisid: 11531,
                claimchargeid: "fd3ab1a1-b704-4914-bc00-11649c7ac547",
                claimPaidamount: 7,
                comments: "",
                contract: null,
                contractid: null,
                drg: "",
                drgDescription: null,
                expectedPay: 12,
                feeScheduleId: null,
                id: 60872,
                isOppsApplied: false,
                otherAllowable: null,
                patientResponsibility: 0,
                paymentfileid: "56c5c2bd-78b7-46c7-b422-0e98f113de76",
                payMethodName: "Percentage",
                principalDx: "K529",
                principalDxDescription: "&amp;amp;quot;Noninfective gastroenteritis and colitis, unspecified&amp;amp;quot;",
                pxcode: "",
                pxcodeDescription: null,
                remitDate: "2023-12-29 00:00:00",
                revcode: "0250",
                revcodeDescription: "Pharmacy",
                rule: {
                    name: "All Other OP",
                    payMethod: "",
                    payValue: 80,
                    ruleType: ""
                },
                ruleid: 146,
                ruleName: "All Other OP",
                rulePayValue: 80,
                ruleTypeName: "DEFAULT",
                servicepaymentid: "0352617c-0048-4033-9418-fa84925d9565",
                total: "2800.00",
                units: 400,
                variance: 5
            },
            {
                acknowledged: false,
                actualPay: 44,
                analysisNotes: "Service payment information not available for claim 483498",
                chargedAmount: 10,
                claimanalysisid: 10940,
                claimchargeid: "e328d3b6-1452-4f5d-afd5-b8d91a647746",
                claimPaidamount: null,
                comments: "",
                contract: null,
                contractid: null,
                drg: "",
                drgDescription: null,
                expectedPay: 0,
                feeScheduleId: null,
                id: 58848,
                isEapgApplied: false,
                isOppsApplied: false,
                otherAllowable: null,
                patientResponsibility: null,
                paymentfileid: null,
                payMethodName: "Percentage",
                principalDx: "J189",
                principalDxDescription: "\&quot;Pneumonia, unspecified organism\&quot;",
                pxcode: "",
                pxcodeDescription: null,
                remitDate: null,
                revcode: "0251",
                revcodeDescription: "Generic drugs",
                rule: {
                    name: "All Other IP",
                    payMethod: '',
                    payValue: 80,
                    ruleType: ''
                },
                ruleid: 143,
                ruleName: "All Other IP",
                rulePayValue: 80,
                ruleTypeName: "DEFAULT",
                servicepaymentid: null,
                total: "8800",
                units: 200,
                variance: null
            },
            {
                acknowledged: false,
                actualPay: 51,
                analysisNotes: "N/A",
                chargedAmount: 30.45,
                claimanalysisid: 10309,
                claimchargeid: "c62e2c86-6dc4-4022-b4a4-082ed5ea0049",
                claimPaidamount: 0,
                comments: "[reese][2023/12/06 01:12 PM]resubmitted claim",
                contract: null,
                contractid: null,
                drg: "",
                drgDescription: null,
                expectedPay: 27.405,
                feeScheduleId: null,
                id: 56326,
                isEapgApplied: false,
                isOppsApplied: false,
                otherAllowable: null,
                patientResponsibility: 0,
                paymentfileid: "4c34530f-e9e6-4a2b-9070-ec974d5298a0",
                payMethodName: "Percentage",
                principalDx: "I7143",
                principalDxDescription: null,
                pxcode: "36415",
                pxcodeDescription: "ROUTINE VENIPUNCTURE",
                remitDate: "2023-09-14 00:00:00",
                revcode: "0300",
                revcodeDescription: "Laboratory",
                rule: {
                    name: "Default",
                    payMethod: '',
                    payValue: 90,
                    ruleType: ''
                },
                ruleid: 168,
                ruleName: "Default",
                rulePayValue: 90,
                ruleTypeName: "DEFAULT",
                servicepaymentid: "bb8c8fba-8e9f-41b6-bfd5-886485f05883",
                total: "20910",
                units: 410,
                variance: 27.4
            }, {
                acknowledged: false,
                actualPay: 38,
                analysisNotes: "N/A",
                chargedAmount: 194.25,
                claimanalysisid: 10309,
                claimchargeid: "2d829c87-696d-422f-93a3-cc722c971127",
                claimPaidamount: 0,
                comments: "[reese][2023/12/06 01:12 PM]resubmitted claim",
                contract: null,
                contractid: null,
                drg: "",
                drgDescription: null,
                expectedPay: 174.825,
                feeScheduleId: null,
                id: 56327,
                isEapgApplied: false,
                isOppsApplied: false,
                otherAllowable: null,
                patientResponsibility: 0,
                paymentfileid: "4c34530f-e9e6-4a2b-9070-ec974d5298a0",
                payMethodName: "Percentage",
                principalDx: "I7143",
                principalDxDescription: null,
                pxcode: "80048",
                pxcodeDescription: "METABOLIC PANEL TOTAL CA",
                remitDate: "2023-09-14 00:00:00",
                revcode: "0301",
                revcodeDescription: "Chemistry",
                rule: {
                    name: "Default",
                    payMethod: '',
                    payValue: 90,
                    ruleType: ''
                },
                ruleid: 168,
                ruleName: "Default",
                rulePayValue: 90,
                ruleTypeName: "DEFAULT",
                servicepaymentid: "d829817b-cbfa-4cef-8d89-314e70c03a31",
                total: "17100",
                units: 450,
                variance: 174.82
            }, {
                acknowledged: false,
                actualPay: 177.28,
                analysisNotes: "N/A",
                chargedAmount: 3285.45,
                claimanalysisid: 10309,
                claimchargeid: "02cd841f-4abf-451d-a205-a3dfc81bc8d9",
                claimPaidamount: 0,
                comments: "[reese][2023/12/06 01:12 PM]resubmitted claim",
                contract: null,
                contractid: null,
                drg: "",
                drgDescription: null,
                expectedPay: 2956.905,
                feeScheduleId: null,
                id: 56328,
                isEapgApplied: false,
                isOppsApplied: false,
                otherAllowable: null,
                patientResponsibility: 177.28,
                paymentfileid: "4c34530f-e9e6-4a2b-9070-ec974d5298a0",
                payMethodName: "Percentage",
                principalDx: "I7143",
                principalDxDescription: null,
                pxcode: "74175",
                pxcodeDescription: "CT ANGIO ABDOM W/O &amp; W/DYE",
                remitDate: "2023-09-14 00:00:00",
                revcode: "0350",
                revcodeDescription: "CT Scan",
                rule: {
                    name: "Default",
                    payMethod: '',
                    payValue: 90,
                    ruleType: ''
                },
                ruleid: 168,
                ruleName: "Default",
                rulePayValue: 90,
                ruleTypeName: "DEFAULT",
                servicepaymentid: "b4e87f3d-2afa-4800-bae6-38c8006373d9",
                total: "70912",
                units: 400,
                variance: 2779.62
            }, {
                acknowledged: false,
                actualPay: 539.3,
                analysisNotes: "N/A",
                chargedAmount: 1156.05,
                claimanalysisid: 11531,
                claimchargeid: "bea533f7-39d9-4b0c-8605-fb74f3875cf0",
                claimPaidamount: 539.3,
                comments: "",
                contract: null,
                contractid: null,
                drg: "",
                drgDescription: null,
                expectedPay: 369.864,
                feeScheduleId: 12,
                id: 60873,
                isOppsApplied: false,
                otherAllowable: null,
                patientResponsibility: 0,
                paymentfileid: "56c5c2bd-78b7-46c7-b422-0e98f113de76",
                payMethodName: "Percentage",
                principalDx: "K529",
                principalDxDescription: "&amp;amp;quot;Noninfective gastroenteritis and colitis, unspecified&amp;amp;quot;",
                pxcode: "76700",
                pxcodeDescription: "US EXAM ABDOM COMPLETE",
                remitDate: "2023-12-29 00:00:00",
                revcode: "0402",
                revcodeDescription: "Ultrasound",
                rule: {
                    name: "Low Tech Rad",
                    payMethod: "",
                    payValue: 330,
                    ruleType: ""
                },
                ruleid: 149,
                ruleName: "Low Tech Rad",
                rulePayValue: 330,
                ruleTypeName: "RULE",
                servicepaymentid: "ed9033fa-ee5e-45aa-b260-fff1564ffd6b",
                total: "272885.80",
                units: 506,
                variance: -169.44
            }, {
                acknowledged: false,
                actualPay: 55.98,
                analysisNotes: "N/A",
                chargedAmount: 120,
                claimanalysisid: 11531,
                claimchargeid: "1f9e9870-bf5f-4bb5-91fb-baf3f91dc252",
                claimPaidamount: 55.98,
                comments: "",
                contract: null,
                contractid: null,
                drg: "",
                drgDescription: null,
                expectedPay: 116.5416,
                feeScheduleId: 12,
                id: 60874,
                isOppsApplied: false,
                otherAllowable: null,
                patientResponsibility: 0,
                paymentfileid: "56c5c2bd-78b7-46c7-b422-0e98f113de76",
                payMethodName: "Percentage",
                principalDx: "K529",
                principalDxDescription: "&amp;amp;quot;Noninfective gastroenteritis and colitis, unspecified&amp;amp;quot;",
                pxcode: "96374",
                pxcodeDescription: "THER/PROPH/DIAG INJ IV PUSH",
                remitDate: "2023-12-29 00:00:00",
                revcode: "0450",
                revcodeDescription: "Emergency Room (ER)",
                rule: {
                    name: "Other Non Surgical",
                    payMethod: "",
                    payValue: 294,
                    ruleType: ""
                },
                ruleid: 152,
                ruleName: "Other Non Surgical",
                rulePayValue: 294,
                ruleTypeName: "RULE",
                servicepaymentid: "8c347de9-b926-4743-ad91-72dfb7e4e2f7",
                total: "28325.88",
                units: 506,
                variance: 60.56
            }],
            name: "OUTPATIENT"
        },

        // {
        //     claims: [
        //         {
        //             "id": 61146,
        //             "claimanalysisid": 10923,
        //             "claimchargeid": "1548735b-f9ae-4bc3-8076-449a7be35d9e",
        //             "ruleTypeName": "RULE",
        //             "payMethodName": "Flat Rate",
        //             "rulePayValue": 77.9200,
        //             "paymentfileid": "fb7ab532-d0a8-4066-911c-2896de9d68df",
        //             "servicepaymentid": "98014eba-984e-4baa-a5ed-1cd3f2ac29f4",
        //             "contractid": null,
        //             "ruleid": 158,
        //             "revcode": "",
        //             "revcodeDescription": null,
        //             "pxcode": "99213:SA:25",
        //             "pxcodeDescription": "OFFICE O/P EST LOW 20-29 MIN",
        //             "drg": "",
        //             "drgDescription": null,
        //             "principalDx": "J0100",
        //             "principalDxDescription": "\"Acute maxillary sinusitis, unspecified\"",
        //             "chargedAmount": 160.0000,
        //             "expectedPay": 77.9200,
        //             "actualPay": 66.2300,
        //             "variance": 11.6900,
        //             "acknowledged": false,
        //             "analysisNotes": "N/A",
        //             "comments": "",
        //             "remitDate": "2023-11-07 00:00:00",
        //             "claimPaidamount": 66.2300,
        //             "patientResponsibility": 0.0000,
        //             "otherAllowable": null,
        //             "contract": null,
        //             "ruleName": "Office - 99213",
        //             "units": 100,
        //             'total': '6623',
        //             "feeScheduleId": null,
        //             "isOppsApplied": false,
        //             "isEapgApplied": false,
        //             "rule": {
        //                 "name": "Office - 99213",
        //                 "payValue": 77.9200,
        //                 "payMethod": {
        //                     "name": "Flat Rate"
        //                 },
        //                 "ruleType": {
        //                     "name": "RULE"
        //                 }
        //             }
        //         },
        //         {
        //             "id": 61147,
        //             "claimanalysisid": 10923,
        //             "claimchargeid": "7e1824c0-25bc-4793-8276-599aff4c77b6",
        //             "ruleTypeName": "DEFAULT",
        //             "payMethodName": "Percentage",
        //             "rulePayValue": 50.0000,
        //             "paymentfileid": "fb7ab532-d0a8-4066-911c-2896de9d68df",
        //             "servicepaymentid": "3b21d5e1-2914-44a5-9a2d-b1d50eaa384e",
        //             "contractid": null,
        //             "ruleid": 157,
        //             "revcode": "",
        //             "revcodeDescription": null,
        //             "pxcode": "J2010",
        //             "pxcodeDescription": "Injection, lincomycin hcl, up to 300 mg",
        //             "drg": "",
        //             "drgDescription": null,
        //             "principalDx": "J0100",
        //             "principalDxDescription": "\"Acute maxillary sinusitis, unspecified\"",
        //             "chargedAmount": 42.0000,
        //             "expectedPay": 21.0000,
        //             "actualPay": 13.6800,
        //             "variance": 7.3200,
        //             "acknowledged": false,
        //             "analysisNotes": "N/A",
        //             "comments": "",
        //             "remitDate": "2023-11-07 00:00:00",
        //             "claimPaidamount": 13.6800,
        //             "patientResponsibility": 0.0000,
        //             "otherAllowable": null,
        //             "contract": null,
        //             "ruleName": "Default Professional ",
        //             "units": 200,
        //             'total': '2737.6',
        //             "feeScheduleId": null,
        //             "isOppsApplied": false,
        //             "isEapgApplied": false,
        //             "rule": {
        //                 "name": "Default Professional ",
        //                 "payValue": 50.0000,
        //                 "payMethod": {
        //                     "name": "Percentage"
        //                 },
        //                 "ruleType": {
        //                     "name": "DEFAULT"
        //                 }
        //             }
        //         }
        //     ],
        //     name: "PROFESSIONAL"
        // }
    ],
}, {
    contractState: {
        id: 3,
        name: "Finalized"
    },
    effectiveDate: "2022-05-01 00:00:00",
    groups: [{
        claims: [{
            acknowledged: false,
            actualPay: 35,
            analysisNotes: "N/A",
            chargedAmount: 118.65,
            claimanalysisid: 10315,
            claimchargeid: "aa975377-784d-4316-885e-cb85d8cf2984",
            claimPaidamount: 0,
            comments: "",
            contract: null,
            contractid: null,
            drg: "",
            drgDescription: null,
            expectedPay: 35,
            feeScheduleId: null,
            id: 58631,
            isEapgApplied: false,
            isOppsApplied: false,
            otherAllowable: null,
            patientResponsibility: 0,
            paymentfileid: "cb65e786-41c9-468f-a509-e4f0460fa940",
            payMethodName: "Per Unit",
            principalDx: "Z1231",
            principalDxDescription: "Encounter for screening mammogram for malignant neoplasm of breast",
            pxcode: "83036",
            pxcodeDescription: "GLYCOSYLATED HEMOGLOBIN TEST",
            remitDate: "2023-09-15 00:00:00",
            revcode: "0300",
            revcodeDescription: "Laboratory",
            rule: {
                name: "Lab",
                payMethod: '',
                payValue: 35,
                ruleType: ''
            },
            ruleid: 135,
            ruleName: "Lab",
            rulePayValue: 35,
            ruleTypeName: "RULE",
            servicepaymentid: "52853a1b-df0e-4c14-84f2-0aa5b39e0791",
            total: "7000",
            units: 200,
            variance: 35
        }, {
            acknowledged: false,
            actualPay: 20.57,
            analysisNotes: "N/A",
            chargedAmount: 96.31,
            claimanalysisid: 10279,
            claimchargeid: "ccf66ffb-3e2a-4f89-9177-1be9475c585f",
            claimPaidamount: 10.57,
            comments: "",
            contract: null,
            contractid: null,
            drg: "",
            drgDescription: null,
            expectedPay: 72.0399,
            feeScheduleId: null,
            id: 56184,
            isEapgApplied: false,
            isOppsApplied: false,
            otherAllowable: null,
            patientResponsibility: 10,
            paymentfileid: "a2b39f09-3665-4722-8b0a-82400d7e08ac",
            payMethodName: "Percentage",
            principalDx: "M5450",
            principalDxDescription: null,
            pxcode: "97140:GP",
            pxcodeDescription: "MANUAL THERAPY 1/&gt; REGIONS",
            remitDate: "2023-08-30 00:00:00",
            revcode: "0420",
            revcodeDescription: "Physical Therapy",
            rule: {
                name: "All Other Outpatient",
                payMethod: '',
                payValue: 74.8,
                ruleType: ''
            },
            ruleid: 140,
            ruleName: "All Other Outpatient",
            rulePayValue: 74.8,
            ruleTypeName: "DEFAULT",
            servicepaymentid: "9c2265dd-8bdb-46f3-a24a-326f8ab35602",
            total: "7199.5",
            units: 350,
            variance: 51.47
        }],
        name: "OUTPATIENT"
    }],
    id: 11,
    name: "United Healthcare",
    terminationDate: "2023-05-31 00:00:00"
},

{
    contractState: {
        id: 3,
        name: "Finalized"
    },
    effectiveDate: "2021-07-01 00:00:00",
    groups: [{
        claims: [{
            acknowledged: false,
            actualPay: 96,
            analysisNotes: "N/A",
            chargedAmount: 30.45,
            claimanalysisid: 9337,
            claimchargeid: "39393f46-bc75-463c-b41e-6db9b2b4790a",
            claimPaidamount: 0,
            comments: null,
            contract: null,
            contractid: null,
            drg: "",
            drgDescription: null,
            expectedPay: 27.405,
            feeScheduleId: null,
            id: 48674,
            isOppsApplied: false,
            otherAllowable: null,
            patientResponsibility: 0,
            paymentfileid: "3c7df0d6-8ac6-44b6-91b2-375e359e1274",
            payMethodName: "Percentage",
            principalDx: "Z01812",
            principalDxDescription: "Encounter for preprocedural laboratory examination",
            pxcode: "36415",
            pxcodeDescription: "ROUTINE VENIPUNCTURE",
            remitDate: "2022-02-08 00:00:00",
            revcode: "0300",
            revcodeDescription: "Laboratory",
            rule: {
                name: "Default",
                payMethod: "",
                payValue: 90,
                ruleType: ""
            },
            ruleid: 168,
            ruleName: "Default",
            rulePayValue: 90,
            ruleTypeName: "DEFAULT",
            servicepaymentid: "9823362f-7756-4152-9c09-8b520a9cb2ea",
            total: "48576",
            units: 506,
            variance: 27.4
        }],
        name: "OUTPATIENT"
    }, {
        claims: [{
            acknowledged: false,
            actualPay: 87.83,
            analysisNotes: "N/A",
            chargedAmount: 160,
            claimanalysisid: 10283,
            claimchargeid: "1cde3642-0f46-4f7f-9b3e-da84d805f940",
            claimPaidamount: 87.83,
            comments: "",
            contract: null,
            contractid: null,
            drg: "",
            drgDescription: null,
            expectedPay: 144,
            feeScheduleId: null,
            id: 56210,
            isOppsApplied: false,
            otherAllowable: null,
            patientResponsibility: 0,
            paymentfileid: "1994fae3-90f9-43b7-a1e0-0b3f9f9bff7a",
            payMethodName: "Percentage",
            principalDx: "K5909",
            principalDxDescription: "Other constipation",
            pxcode: "99213",
            pxcodeDescription: "OFFICE O/P EST LOW 20-29 MIN",
            remitDate: "2023-08-16 00:00:00",
            revcode: "",
            revcodeDescription: null,
            rule: {
                name: "Default",
                payMethod: "",
                payValue: 90,
                ruleType: ""
            },
            ruleid: 169,
            ruleName: "Default",
            rulePayValue: 90,
            ruleTypeName: "DEFAULT",
            servicepaymentid: "df45f269-6a4f-494f-b0ab-e9a05f9415cd",
            total: "39523.5",
            units: 450,
            variance: 56.17
        }],
        name: "PROFESSIONAL"
    }],
    id: 9,
    name: "Humana PPO ERS OF TX",
    terminationDate: "2023-06-30 00:00:00"
}]