import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import ImportDialogComponent from './ImportDialog';
import { setV2 } from '../../../../store/actions/pagesV2';
import { importHospitalServices, setHopsitalServicesLookupImportConfirmationDialog, setHopsitalServicesLookupDialog } from '../../../../store/actions/hospitalServices';

export const ImportDialog = connect(
    () => {
        const selectDRGIds = (state, props) => state.pagesV2['hospitalservices-master-landing'].selected;
        const selectImportDialog = (state) => state.hospitalServices.lookupImportConfirmationDialog;

        const selectOpen = createSelector(
            selectImportDialog,
            (importHsDialog) => {
                if (importHsDialog && importHsDialog?.open) {
                    return true
                }
                return false
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            selectedItems: selectDRGIds(state, props),
            importHospitalServicesPending: state.hospitalServices.getHospitalServicesImport.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setSelected: (value) => setV2('hospitalservices-master-landing', 'selected', value),
            importHospitalServices: importHospitalServices,
            setHopsitalServicesLookupImportConfirmationDialog: setHopsitalServicesLookupImportConfirmationDialog,
            setHopsitalServicesLookupDialog: setHopsitalServicesLookupDialog
        }, dispatch)
    })
)(injectIntl(ImportDialogComponent));