import { Box, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EditIcon from '@material-ui/icons/Edit';
import { PurePtr } from '@pure-ptr/react';
import React from 'react';
import { NumericInput } from '../../common/FormControls';
import { asDollars, asPercentage } from '../../util/format';
import { AnnualForecast } from './state';

export const CurrentRate = ({ selectedContractForCurrent, selectedGroupIdPtr } : {
    selectedContractForCurrent: AnnualForecast,
    selectedGroupIdPtr: PurePtr<string>
}) => {
    const classes = useTableStyles({ color: 'blue' });

    return (
        <TableContainer component={Paper}>
            <Table size="small" classes={{ root: classes.table }}>
                <TableHead>
                    <TableRow classes={{ root : classes.headerRow }}>
                        <TableCell align='left'>Rev. Code </TableCell>
                        <TableCell width={80} align='left'>Unit </TableCell>
                        <TableCell width={110} align='left'>Pay Method </TableCell>
                        <TableCell width={100} align='left'>Rate </TableCell>
                        <TableCell width={120} align='left'>Total </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {selectedContractForCurrent?.groups?.map(group => {
                        return (
                            <React.Fragment key={group.id}>
                                <TableRow 
                                    classes={{ root : classes.groupRow }}
                                    onClick={() => 
                                        selectedGroupIdPtr.set(
                                            selectedGroupIdPtr.value === group.id ? 
                                                null : 
                                                group.id 
                                        )
                                    }
                                >
                                    <TableCell colSpan={4} align='left'>
                                        { selectedGroupIdPtr.value === group.id ? 
                                            <ArrowDropDownIcon /> 
                                        : 
                                            <ArrowRightIcon /> 
                                        }

                                        {group.revCodeGroup.revCodes + ': '} 
                                        {group.revCodeGroup.description} 
                                    </TableCell>
                                    <TableCell align='right'>{ asDollars( group.summary[0].total )}</TableCell>
                                </TableRow>

                                {selectedGroupIdPtr.value === group.id && group.claims.map(claim => {
                                    return <TableRow key={claim.claim.id} classes={{ root : classes.editRow }}>
                                        <TableCell align='left'>{claim.claim.revcode}: {claim.claim.revcodeDescription}</TableCell>
                                        <TableCell align='right'>{claim.claim.units} </TableCell>
                                        <TableCell align='left'>{claim.claim.payMethodName} </TableCell>
                                        <TableCell align='right'>{asDollars(claim.forecast[0].rate)} </TableCell>
                                        <TableCell align='right'>{asDollars(claim.summary[0].total)} </TableCell>
                                    </TableRow>
                                })}
                            </React.Fragment>
                        )
                    })}

                    <TableRow classes={{ root : classes.totalsRow}}>
                        <TableCell colSpan={4}/>
                        <TableCell align='right'>
                            { asDollars( selectedContractForCurrent.summary[0].total )}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const colors = {
    blue : {
        primary: "#CCE7F6",
        secondary: "#E1F4FA"
    },

    green : {
        primary: "#CDE8D7",
        secondary: "#D9F6E3"
    },

    sepia :{
        primary: "#C8B090",
        secondary: "#Fffaf5"
    }
}

const useTableStyles = makeStyles( _ => {
    return({
    table: {
        "& .MuiTableRow-root" : {
            height: 33,

            '& .MuiTableCell-root' : {
                textWrapMode: 'nowrap'
            }
        }
    },
    groupRow: {
        "& .MuiTableCell-root" : {
            fontWeight: 600,
            cursor : 'pointer'
        }
    },

    editRow : {
        "& .MuiTableCell-root" : {
            backgroundColor: colors.sepia.secondary,
        }
    },

    headerRow: {
        "& .MuiTableCell-root" : {
            fontWeight: 600,
            backgroundColor: ( props : { color : string } ) => colors[props.color].primary
        }
    },

    totalsRow: {
        "& .MuiTableCell-root" : {
            fontWeight: 600
        }
    },

    unchangedRow: {
        "& .MuiTableCell-root" : {
            color: 'grey',
            backgroundColor: colors.sepia.secondary,
        }
    }
})});

export const Comparison = ({ periodPtr, index, selectedGroupIdPtr } :{
    periodPtr : PurePtr<AnnualForecast>,
    index : number
    selectedGroupIdPtr : PurePtr<string>
}) => {
    const classes = useTableStyles({ color: 'green' });
    
    const summary = periodPtr.value.summary[index];

    return (
        <TableContainer component={Paper}>
            <Table size="small" classes={{ root : classes.table }}>
                <TableHead>
                    <TableRow classes={{
                        root : classes.headerRow 
                    }}>
                        <TableCell style={{ minWidth: 100 }} align='left'>Units </TableCell>
                        <TableCell style={{ minWidth: 120 }} align='left'>
                            <Box justifyContent="space-between" display="flex" alignItems="center">
                                Rate 
                                <IconButton size='small'>
                                    <EditIcon fontSize='small'/>
                                </IconButton>
                            </Box>
                        </TableCell>
                        <TableCell align='left'>Total</TableCell>
                        <TableCell align='left'>Variance</TableCell>
                        <TableCell align='left'>%</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {periodPtr.at('groups').map((groupPtr, gIndex) => {
                        const group = groupPtr.value,
                            summary = group.summary[index];

                        return (
                            <React.Fragment key={group.id}>
                                <TableRow classes={{ root : classes.groupRow }}
                                    onClick={() => 
                                        selectedGroupIdPtr.set(
                                            selectedGroupIdPtr.value === group.id ? 
                                                null : 
                                                group.id 
                                        )
                                    }
                                >
                                    <TableCell colSpan={2} align='left'>
                                        { selectedGroupIdPtr.value === group.id ? 
                                            <ArrowDropDownIcon /> 
                                        : 
                                            <ArrowRightIcon /> 
                                        }
                                        {group.revCodeGroup.revCodes}
                                    </TableCell>
                                    <TableCell align='right'>{asDollars(summary.total)} </TableCell>
                                    <TableCell align='right'>{asDollars(summary.variance)} </TableCell>
                                    <TableCell align='left'>{asPercentage(summary.increase)} </TableCell>
                                </TableRow>

                                {selectedGroupIdPtr.value === group.id && groupPtr.at('claims').map((claimPtr, cIndex) => {
                                    const [ ratePtr, unitsPtr ] = claimPtr
                                                                    .at('forecast').at(index)
                                                                    .pick('rate', 'units'),
                                            { value } = claimPtr,
                                         summary  = value.summary[index];

                                    return (
                                        <TableRow 
                                            key={ value.claim.id } 
                                            classes={{ root : unitsPtr.isTruthy || ratePtr.isTruthy ? classes.editRow : classes.unchangedRow }}>
                                            <TableCell align='right'>
                                                <NumericInput nullable positive valuePtr={ unitsPtr }
                                                    placeholder={ claimPtr.value.filledForecast[ index - 1 ].units }
                                                />                               
                                            </TableCell>

                                            <TableCell className='py-0' align='right'>
                                                <NumericInput nullable positive valuePtr={ ratePtr }
                                                    placeholder={asDollars(claimPtr.value.filledForecast[ index - 1 ].rate)}
                                                />
                                            </TableCell>

                                            <TableCell align='right'>{asDollars(summary.total)} </TableCell>
                                            <TableCell align='right'>{asDollars(summary.variance)} </TableCell>
                                            <TableCell align='left'>{asPercentage(summary.increase)} </TableCell>
                                        </TableRow>
                                    )
                                }
                                    
                                )}
                            </React.Fragment>
                        )
                    }

                    )}
                    <TableRow classes={{ root : classes.totalsRow }}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align='right'>{ asDollars( summary.total )} </TableCell>
                        <TableCell align='right'>{ asDollars( summary.variance )}</TableCell>
                        <TableCell>{ asPercentage( summary.increase ) }</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
