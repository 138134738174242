export const HOSPITAL_SERVICES_DELETE_BULK = 'HOSPITAL_SERVICES_DELETE_BULK';
export const HOSPITAL_SERVICES_DELETE_BULK_PENDING = 'HOSPITAL_SERVICES_DELETE_BULK_PENDING';
export const HOSPITAL_SERVICES_DELETE_BULK_FULFILLED = 'HOSPITAL_SERVICES_DELETE_BULK_FULFILLED';
export const HOSPITAL_SERVICES_DELETE_BULK_REJECTED = 'HOSPITAL_SERVICES_DELETE_BULK_REJECTED';

export const HOSPITAL_SERVICES_IMPORT = 'HOSPITAL_SERVICES_IMPORT';
export const HOSPITAL_SERVICES_IMPORT_PENDING = 'HOSPITAL_SERVICES_IMPORT_PENDING';
export const HOSPITAL_SERVICES_IMPORT_FULFILLED = 'HOSPITAL_SERVICES_IMPORT_FULFILLED';
export const HOSPITAL_SERVICES_IMPORT_REJECTED = 'HOSPITAL_SERVICES_IMPORT_REJECTED';
export const HOSPITAL_SERVICES_IMPORT_RESET = 'HOSPITAL_SERVICES_IMPORT_RESET';

export const HOSPITAL_SERVICES_IMPORT_HISTORICAL = 'HOSPITAL_SERVICES_IMPORT_HISTORICAL';
export const HOSPITAL_SERVICES_IMPORT_HISTORICAL_PENDING = 'HOSPITAL_SERVICES_IMPORT_HISTORICAL_PENDING';
export const HOSPITAL_SERVICES_IMPORT_HISTORICAL_FULFILLED = 'HOSPITAL_SERVICES_IMPORT_HISTORICAL_FULFILLED';
export const HOSPITAL_SERVICES_IMPORT_HISTORICAL_REJECTED = 'HOSPITAL_SERVICES_IMPORT_HISTORICAL_REJECTED';

export const HOSPITAL_SERVICES_LOOKUP_MASTER = 'HOSPITAL_SERVICES_LOOKUP_MASTER';
export const HOSPITAL_SERVICES_LOOKUP_MASTER_PENDING = 'HOSPITAL_SERVICES_LOOKUP_MASTER_PENDING';
export const HOSPITAL_SERVICES_LOOKUP_MASTER_FULFILLED = 'HOSPITAL_SERVICES_LOOKUP_MASTER_FULFILLED';
export const HOSPITAL_SERVICES_LOOKUP_MASTER_REJECTED = 'HOSPITAL_SERVICES_LOOKUP_MASTER_REJECTED';
export const HOSPITAL_SERVICES_LOOKUP_RESET = 'HOSPITAL_SERVICES_LOOKUP_RESET';

export const HOSPITAL_SERVICES_SET_DEPENDENCY_DIALOG = 'HOSPITAL_SERVICES_SET_DEPENDENCY_DIALOG';
export const HOSPITAL_SERVICES_SET_IMPORT_CONFIRMATION_DIALOG = 'HOSPITAL_SERVICES_SET_IMPORT_CONFIRMATION_DIALOG';
export const HOSPITAL_SERVICES_SET_IMPORT_DIALOG = 'HOSPITAL_SERVICES_SET_IMPORT_DIALOG';
export const HOSPITAL_SERVICES_SET_LOOKUP_DIALOG = 'HOSPITAL_SERVICES_SET_LOOKUP_DIALOG';
export const HOSPITAL_SERVICES_SET_LOOKUP_CONFIRMATION_DIALOG = 'HOSPITAL_SERVICES_SET_LOOKUP_CONFIRMATION_DIALOG';

export const deleteBulk = (hospitalServices, callback, shouldReloadGrid) => ({
    type: HOSPITAL_SERVICES_DELETE_BULK,
    payload: { hospitalServices, callback, shouldReloadGrid },
});

export const importHospitalServices = (options, callback) => ({
    type: HOSPITAL_SERVICES_IMPORT,
    payload: { options, callback }
});

export const importHospitalServicesFromHistorical = () => ({
    type: HOSPITAL_SERVICES_IMPORT_HISTORICAL,
    //payload: { options }
});

export const hospitalServicesImportErrorReset = () => ({
    type: HOSPITAL_SERVICES_IMPORT_RESET
});

export const hospitalServicesLookupFromMaster = (options) => {
    return {
        type:HOSPITAL_SERVICES_LOOKUP_MASTER ,
        payload: { options }
    }
}

export const hospitalServicesLookupErrorReset = () => ({
    type: HOSPITAL_SERVICES_LOOKUP_RESET
});

export const setHopsitalServicesDependencyDialog = (dependencyDialog) => ({
    type: HOSPITAL_SERVICES_SET_DEPENDENCY_DIALOG,
    payload: { dependencyDialog }
});

export const setHopsitalServicesImportConfirmationDialog = (importConfirmationDialog) => ({
    type: HOSPITAL_SERVICES_SET_IMPORT_CONFIRMATION_DIALOG,
    payload: { importConfirmationDialog }
});

export const setHopsitalServicesImportDialog = (importDialog) => ({
    type: HOSPITAL_SERVICES_SET_IMPORT_DIALOG,
    payload: { importDialog }
});

export const setHopsitalServicesLookupDialog = (lookupDialog) => ({
    type: HOSPITAL_SERVICES_SET_LOOKUP_DIALOG,
    payload: { lookupDialog }
});

export const setHopsitalServicesLookupImportConfirmationDialog = (lookupImportConfirmationDialog) => ({
    type: HOSPITAL_SERVICES_SET_LOOKUP_CONFIRMATION_DIALOG,
    payload: { lookupImportConfirmationDialog }
});

