import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { get, getAlertUsers, updateServiceTab } from '../../../store/actions/contracts';
import { createContractSelector, selectAlertUsers } from "../../../store/selectors/contracts";
import { selectSessionUserPreferredClientId } from "../../../store/selectors/session";
import ContractViewComponent from './ContractView';
import { createStringDataField } from '../../../util/format';
import { list } from "../../../store/actions/services";
import { list as getFacilities } from "../../../store/actions/facilities";
import { selectFacilityList } from '../../../store/selectors/facilities';
import { getHierarchy } from '../../../store/actions/contractsv2';

export const ContractViewPage = connect(
    () => {
        const selectContractId = (state, props) => props.match.params.contractId;
        const selectContract = createContractSelector(selectContractId);
        const selectDirectoryId = (state, props) => props.match.params.directoryId;

        const selectAlertContacts = createSelector(
            selectAlertUsers,
            selectContract,
            (allUsers, contract) => {
                if (allUsers && allUsers.length > 0 && contract && contract.alertContacts) {
                    return allUsers.filter(user => contract.alertContacts.some(y => y.userId == user.userId));
                }
                return [];
            }
        );

        const selectFacilities = createSelector(
            selectFacilityList,
            selectContract,
            (allFacilities, contract) => {
                if (allFacilities && allFacilities.length > 0 && contract && contract.contractFacilityXref) {
                    return allFacilities.filter(facility => contract.contractFacilityXref.some(y => y.facilityId == facility.id));
                }
                return [];
            }
        );

        const selectFields:any = createSelector(
            () => [
                createStringDataField('name', 'Name'),
                createStringDataField('title', 'Title'),
                createStringDataField('emails', 'Emails'),
                createStringDataField('phone', 'Phone'),
            ]
        );

        return (state:any, props) => ({
            contractsGetPending: state.contracts.get.pending,
            contractsGetAlertUsersPending: state.contracts.getAlertUsers.pending,
            contract: selectContract(state, props),
            contractId: selectContractId(state, props),
            vendorContactFields: selectFields(),
            clientId: selectSessionUserPreferredClientId(state),
            alertUsers: selectAlertContacts(state, props),
            servicesTab: state.contracts.servicesTab,
            facilityXRef: selectFacilities(state, props),
            directoryId:selectDirectoryId(state,props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            getContract: get,
            contractGetAlertUsers: getAlertUsers,
            getConfigs: list,
            updateServiceTab: updateServiceTab,
            getFacilities: getFacilities,
            getHierarchy:getHierarchy
        }, dispatch)
    })
)(injectIntl(ContractViewComponent as any));
