import { Box, Button, DialogContent, DialogTitle, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { CustomLabel, DataTable, TraceDialog } from '../../../common';


import { BackButton } from '../../../common/BackButton';

import { Card } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../../_metronic';
import { Loadable } from '../../../common';


import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';
import { asDate } from '../../../util/date';
import { AdvancedTrace } from '../../ClaimAnalysisPage/ClaimViewPage/AdvancedTrace';
import { PocketCostDialog } from '../../claimEstimatorPage/estimationsResultPage/PocketCostDialog';
import { ActiveFacilitiesDialog } from './ActiveFacilitiesDialog';

const useStyles = makeStyles(theme => ({
    background: {
        background: 'white',
    },
    addressCardStyles:{
        border:'none',display:'none'
    },
    disclaimerTextStyle:{
        display:'inline-block'

    },
    disclaimerCard:{
        border:'none',display:'none'
    },
    signaturesCard:{
        border:'none',justifyContent:'center',display:'none'

    },
    signaturesCardContainer:{
        display:'flex',marginBottom:'20px',columnGap:'10px'
    },
    signaturesCardBox:{
        width:'350px',display:'flex',columnGap:'5px'
    },
    signaturesCardText:{
        minWidth:'max-content',position:'relative',top:'5px'
    },
    signaturesField:{
        width:'100%',borderBottom:'1px solid'
    },
    signaturesDateFieldContainer:{
        width:'140px',display:'flex',columnGap:'5px'
    },
    signaturesDateFieldText:{
        position:'relative',top:'5px'
    },
    signaturesDate:{
        width:'100%',borderBottom:'1px solid'
    }
}));

function EstimateHistoryDetailsPage({  fields, order,sort, sections,data,estimatorId,getEstimatePending,getFacilitiesPending,facilitiesData,getBrandingDetailsPending, brandingDetails,outPocket,actions: { setSort, setOrder,getEstimateById,getFacilities ,getBrandingDetails} }) {
    const [isAnalysisTraceOpen, setAnalysisTraceOpen] = useState(false);
    const [isAdvancedTraceOpen, setAdvancedTraceOpen] = useState(false);
    const [haveActiveFacilities, setHaveActiveFacilities] = useState(false);
    const [isPocketCostDialogOpen, setPocketCostDialog] = useState(false);


    const componentRef = useRef();
    const classes = useStyles();    


    useEffect(() => {
        getEstimateById(estimatorId)
    }, [getEstimateById]);

    useEffect(() => {
        getFacilities()
    }, [getFacilities]);
    

    const hoverData = (rowData) => {
        const hoverColumns = new Map();

        const pxCodeColumns = [
            {
                label: "",
                data: rowData?.pxCodeDescription ?? ''
            }
        ];
        const revCodeColumns = [
            {
                label: "",
                data: rowData?.revCodeDescription ?? ''
            }
        ];

        hoverColumns.set('pxCode', pxCodeColumns);
        hoverColumns.set('revCode', revCodeColumns);

        return hoverColumns;
    }


    function handleTraceOpen() {

        if (sections && sections.length > 0) {
            setAdvancedTraceOpen(true);
        } else {
            setAnalysisTraceOpen(true);
        }
    }
    function handleTraceClose() {
        setAnalysisTraceOpen(false);
    }

    const handleFacilityBasedPrint = () => {
        addRemoveTextDecoration(true,componentRef)
        handlePrint();
        addRemoveTextDecoration(false,componentRef)
        
    }

    function handleStyleForPrint() {
        if(facilitiesData?.length>1)
        {   
            setHaveActiveFacilities(true)
        }
        else if(facilitiesData?.length===1)
        {
            getBrandingDetails(facilitiesData[0]?.id,handleFacilityBasedPrint)
        }
        else {
            getBrandingDetails(null,handleFacilityBasedPrint)

        }
    }

    

    const addRemoveTextDecoration = (isRemove, componentRef) => {
        const elements = [
            { id: 'print-logo-img', styles: { display: isRemove ? 'block' : 'none' } },
            { id: 'card-1', classes: 'mx-5' },
            { id: 'card-2', classes: 'mx-5' },
            { id: 'card-3', classes: 'mx-5', styles: { display: isRemove ? 'grid' : 'none' } },
            { id: 'card-4', classes: 'mx-5', styles: { display: isRemove ? 'block' : 'none' } },
            { id: 'card-5', classes: 'mx-5', styles: { display: isRemove ? 'block' : 'none' } },
        ];
        elements.forEach(({ id, classes, styles }) => {
            const element = document.getElementById(id);
            if (classes) {
                isRemove ? element.classList.add(classes) : element.classList.remove(classes);
            }
            if (styles) {
                Object.assign(element.style, styles);
            }
        });
        const links = componentRef.current.querySelectorAll('.datatable tr td:nth-child(2) a, .datatable tr td:nth-child(3) a');
        links.forEach(link => {
            link.style.textDecoration = isRemove ? 'none' : 'underline';
            link.style.color = isRemove ? 'black' : '#2739c1';
        });
     };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handleActiveFacilityDialogClose = () => {

        setHaveActiveFacilities(false)
    }
    function handlePocketCostOpen() {
        setPocketCostDialog(true);
    }
    function handlePocketCostClose() {
        setPocketCostDialog(false);
    }

    return (
        <Box className={classes.background}>
        <Loadable loading={getEstimatePending || getFacilitiesPending || getBrandingDetailsPending}>
          
                <DialogTitle>
                    <Grid container alignItems='center'>
                        <Grid item xs={5}>
                            <Typography variant="h6">Estimation History</Typography>
                        </Grid>
                        <Grid xs={6}>
                            <Grid container className='justify-content-end'>
                            {outPocket && <Button onClick={handlePocketCostOpen}
                                variant='outlined'
                                color="primary">
                                    Out of Pocket Cost
                            </Button>}
                                <Button onClick={handleTraceOpen}
                                    className='ml-2'
                                    variant='outlined'
                                    color="primary">
                                    Analysis Trace
                                </Button>
                                <Button
                                    onClick={handleStyleForPrint}
                                    className='ml-2'
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                >
                                    Print
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} className='text-right'>
                            <BackButton text='Back' defaultRoute="/estimator/history" />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid xs={12} ref={componentRef}>
                        <div id='print-logo-img' style={{ display: 'none' }} className="kt-login__logo text-center my-5">
                            <a href="/#">
                                
                                <img alt="logo" 
                                
                                // src={toAbsoluteUrl("/media/logos/logo-light.v3.png?v=4")}
                                
                                src={brandingDetails?.logoPath ? (brandingDetails?.usePricerLogo ? 
                                    `https://${brandingDetails?.pricerBucket}.s3.amazonaws.com/${brandingDetails?.logoPath}?q=${Date.now()}` :
                                    toAbsoluteUrl(`/${brandingDetails?.logoPath}?q=${Date.now()}`)) 
                                    : toAbsoluteUrl("/media/logos/logo-light.v3.png?v=4")}
                                />
                            </a>
                        </div>
                        <Card id='card-1'>
                            <Card.Header as="h5">Claim Estimation</Card.Header>
                            <Card.Body>
                                <Grid container md={12} className='px-3 py-2'>
                                    <Grid item xs={5} className='py-1'>
                                        <CustomLabel label="Estimation Id" data={data?.estimationId} />
                                    </Grid>
                                    <Grid item xs={5} className='py-1'>
                                        <CustomLabel label="Patient Name" data={`${data?.patientFName || ''} ${data?.patientLName || ''}`} />
                                    </Grid>
                                    <Grid item xs={5} className='py-1'>
                                        <CustomLabel label="Contract" data={data?.contract?.name || ''} />
                                    </Grid>
                                    <Grid item xs={5} className='py-1'>
                                        <CustomLabel label="Claim Type" data={data?.claimType} />
                                    </Grid>
                                    <Grid item xs={5} className='py-1'>
                                        <CustomLabel label="Total Claim Amount" data={`$${data?.claimAmount?.toFixed(2) || ''}`} />
                                    </Grid>
                                    <Grid item xs={5} className='py-1'>
                                        <CustomLabel label="Allowable" data={`$${data?.estimatedClaimAmount?.toFixed(2) || ''}`} />
                                    </Grid>
                                    <Grid item xs={5} className='py-1'>
                                        <CustomLabel label="Anticipated Service Date" data={asDate(data?.admitionDate)} />
                                    </Grid>
                                    {Boolean(data?.copay !== null && data?.remainingDeductible !== null && data?.coInsurancePercent !== null && data?.maxOutOfPocket) &&
                                        <Grid item xs={5} className='py-1'>
                                            <CustomLabel label="Out of the Pocket" data={`$${data?.outOfPocketAmount?.toFixed(2) ?? 'N/A'}`} />
                                        </Grid>
                                    }
                                </Grid>
                            </Card.Body>
                        </Card>
                        <Card className='mt-4' id='card-2'>
                            <Card.Header as="h5">Charge Estimation</Card.Header>
                            <Card.Body>
                                <DataTable
                                    fields={fields}
                                    data={data?.chargeEstimations}
                                    order={order}
                                    onOrder={setOrder}
                                    sort={sort}
                                    onSort={setSort}
                                    displayTotal={true}
                                    hoverData={hoverData}
                                />


                            </Card.Body>
                        </Card>
                        <Card className={`mt-4 ${classes.signaturesCard}`} id='card-3'>
                            {brandingDetails?.signatureLabelList?.map((item)=>
                            <Box className={`${classes.signaturesCardContainer}`} >
                                <Box className={`${classes.signaturesCardBox}`}>
                                    <Typography className={`${classes.signaturesCardText}`}>
                                        {item}
                                    </Typography>
                                    <Box className={`${classes.signaturesField}`} />
                                </Box>
                                <Box className={`${classes.signaturesDateFieldContainer}`} >
                                <Typography className={`${classes.signaturesDateFieldText}`} >
                                    Date</Typography>
                                    <Box className={`${classes.signaturesDate}`}/>
                                </Box>
                            </Box>)
                        }


                        </Card>

                        <Card className={`mt-4 ${classes.disclaimerCard}`} id='card-4' >

                        <Typography><Box className={`ql-editor ${classes.disclaimerTextStyle}`} dangerouslySetInnerHTML={{__html: brandingDetails?.disclaimer}} /></Typography>
                        </Card>


                        <Card className={`mt-4 ${classes.addressCardStyles}`} id='card-5' >



                        <Grid container alignItems='center'>
                        <Grid item xs={6}>
                            <Typography>{brandingDetails?.address} , {brandingDetails?.phone}</Typography>
                        </Grid>
                        <Grid xs={6}>
                            <Grid container className='justify-content-end'>

                            <Typography>Date Time: { asDate()}</Typography>

                               
                            </Grid>
                        </Grid>
                        
                    </Grid>

                    </Card>

                    </Grid>
                </DialogContent>
        </Loadable>
        <TraceDialog open={isAnalysisTraceOpen} handleClose={handleTraceClose} traces={data?.analysisTraces} />
        <AdvancedTrace sections={sections} isTraceOpen={isAdvancedTraceOpen} setTraceOpen={setAdvancedTraceOpen} title={data?.estimationId} />
        <ActiveFacilitiesDialog open={haveActiveFacilities} handleClose={handleActiveFacilityDialogClose} facilities={facilitiesData} getBrandingDetails={getBrandingDetails} getBrandingDetailsPending={getBrandingDetailsPending} handlePrint={handleFacilityBasedPrint}/>

        <PocketCostDialog open={isPocketCostDialogOpen} handleClose={handlePocketCostClose} estimationId={data?.estimationId} claimAmount={data?.estimatedClaimAmount} />

        </Box>
    )
}

export default EstimateHistoryDetailsPage