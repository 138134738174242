import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import HospitalServicesPageComponent from "./HospitalServicesPage";

import { hospitalServicesLanding, resetPage, set } from '../../store/actions/pages';
import { dateEquators, integerEquators, StringEquators } from '../../store/constants/pages';
import { createDateField, createFloatingPoint, createIconDataField, createNumberDataField, createStringDataField } from '../../util/format';

import { setV2 } from '../../store/actions/pagesV2';
import { getByName, reset } from '../../store/actions/search';
import { selectSessionRole } from '../../store/selectors/session';
import { setHopsitalServicesDependencyDialog, setHopsitalServicesImportConfirmationDialog, setHopsitalServicesLookupDialog } from "../../store/actions/hospitalServices";

const selectFields = createSelector(
    () => [
        createStringDataField('drg', 'DRG', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringDataField('type', 'Type', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringDataField('title', 'Description', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createNumberDataField('weights', 'Weight', { sortable: true, equators: integerEquators }),
        createDateField('effectiveTo', 'Effective To', { sortable: true, equators: dateEquators }),
        createIconDataField('isEffective', 'Effective', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
        createIconDataField('isDiscontinued', 'Discontinued', { hideFromFilter: false, isStrictDropdown: true, icon: "fas fa-times-circle", checkedClass: 'text-danger', sortable: true }),
    ]
);

const selectHospitalServicesData = createSelector(
    (state) => state.pages['hospitalservices-landing'].list,
    (data) => data
);

export const selectCheckboxStatus = createSelector(
    selectHospitalServicesData,
    (hospitalServices: any) => {
        const enabledHospitalServices = hospitalServices?.filter(hospitalService => hospitalService.active).length;
        if (hospitalServices?.length === enabledHospitalServices) {
            return true;
        }
        return false;
    }
);


export const HospitalServicesPage = connect(
    () => {
        return (state, props) => ({
            hospitalServicesData: selectHospitalServicesData(state),
            hospitalServicesFields: selectFields(state as never),
            checkboxStatus: selectCheckboxStatus(state),
            userRole: selectSessionRole(state as never)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            hospitalServicesLanding: hospitalServicesLanding,
            searchGetByName: getByName,
            searchReset: reset,
            resetPagedTable: resetPage,
            setList: (value) => set('hospitalservices-landing', 'list', value),
            setSelectedItems: (value) => setV2('hospitalservices-landing', 'selected', value),
            setMasterSelectedItems: (value) => setV2('hospitalservices-master-landing', 'selected', value),
            setHopsitalServicesDependencyDialog: setHopsitalServicesDependencyDialog,
            setHopsitalServicesImportConfirmationDialog: setHopsitalServicesImportConfirmationDialog,
            setHopsitalServicesLookupDialog: setHopsitalServicesLookupDialog
        }, dispatch)
    })
)(injectIntl(HospitalServicesPageComponent));