import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { setV2 } from '../../../../store/actions/pagesV2';
import { deleteMRFModifier, setMRFModifiersDependencyDialog } from '../../../../store/actions/modifiers';

export const DependencyDialog = connect(
    () => {
        const selectModifiersIds = (state, props) => state.pagesV2['mrf-modifiers-landing'].selected;
        const selectDependencyDialog = (state) => state.modifiers.dependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (dependencyDialog) => {
                if (dependencyDialog && dependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            selectedItems: selectModifiersIds(state, props),
            deleteMRFModifiersPending: state.modifiers.deleteMRFModifier.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setSelected: (value) => setV2('mrf-modifiers-landing', 'selected', value),
            deleteMRFModifiers: deleteMRFModifier,
            setMRFModifiersDependencyDialog: setMRFModifiersDependencyDialog
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));