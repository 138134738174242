import {
    METHOD_POST,
    SERVER_URL,
    METHOD_GET,
    METHOD_PUT,
    METHOD_DELETE
} from '../constants/api';
import {
    headersAuthSendReceiveJson,
} from '../headers';
import { apiHandleResponse } from '../../util/api';

const getContractsV2 = (directoryId) => {
    let api = `${SERVER_URL}/api/v2/cms/contract/list`;
    if (directoryId !== 'root') {
        api = `${SERVER_URL}/api/v2/cms/contract/list/${directoryId}`
    }

    return fetch(api, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const getHeirarchy = () => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/directory/hierarchy`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const createDirectory = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/directory`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};
const updateDirectory = (options) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/directory`, {
        method: METHOD_PUT,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

const getContractsV2DirectoryDependencies = (directoryId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/directory/dependencies/${directoryId}`, {
        method: METHOD_GET,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};

const deleteContractsV2ScheduleDirectory = (directoryId) => {
    return fetch(`${SERVER_URL}/api/v1/cms/contract/directory/${directoryId}`, {
        method: METHOD_DELETE,
        headers: headersAuthSendReceiveJson(),
    }).then(apiHandleResponse);
};
const moveDirectoryOrContracts = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/contractanddirectory/move`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

 
const searchContracts = (options) => {
    return fetch(`${SERVER_URL}/api/v2/cms/contract/search`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

export {
    getContractsV2,
    getHeirarchy,
    createDirectory,
    updateDirectory,
    getContractsV2DirectoryDependencies,
    deleteContractsV2ScheduleDirectory,
    moveDirectoryOrContracts,
    searchContracts
};