import React from 'react'
import { EllipsisText } from '../EllipsisText';

function DependentsList({ dependents = [] }) {


    const TreeNode = ({ node }) => {
        return (
            <ul className='pl-3'>
                 <li>{node.name}</li>
                {(node.childDependents && (node.childDependents.length > 0 || node?.dependents?.length>0)) && (
                    <ul className='pl-3'>
                        <ul className='pl-3'>
                            {node.dependents.map(dpt => <li>{dpt.dependentType} ({dpt.count}): {' '}
                                <EllipsisText textArr={dpt.names} />
                            </li>)}
                        </ul>
                        {node.childDependents.map(childNode => (
                            <TreeNode key={childNode.name} node={childNode} />
                        ))}
                    </ul>
                )}
            </ul>
        );
    };

    const Tree = ({ data }) => {
        return (
            <div>
                {data.map(rootNode => (
                    <TreeNode key={rootNode.name} node={rootNode} />
                ))}
            </div>
        );
    };

    return (
        <ul>
            <Tree data={dependents} />
        </ul>
    );
}

export default DependentsList
