import * as utils from "../../_metronic/utils/utils";
import { moduleNames } from "../store/constants/pages";
import { fromLocalStorage, fromSessionStorage } from "../util/storage";

const localStorageLastLocationKey = "slicedHealth-lastLocation";
const localStorageBreadcrumLocationKey = "slicedHealth-breadcrumb-based-location";

function acceptLocation(lastLocation) {
  if (
    lastLocation &&
    lastLocation.pathname &&
    lastLocation.pathname !== "/" &&
    lastLocation.pathname.indexOf("auth") === -1 &&
    lastLocation.pathname !== "/logout"
  ) {
    return true;
  }

  return false;
}

export function saveLastLocation(lastLocation) {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  let _useLocations = localStorateLocations
    ? JSON.parse(localStorateLocations)
    : [];

  if (acceptLocation(lastLocation)) {
    _useLocations.push(lastLocation.pathname);
    utils.setStorage(
      localStorageLastLocationKey,
      JSON.stringify(_useLocations),
      120
    );
  }
}

export function forgotLastLocation() {
  utils.removeStorage(localStorageLastLocationKey);
}

export function getLastLocation() {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  if (!localStorateLocations) {
    return "/";
  }

  const _userLocations = JSON.parse(localStorateLocations);
  const result = _userLocations.length > 0 ? _userLocations.pop() : "/";
  return result;
}


export function saveBreadcrumBasedLocation(lastLocation,history) {
  if (lastLocation) {
    let editId = history?.location?.state?.editId
    let isNewRecord=lastLocation?.pathname?.includes('new')
    const currentModuleName = lastLocation.pathname.split('/')[1];

    let previousLocationsObj = fromSessionStorage(localStorageBreadcrumLocationKey);
    let previousLocalStorageObject=fromLocalStorage(localStorageBreadcrumLocationKey)
    const tabCount=getTabCount()
    if(tabCount===1 && utils.isAnyArrayFilled(previousLocalStorageObject) && !utils.isAnyArrayFilled(previousLocationsObj))
    {
      previousLocationsObj=previousLocalStorageObject
      resetBreadCrumbLocalStorageLocations()
    }

    if (currentModuleName && currentModuleName !== 'auth') {
      const tempBreadcrumbs = {};
      for (let module of moduleNames) {
        if (currentModuleName === module.id) {
          tempBreadcrumbs[module.id] = createBreadcrumbLocationArray(editId && isNewRecord  ? {...lastLocation,pathname:lastLocation?.pathname?.replace('new',editId)}:lastLocation, currentModuleName, previousLocationsObj);
        } else {
          tempBreadcrumbs[module.id] = [];
        }
      }

      // utils.setStorage(
      //   localStorageBreadcrumLocationKey,
      //   JSON.stringify(tempBreadcrumbs),
      //   120
      // );

      utils.setSessionStorage(
        localStorageBreadcrumLocationKey,
        JSON.stringify(tempBreadcrumbs),
      );

      utils.setStorage(
        localStorageBreadcrumLocationKey,
        JSON.stringify(tempBreadcrumbs),
      );



      
      // moduleNames
    }
  }
}

export function getLastFiveBreadCrumbLocations(moduleName) {
  // const breadcrumbLocationsObj = fromLocalStorage(localStorageBreadcrumLocationKey);
  // if (breadcrumbLocationsObj && moduleName) {
  //   return breadcrumbLocationsObj[moduleName];
  // }
  // return [];

  const breadcrumbLocationsObj = fromSessionStorage(localStorageBreadcrumLocationKey);
  if (breadcrumbLocationsObj && moduleName) {
    return breadcrumbLocationsObj[moduleName];
  }
  return [];

}

export function getLastFiveBreadCrumbLocationsLocalStorage(moduleName) {
  const breadcrumbLocationsObj = fromLocalStorage(localStorageBreadcrumLocationKey);
  if (breadcrumbLocationsObj && moduleName) {
    return breadcrumbLocationsObj[moduleName];
  }
  return [];

}



export function resetBreadCrumbLocations() {
  const tempBreadcrumbs = {};
  for (let module of moduleNames) {
    tempBreadcrumbs[module.id] = [];
  }
  // utils.setStorage(
  //   localStorageBreadcrumLocationKey,
  //   JSON.stringify(tempBreadcrumbs),
  //   120
  // );

  utils.setSessionStorage(
    localStorageBreadcrumLocationKey,
    JSON.stringify(tempBreadcrumbs)
  );

  utils.setStorage(
    localStorageBreadcrumLocationKey,
    JSON.stringify(tempBreadcrumbs),
    120
  );
}


export function resetBreadCrumbLocalStorageLocations() {
  const tempBreadcrumbs = {};
  for (let module of moduleNames) {
    tempBreadcrumbs[module.id] = [];
  }
  utils.setStorage(
    localStorageBreadcrumLocationKey,
    JSON.stringify(tempBreadcrumbs),
    120
  );

}

function createBreadcrumbLocationArray(lastLocation: { pathname: string | string[]; }, currentModuleName: string | number, previousLocationsObj: { [x: string]: any; }) {

  if (previousLocationsObj) {
    const previousLocation = previousLocationsObj[currentModuleName];
    if (acceptLocation(lastLocation) &&
      (window.location.pathname.includes('/auth/login') || window.location.pathname.includes('logout')) === false
    ) {

      const index = previousLocation.findIndex(item => item === window.location.pathname);
      if (index === -1) {
        //exceptional case
        if (lastLocation.pathname.includes('/contract/edit/') || lastLocation.pathname.includes('/vendor/edit/')) {
          return [...previousLocation];
        } else if (window.location.pathname.includes('/cms/claimAnalysis/view/') && lastLocation?.pathname.includes('/cms/claimAnalysis/view/')) {
          return [...previousLocation];
        }
        else if (window.location.pathname.includes('/cms/modeling/results/view/') && lastLocation?.pathname.includes('/cms/modeling/results/view/')) {
          return [...previousLocation];
        }
        return [...previousLocation, lastLocation?.pathname];
      } else {
        const temp = [...previousLocation];
        temp.splice(index);
        return temp;
      }
    }
    return [...previousLocation];
  }
  return [];
}







export function updateTabCount() {
  const tabCount = localStorage.getItem('tabCount');
  if (tabCount) {
    localStorage.setItem('tabCount', String(Number(tabCount) + 1));
  } else {
    localStorage.setItem('tabCount', '1');
  }
}

export function removeTab() {
  const tabCount = localStorage.getItem('tabCount');
  if (tabCount) {
    localStorage.setItem('tabCount', String(Math.max(Number(tabCount) - 1, 0)));
  }
}

export function getTabCount() {
  return Number(localStorage.getItem('tabCount')) || 0;
}

export const getBackPath = (currentLocation: string, props: { history: any[]; defaultRoute: any; }) => {
  const moduleName = currentLocation.split('/')[1];
  const lastFiveLocationsFromSessionStorage=getLastFiveBreadCrumbLocations(moduleName)
  const lastFiveLocationsFromLocalStorage=getLastFiveBreadCrumbLocationsLocalStorage(moduleName)
  
  const lastFiveLocations = lastFiveLocationsFromSessionStorage?.length>0 ?lastFiveLocationsFromSessionStorage:lastFiveLocationsFromLocalStorage ;
  
  if (lastFiveLocations.length > 0) {
      props.history.push(lastFiveLocations.at(-1));
  }
  else {
      props.history.push(props.defaultRoute ?? '/');
  }
}