import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import React from 'react'

export default function PrimaryFiltersDisregardDialog({open,handleClose,ef,switchDate}) {
    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>
                <span style={{ color: "deeppink" }}>Note</span>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    In case of Unpaid Results (Paid = No), &nbsp;
                    <b>All payment related</b> filters are
                    disregarded
                    {ef[0]?.field === "remitdate" &&
                        ", Do you want to switch to Service Date ?"}
                </DialogContentText>
            </DialogContent>
            {ef[0]?.field !== "remitdate" ? (
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary"
                    >
                        Ok
                    </Button>
                </DialogActions>
            ) : (
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        Cancel
                    </Button>
                    <Button
                        onClick={switchDate}
                        color="primary"
                    >
                        Switch
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}




