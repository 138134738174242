import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { moveDirectoryOrContracts,setMoveDialog } from '../../../store/actions/contractsv2';

import { setV2 } from '../../../store/actions/pagesV2';
import MoveItemsDialogComponent from './MoveItemsDialog';

export const MoveItemsDialog = connect(
    () => {

        const selectDialog = state => state.contractsv2.moveDialog;

        const selectOpen = createSelector(
            selectDialog,
            (dialog) => {
                if (dialog && dialog.open) {
                    return dialog.open
                }
                return false
            }
        );

        const selectHierarchy = (state) => state.contractsv2.hierarchy;

        const selectContractsIds:any = (state, props) => state.pagesV2['contractsv2-landing'].selected;

        return (state, props) => ({
            open: selectOpen(state),
            selectedItems: selectContractsIds(state),
            hierarchy: selectHierarchy(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setMoveDialog: setMoveDialog,
            moveDirectoryOrContracts,
            resetSelectedContracts: () => setV2('contractsv2-landing', 'selected', [])
        }, dispatch)
    })
)(injectIntl(MoveItemsDialogComponent));