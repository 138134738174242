import { Grid, IconButton, makeStyles, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip,Divider,Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Button } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import Helmet from 'react-helmet';
import { Loadable, TableLevelAction } from '../../../common';

import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import AddIcon from "@material-ui/icons/AddCircle";
import CopyIcon from '@material-ui/icons/FileCopy';

import ReorderIcon from "@material-ui/icons/DragIndicator";
import PageviewIcon from '@material-ui/icons/Pageview';
import HeaderColumn from "../../../common/dataTable/HeaderColumn";
import { RowCheckbox } from "../../../common/dataTable/rowCheckbox";
import {
    Portlet,
    PortletBody,
    PortletHeader
} from "../../../partials/content/Portlet";
import { ROLE_ADMIN, ROLE_PARTNER_ADMIN, ROLE_SUPERADMIN } from "../../../store/constants/roles";
import { EllipsisMenu } from '../../../common/EllipsisMenu';
import { asDate } from '../../../util/date';
import { DependencyDialog } from "../DependencyDialog";
import { DirectoryDependencyDialog } from "../DirectoryDependencyDialog";
import { DirectoryEditDialog } from "../DirectoryEditDialog";
import { ContractsBreadCrumb } from "../ContractsBreadCrumb";
import { ContractsDraggable } from "../ContractsDraggable";
import { ContractsDroppable } from "../ContractsDroppable";
import { CopyContractDialog } from '../CopyContractDialog';
import { ContractStateDialog } from '../ContractStateDialog';
import { ContractsSearchComponent } from '../ContractsSearchComponent';

import { MoveItemsDialog } from "../moveItemsDialog";

const useStyles = makeStyles(theme => {
    return {
        root: {
            display: 'flex',
            overflowX: 'auto',
            flexDirection: 'column',
        },
        container: {
            flexGrow: 1,
        },
        textField: {
            width: 400,
        },
        portlet: {
            display: 'flex',
            flexGrow: 1,
        },
        tr: {
            "& th": {
                padding: '6px 24px 6px 16px',
                top: "0px",
                '@media (max-width: 1440px)': {
                    padding: "6px 5px 6px 5px"
                },
                '@media (max-width: 1280px)': {
                    padding: "3px 5px 3px 5px"
                },
                '@media (max-width: 1024px)': {
                    top: '0px',
                    padding: "6px 5px 6px 5px"
                },
                '@media (max-width: 830px)': {
                    top: '0px!important'
                },
                '@media screen and (max-width: 1279px) and (min-width: 1025px)': {
                    top: '0px!important'
                },
            },
        },
        trBody: {
            "& td": {
                '@media (max-width: 1440px)': {
                    padding: "6px 5px 6px 5px"
                },
                '@media (max-width: 1280px)': {
                    padding: "3px 5px 3px 5px"
                },
                '@media (max-width: 1024px)': {
                    padding: "6px 5px 6px 5px"
                }
            },
        },
        folderDiv: {
            cursor: 'pointer',
            textDecoration: 'underline'
        },
        searchIcon:{
            position:'relative',
            bottom:'8px'
        },
        button: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: '0.25rem',
        },
        banIcon: {
            fontSize: 17
        }
    }
});

export default function ContractsPageV2({ intl, history, directoryId, fields, contracts, directories, order, sort, getListPending, deletePending, userRole, movePending,
    actions: { setSort, setOrder, getContractsList, resetContracts,
        setDirectoryEditDialog, setMoveDialog, resetSelected, setContractsV2DirectoryDependencyDialog, getHierarchy,getFacilities,deleteReset,setCopyContractDialog,setContractDependencyDialog,setStateDialog,deleteClaimByContractId,getStates }
}) {
    const classes = useStyles();

    const { id: columnOrder, direction } = (sort && sort.length > 0 && sort[0]) || {};

    const [anchorEl, setAnchorEl] = useState(null);
    const [isClaimConfirmOpen, setClaimConfimOpen] = useState(false);
    const [existingContractId, setExistingContractId] = useState(null);

    const open = Boolean(anchorEl);

    useEffect(() => {
        getHierarchy();
    }, [getHierarchy]);

    useEffect(() => {
        getFacilities()
    }, [getFacilities]);


    useEffect(() => {
        getContractsList(directoryId);
        return () => {
            resetContracts();
        }
    }, [directoryId, getContractsList, resetContracts]);

    useEffect(() => {
        getStates();
    }, [getStates]);

    const handleRedirectToDirectory = (directoryId) => {
        resetSelected();
        history.push(`/cms/contracts/${directoryId}`);
    }

    const handleView = (rowData) => {
        history.push(`/cms/contract/view/${rowData?.id}/${directoryId}`);
    }
    const handleEdit = (rowData) => {
        history.push(`/cms/contract/edit/${rowData.id || 'new'}/${directoryId}`);

    }

    function handleRefresh() {
        getContractsList(directoryId);
        getHierarchy();
    }

    const handleOpenAddMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseAddMenu = () => {
        setAnchorEl(null);
    };

    const handleAddFolder = () => {
        setDirectoryEditDialog({ open: true, mode: 'create', parentId: directoryId });
        handleCloseAddMenu();
    }

    const handleEditFolder = ({ id, name }) => {
        setDirectoryEditDialog({ open: true, mode: 'edit', parentId: directoryId, id, name });
    }

    const handleFolderDeleteDependencies = ({ id, name }) => {
        setContractsV2DirectoryDependencyDialog({ open: true, directoryId: id, directoryName: name })
    }


    const handleMoveFiles = () => {
        setMoveDialog({ open: true });
    }
    const handleSearchClick = (rowData) => {
        history.push(`/cms/contract/view/${rowData?.id}`);
    }

    const tableLevelActions = () => {
        return (
            <>
                <Grid item>
                    <IconButton
                        id="add-fee-button"
                        aria-controls={open ? "add-fee-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleOpenAddMenu}
                        className="p-1"
                    >
                        <Tooltip title="Add">
                            <AddIcon />
                        </Tooltip>
                    </IconButton>
                    <Menu
                        id="add-fee-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseAddMenu}
                        MenuListProps={{
                            "aria-labelledby": "add-fee-button",
                        }}
                        PaperProps={{
                            style: {
                                marginTop: 40,
                            },
                        }}
                        transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                        }}
                        anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    >
                        <MenuItem onClick={handleEdit}>Contract</MenuItem>
                        <MenuItem onClick={handleAddFolder}>Folder</MenuItem>
                    </Menu>
                </Grid>

                <Grid item className={`${classes.searchIcon}`}>
                    <ContractsSearchComponent
                        handleSetContract={handleSearchClick}
                    />
                </Grid>
                <Grid item>
                    <IconButton
                        className="p-1"
                        aria-label="Edit"
                        onClick={handleMoveFiles}
                    >
                        <Tooltip title="Move Files">
                            <i className="fas fa-file-import"></i>
                        </Tooltip>
                    </IconButton>
                </Grid>
            </>
        );
    }


    const handleSort = ({ id, direction, fieldType }) => {
        if (!setSort) {
            return;
        }

        const sorts = [...sort];
        const updatedSort = { id, fieldType, direction };
        const oldSort = sorts.find((sort) => {
            return sort.id === id;
        });

        // sort exists, toggle sort
        if (oldSort && oldSort.id === id) {
            const index = sorts.indexOf(oldSort);
            updatedSort.direction = oldSort.direction === 'asc' ? 'desc' : 'asc';
            sorts[index] = updatedSort;
        }
        // new sort
        else {
            updatedSort.direction = 'desc';
            sorts.splice(0, sorts.length);
            sorts.push(updatedSort);
        }
        setSort(sorts);
    }

    function handleCopyContract(contractObj) {
        deleteReset();
        setCopyContractDialog({ 
            open: true, 
            existingContractId: contractObj.id, 
            existingContractName: contractObj.name, 
            existingContractStateId: contractObj.contractStateId 
        });
    }

    const handleDeleteConfirmation = (rowData) => {
        setContractDependencyDialog({
            open: true,
            contractId: rowData.id,
            contractName: rowData.name
        })
    }
    const handleClaimOpen = (rowData) => {
        setClaimConfimOpen(true);
        setExistingContractId(rowData.id)
    }
    const handleChangeState = (rowData) => {
        setStateDialog({
            open: true,
            contractId: rowData.id,
            contractStateId: rowData.contractState.id
        });
    }
    const handleStateDialogClose = (shouldRefresh) => {
        setStateDialog(null);
        if (shouldRefresh) {
            handleRefresh();
        }
    }
    const handleClaimClose = () => {
        setClaimConfimOpen(false);
    }

    const handleAnalysisDelete = () => {
        handleClaimClose();
        deleteClaimByContractId(existingContractId, handleAnalysisDeleteCallback);
    }

    const handleAnalysisDeleteCallback = () => {
        setExistingContractId(null);
    }

    const ContractHierarchyActions = ({ rowData }) => {
        return (
            <div className="d-flex">
                <IconButton
                    color="primary"
                    className="p-1"
                    aria-label="Edit"
                    onClick={() => handleView(rowData)}
                >
                    <Tooltip title="View">
                        <PageviewIcon />
                    </Tooltip>
                </IconButton>
                <IconButton
                    color="primary"
                    className="p-1"
                    aria-label="Edit"
                    onClick={() => handleEdit(rowData)}
                >
                    <Tooltip title="Edit">
                        <EditIcon />
                    </Tooltip>
                </IconButton>
                <div>
                    <EllipsisMenu
                        renderMenuItems={[
                            <MenuItem
                                className="justify-content-center"
                                onClick={() => handleCopyContract(rowData)}
                            >
                                <IconButton
                                    color="primary"
                                    className="p-1"
                                    aria-label="Copy"
                                >
                                    <Tooltip title={`Copy Contract`}>
                                        <CopyIcon />
                                    </Tooltip>
                                </IconButton>
                            </MenuItem>,
                            <Divider />,
                            <MenuItem
                                className="justify-content-center px-0"
                                onClick={() =>
                                    handleDeleteConfirmation(rowData)
                                }
                            >
                                <IconButton
                                    color={"primary"}
                                    className={classes.button}
                                    aria-label="Delete"
                                >
                                    <Tooltip title={"Delete Contract"}>
                                        <DeleteIcon />
                                    </Tooltip>
                                </IconButton>
                            </MenuItem>,
                            <Divider />,
                            <MenuItem
                                onClick={() => handleClaimOpen(rowData)}
                                className="justify-content-center px-0"
                            >
                                <IconButton color="primary" className="p-1">
                                    <Tooltip title={"Delete Analysis"}>
                                        <i
                                            className={`${classes.banIcon} fas fa-ban`}
                                        ></i>
                                    </Tooltip>
                                </IconButton>
                            </MenuItem>,
                            <Divider />,
                            <MenuItem
                                onClick={() => handleChangeState(rowData)}
                                className="justify-content-center px-0"
                            >
                                <IconButton color="primary" className="p-1">
                                    <Tooltip title={"Change Contract State"}>
                                        <i
                                            className={`${classes.banIcon} fas fa-exchange-alt`}
                                        ></i>
                                    </Tooltip>
                                </IconButton>
                            </MenuItem>,
                        ]}
                    />
                </div>
            </div>
        );
    }


    return (
        <>
            <ContractsBreadCrumb directoryId={directoryId} handleRefresh={handleRefresh} />
            <Loadable loading={getListPending || deletePending || movePending}>
                <Helmet title="Contracts" />
                <Portlet className={classes.root}>
                    <PortletHeader
                        title="Contracts"
                        toolbar={<></>}
                    />
                    <PortletBody>

                        <Grid spacing={1} container>
                            <Grid item xs={12}>
                                <TableLevelAction
                                    handleRefresh={handleRefresh}
                                    actions={tableLevelActions}
                                    name={''}
                                    loading={false}
                                    actionLevelGrid={false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Table stickyHeader={true}>
                                    <TableHead>
                                        <TableRow className={`${classes.tr} fee-schedule-thr`}>
                                            <TableCell className="drag-cell"></TableCell>
                                            {fields.map((field, index) => (
                                                !field?.options.hideFromGrid && <HeaderColumn
                                                    key={`column-${index}`}
                                                    field={field}
                                                    onSort={handleSort}
                                                    direction={direction}
                                                    columnOrder={columnOrder}
                                                />

                                            ))}
                                            <HeaderColumn field={{ label: 'Actions' }} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {directories?.length === 0 && contracts?.length === 0 &&
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell colSpan={5}>No records found!</TableCell>
                                            </TableRow>
                                        }
                                        {directories?.map(item => {
                                            return <ContractsDroppable item={item} classes={classes} handleRefresh={handleRefresh}>
                                                <TableCell id={`folder-${item.id}`} className="drag-cell">
                                                    <IconButton id={`folder-${item.id}`} className='fee-schedule-rerder-button'><ReorderIcon />
                                                    </IconButton>
                                                    <RowCheckbox rowDataId={`folder-${item.id}`} pageName={'contractsv2-landing'} customClasses='p-1' />
                                                </TableCell >
                                                <TableCell id={`folder-${item.id}`} colSpan={4}>
                                                    <div id={`folder-${item.id}`} className={classes.folderDiv} onClick={() => handleRedirectToDirectory(item.id)}>
                                                        <IconButton id={`folder-${item.id}`} className="p-1 mr-2">
                                                            <i id={`folder-${item.id}`} style={{ color: '#FFCA35' }} className="fas fa-folder"></i>
                                                        </IconButton>
                                                        {item.name}
                                                    </div>
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>

                                                <TableCell className="pl-3">
                                                    <IconButton
                                                        color="primary"
                                                        className='p-1 invisible'
                                                        aria-label="Edit"
                                                    >
                                                        <Tooltip title="View">
                                                            <PageviewIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <IconButton
                                                        color="primary"
                                                        className='p-1'
                                                        aria-label="Edit"
                                                        onClick={() => handleEditFolder(item)}
                                                    >
                                                        <Tooltip title="Edit">
                                                            <EditIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                    {(userRole === ROLE_SUPERADMIN || userRole === ROLE_ADMIN || userRole === ROLE_PARTNER_ADMIN) &&
                                                        <IconButton
                                                            onClick={() => handleFolderDeleteDependencies(item)}
                                                            color={"primary"}
                                                            className='p-1'
                                                            aria-label="Delete"
                                                        >
                                                            <Tooltip title={"Delete Folder"} >
                                                                <DeleteIcon />
                                                            </Tooltip>
                                                        </IconButton>}
                                                </TableCell>
                                            </ContractsDroppable>
                                        })}
                                        {contracts?.map(item => {
                                            return <ContractsDraggable classes={classes} item={item}>


                                                <TableCell className="drag-cell">
                                                    <div className="d-flex">
                                                        <IconButton className='fee-schedule-rerder-button'>
                                                            <ReorderIcon />
                                                        </IconButton>
                                                        <RowCheckbox rowDataId={`contracts-${item.id}`} pageName={'contractsv2-landing'} customClasses='p-1' />
                                                    </div>
                                                </TableCell>
                                                <TableCell className="pl-4">{item.name}</TableCell>
                                                <TableCell>{asDate(item.effectiveDate) || 'N/A'}</TableCell>
                                                <TableCell>{asDate(item.terminationDate) || 'N/A'}</TableCell>
                                                <TableCell>{asDate(item.finalizedDate) || 'N/A'}</TableCell>
                                                <TableCell>{fields[4]?.renderString(item?.contractFacilityXref)}</TableCell>
                                                <TableCell>{fields[5]?.renderString(item?.contractState?.name)}</TableCell>

                                                <TableCell><ContractHierarchyActions rowData={item} /> </TableCell>

                                               
                                            </ContractsDraggable>
                                        })}
                                    </TableBody>
                                </Table>
                            </Grid>

                        </Grid>
                    </PortletBody>
                </Portlet>
            </Loadable>
            <DependencyDialog handleRefresh={handleRefresh} />
            <DirectoryDependencyDialog handleRefresh={handleRefresh} />
            <DirectoryEditDialog handleRefresh={handleRefresh} />
            <MoveItemsDialog handleRefresh={handleRefresh} />
            <CopyContractDialog
                handleRefresh={handleRefresh}
            />
            <ContractStateDialog handleClose={handleStateDialogClose} />
            <DependencyDialog handleRefresh={handleRefresh} />
            <Dialog open={isClaimConfirmOpen} onClose={handleClaimClose}>
                <DialogTitle>
                    Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete Analysis of this contract?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClaimClose} color="default">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAnalysisDelete}
                        color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}