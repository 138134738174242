import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DirectoryEditDialogComponent from './DirectoryEditDialog';
import { setDirectoryEditDialog,createDirectory,updateDirectory } from '../../../store/actions/contractsv2';


export const DirectoryEditDialog = connect(
    () => {

        const selectDialog = state => state.contractsv2.directoryEditDialog;

        const selectOpen = createSelector(
            selectDialog,
            (dialog) => {   
                if (dialog && dialog.open) return true
                return false
            }
        )

        const selectParentId:any = createSelector(
            selectDialog,
            (dialog) => {
                if (dialog && dialog.parentId) {
                    return dialog.parentId
                }
                return null
            }
        )

        const selectDirectoryId:any = createSelector(
            selectDialog,
            (dialog) => {
                if (dialog && dialog.id) {
                    return dialog.id
                }
                return null
            }
        )

        const selectDirectoryName = createSelector(
            selectDialog,
            (dialog) => {
                if (dialog && dialog.name) {
                    return dialog.name
                }
                return ''
            }
        )

        const selectMode:any = createSelector(
            selectDialog,
            (dialog) => {
                return dialog?.mode ?? 'create';
            }
        )


        const selectInitialValues:any = createSelector(
            selectMode,
            selectDirectoryName,
            (mode, name) => {
                if (mode === 'edit') {
                    return {
                        name
                    }
                }

                return {
                    name: '',
                }
            }
        );

        return (state:any, props:any) => ({
            createPending: state.contractsv2.createDirectory.pending,
            updatePending: state.contractsv2.updateDirectory.pending,
            // edit
            initialValues: selectInitialValues(state, props),
            mode: selectMode(state, props),
            directoryId: selectDirectoryId(state, props),
            parentId: selectParentId(state, props),
            open: selectOpen(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setDirectoryEditDialog: setDirectoryEditDialog,
            createDirectory: createDirectory,
            updateDirectory: updateDirectory
        }, dispatch)
    })
)(injectIntl(DirectoryEditDialogComponent));