import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import MRFModifiersPageComponent from "./MRFModifiersPage";

import { MRFModifiersLanding, resetPage, set } from '../../../store/actions/pages';
import { dateEquators, integerEquators, StringEquators } from '../../../store/constants/pages';
import { createDateField, createFloatingPoint, createIconDataField, createNumberDataField, createStringDataField } from '../../../util/format';

import { getByName, reset } from '../../../store/actions/search';
import { selectSessionRole } from '../../../store/selectors/session';
import { checkExistingMRFModifiers, setMRFModifiersDependencyDialog, setMRFModifiersEditDialog, setMRFModifiersImportConfirmationDialog } from "../../../store/actions/modifiers";
import { setV2 } from "../../../store/actions/pagesV2";
import { CONTRACTED_SERVICE_INPATIENT, CONTRACTED_SERVICE_OUTPATIENT, CONTRACTED_SERVICE_BOTH } from "../../../store/constants/contract";

const visitTypes = [
    CONTRACTED_SERVICE_INPATIENT,
    CONTRACTED_SERVICE_OUTPATIENT,
    CONTRACTED_SERVICE_BOTH
];

const selectFields = createSelector(
    () => [
        createStringDataField('name', 'Modifier', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringDataField('description', 'Description', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createNumberDataField('percentage', 'Percentage', { sortable: true, equators: integerEquators }),
        createStringDataField('payerContractName', 'Payer Contract Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringDataField('visitType', 'Visit', { sortable: true, equators: StringEquators, isDropdown: true, dropdownValues: visitTypes }),
        createStringDataField('paymentDescription', 'Payment Description', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createIconDataField('isImported', 'Imported', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
        createIconDataField('isDuplicated', 'Duplicated', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
    ]
);

const selectMRFModifiersData = createSelector(
    (state) => state.pages['mrf-modifiers-landing'].list,
    (data) => data
);

export const selectCheckboxStatus = createSelector(
    selectMRFModifiersData,
    (MRFModifiers: any) => {
        const enabledMRFModifiers = MRFModifiers?.filter(modifier => modifier.active).length;
        if (MRFModifiers?.length === enabledMRFModifiers) {
            return true;
        }
        return false;
    }
);


export const MRFModifiersPage = connect(
    () => {
        return (state, props) => ({
            MRFModifiersData: selectMRFModifiersData(state),
            MRFModifiersFields: selectFields(state as never),
            checkboxStatus: selectCheckboxStatus(state),
            userRole: selectSessionRole(state as never),
            checkExistingPending: state.modifiers.checkExisting.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            MRFModifiersLanding: MRFModifiersLanding,
            searchGetByName: getByName,
            searchReset: reset,
            resetPagedTable: resetPage,
            setList: (value) => set('mrf-modifiers-landing', 'list', value),
            setSelectedItems: (value) => setV2('mrf-modifiers-landing', 'selected', value),
            setMRFModifiersImportConfirmationDialog: setMRFModifiersImportConfirmationDialog,
            setMRFModifiersDependencyDialog: setMRFModifiersDependencyDialog,
            setEditDialog: setMRFModifiersEditDialog,
            checkExistingMRFModifiers: checkExistingMRFModifiers
        }, dispatch)
    })
)(injectIntl(MRFModifiersPageComponent));