import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import ContractsDraggableComponent from './ContractsDraggable';

export const ContractsDraggable = connect(
    () => {
        const selectContractsIds = (state, props) => state.pagesV2['contractsv2-landing'].selected;

        return (state, props) => ({
            selectContractsIds: selectContractsIds(state,props)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
        }, dispatch)
    })
)(injectIntl(ContractsDraggableComponent as any));