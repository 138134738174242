import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { importMRFModifiers, importMRFModifiersErrorReset, setMRFModifiersImportDialog } from '../../../../store/actions/modifiers';
import ModifiersImportComponent from './ModifiersImport';
import { createDateField, createIconDataField, createNumberDataField, createStringDataField } from '../../../../util/format';
import { dateEquators, integerEquators, StringEquators } from '../../../../store/constants/pages';
import { set } from '../../../../store/actions/pages';
import { createFilteredSortedDataSelector } from '../../../../util/selector';
import { CONTRACTED_SERVICE_INPATIENT, CONTRACTED_SERVICE_OUTPATIENT, CONTRACTED_SERVICE_BOTH } from "../../../../store/constants/contract";

export const ModifiersImport = connect(
    () => {

        const visitTypes = [
            CONTRACTED_SERVICE_INPATIENT,
            CONTRACTED_SERVICE_OUTPATIENT,
            CONTRACTED_SERVICE_BOTH
        ];

        const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];

        const selectFields = createSelector(
            () => [
                createStringDataField('name', 'Modifier', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
                createStringDataField('description', 'Description', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
                createNumberDataField('percentage', 'Percentage', { sortable: true, equators: integerEquators }),
                createStringDataField('payerContractName', 'Payer Contract Name', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
                createStringDataField('visitType', 'Visit', { sortable: true, equators: StringEquators, isDropdown: true, dropdownValues: visitTypes }),
            ]
        );
        
        const selectData = createSelector(
            (state) => state.modifiers.importedMRFModifiers,
            (data) => {
                return data;
            }
        );

        const selectFilteredFields = createSelector(
            selectFields,
            (fields) => {
                return fields.map(field => field.id);
            }
        );
        
        const selectSort = createSelector(
            (state, props) => state.pages[`modifiers-import-landing`].sort,
            (sort) => sort || DEFAULT_SORT,
        );
        
        const selectFilteredData = createFilteredSortedDataSelector(
            selectFilteredFields,
            (state, props) => state.pages[`modifiers-import-landing`].filter,
            selectSort,
            selectData,
        );

        const selectImportDialog = (state) => state.modifiers.importDialog;

        const selectOpen = createSelector(
            selectImportDialog,
            (importDialog) => {
                if (importDialog && importDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectIsDuplicate = createSelector(
            selectImportDialog,
            (selectImportDialog) => {
                if (selectImportDialog && selectImportDialog?.isDuplicate) {
                    return true
                }
                return false
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            isDuplicate: selectIsDuplicate(state),
            fields: selectFields(state as never),
            filteredData: selectFilteredData(state),
            importMRFModifiersPending: state.modifiers.importMRFModifiers.pending,
            order: state.pages[`modifiers-import-landing`].order,
            filter: state.pages[`modifiers-import-landing`].filter,
            sort: selectSort(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            importMRFModifiers: importMRFModifiers,
            importMRFModifiersErrorReset: importMRFModifiersErrorReset,
            setList: (value) => set('modifiers-import-landing', 'list', value),
            setFilter: (value) => set('modifiers-import-landing', 'filter', value),
            setSort: (value) => set('modifiers-import-landing', 'sort', value),
            setOrder: (value) => set('modifiers-import-landing', 'order', value),
            setMRFModifiersImportDialog: setMRFModifiersImportDialog
        }, dispatch)
    })
)(injectIntl(ModifiersImportComponent));