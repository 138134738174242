export interface Rule {
    name: string;
    payMethod: string;
    payValue: number;
    ruleType: string;
}

export interface Claim {
    acknowledged: boolean;
    actualPay: number;
    analysisNotes: string;
    chargedAmount: number;
    claimanalysisid: number;
    claimchargeid: string;
    claimPaidamount: number | null;
    comments: string;
    contract: any;
    contractid: any;
    drg: string;
    drgDescription: string | null;
    expectedPay: number;
    feeScheduleId: number | null;
    id: number;
    isEapgApplied?: boolean;
    isOppsApplied: boolean;
    otherAllowable: any;
    patientResponsibility: number | null;
    paymentfileid: string | null;
    payMethodName: string;
    principalDx: string;
    principalDxDescription: string | null;
    pxcode: string;
    pxcodeDescription: string | null;
    remitDate: string | null;
    revcode: string;
    revcodeDescription: string;
    rule: Rule;
    ruleid: number;
    ruleName: string;
    rulePayValue: number;
    ruleTypeName: string;
    servicepaymentid: string | null;
    total: string;
    units: number;
    variance: number | null;
}

export interface ContractState {
    id: number;
    name: string;
}

export interface ResponseItem {
    id: number;
    name: string;
    terminationDate: string;
    contractState: ContractState;
    effectiveDate: string;
    claims: Claim[];
}

export const response : ResponseItem[] = [{
    id: 10,
    name: "Blue Cross PPO",
    terminationDate: "2023-12-31 00:00:00",
    contractState: {
        id: 3,
        name: "Finalized"
    },
    effectiveDate: "2021-01-01 00:00:00",

    claims: [
        {
            acknowledged: false,
            actualPay: 530,
            analysisNotes: "Service payment information not available for claim 483498",
            chargedAmount: 1377.6,
            claimanalysisid: 10940,
            claimchargeid: "584a8186-9146-455a-a5a3-9ef6e833f637",
            claimPaidamount: null,
            comments: "",
            contract: null,
            contractid: null,
            drg: "",
            drgDescription: null,
            expectedPay: 2060,
            feeScheduleId: null,
            id: 58846,
            isEapgApplied: false,
            isOppsApplied: false,
            otherAllowable: null,
            patientResponsibility: null,
            paymentfileid: null,
            payMethodName: "Per Diem",
            principalDx: "J189",
            principalDxDescription: "\&quot;Pneumonia, unspecified organism\&quot;",
            pxcode: "",
            pxcodeDescription: null,
            remitDate: null,
            revcode: "0120",
            revcodeDescription: "Room &amp; Board ? Semi-private, 2 Beds",
            rule: {
                name: "Medical IP",
                payMethod: '',
                payValue: 1030,
                ruleType: ''
            },
            ruleid: 144,
            ruleName: "Medical IP",
            rulePayValue: 1030,
            ruleTypeName: "RULE",
            servicepaymentid: null,
            total: "59360",
            units: 112,
            variance: 1130
        },{
        acknowledged: false,
        actualPay: 7,
        analysisNotes: "N/A",
        chargedAmount: 15,
        claimanalysisid: 11531,
        claimchargeid: "fd3ab1a1-b704-4914-bc00-11649c7ac547",
        claimPaidamount: 7,
        comments: "",
        contract: null,
        contractid: null,
        drg: "",
        drgDescription: null,
        expectedPay: 12,
        feeScheduleId: null,
        id: 60872,
        isOppsApplied: false,
        otherAllowable: null,
        patientResponsibility: 0,
        paymentfileid: "56c5c2bd-78b7-46c7-b422-0e98f113de76",
        payMethodName: "Percentage",
        principalDx: "K529",
        principalDxDescription: "&amp;amp;quot;Noninfective gastroenteritis and colitis, unspecified&amp;amp;quot;",
        pxcode: "",
        pxcodeDescription: null,
        remitDate: "2023-12-29 00:00:00",
        revcode: "0250",
        revcodeDescription: "Pharmacy",
        rule: {
            name: "All Other OP",
            payMethod: "",
            payValue: 80,
            ruleType: ""
        },
        ruleid: 146,
        ruleName: "All Other OP",
        rulePayValue: 80,
        ruleTypeName: "DEFAULT",
        servicepaymentid: "0352617c-0048-4033-9418-fa84925d9565",
        total: "2800.00",
        units: 400,
        variance: 5
    },
    {
        acknowledged: false,
        actualPay: 44,
        analysisNotes: "Service payment information not available for claim 483498",
        chargedAmount: 10,
        claimanalysisid: 10940,
        claimchargeid: "e328d3b6-1452-4f5d-afd5-b8d91a647746",
        claimPaidamount: null,
        comments: "",
        contract: null,
        contractid: null,
        drg: "",
        drgDescription: null,
        expectedPay: 0,
        feeScheduleId: null,
        id: 58848,
        isEapgApplied: false,
        isOppsApplied: false,
        otherAllowable: null,
        patientResponsibility: null,
        paymentfileid: null,
        payMethodName: "Percentage",
        principalDx: "J189",
        principalDxDescription: "\&quot;Pneumonia, unspecified organism\&quot;",
        pxcode: "",
        pxcodeDescription: null,
        remitDate: null,
        revcode: "0251",
        revcodeDescription: "Generic drugs",
        rule: {
            name: "All Other IP",
            payMethod: '',
            payValue: 80,
            ruleType: ''
        },
        ruleid: 143,
        ruleName: "All Other IP",
        rulePayValue: 80,
        ruleTypeName: "DEFAULT",
        servicepaymentid: null,
        total: "8800",
        units: 200,
        variance: null
    },
    {
        acknowledged: false,
        actualPay: 51,
        analysisNotes: "N/A",
        chargedAmount: 30.45,
        claimanalysisid: 10309,
        claimchargeid: "c62e2c86-6dc4-4022-b4a4-082ed5ea0049",
        claimPaidamount: 0,
        comments: "[reese][2023/12/06 01:12 PM]resubmitted claim",
        contract: null,
        contractid: null,
        drg: "",
        drgDescription: null,
        expectedPay: 27.405,
        feeScheduleId: null,
        id: 56326,
        isEapgApplied: false,
        isOppsApplied: false,
        otherAllowable: null,
        patientResponsibility: 0,
        paymentfileid: "4c34530f-e9e6-4a2b-9070-ec974d5298a0",
        payMethodName: "Percentage",
        principalDx: "I7143",
        principalDxDescription: null,
        pxcode: "36415",
        pxcodeDescription: "ROUTINE VENIPUNCTURE",
        remitDate: "2023-09-14 00:00:00",
        revcode: "0300",
        revcodeDescription: "Laboratory",
        rule: {
            name: "Default",
            payMethod: '',
            payValue: 90,
            ruleType: ''
        },
        ruleid: 168,
        ruleName: "Default",
        rulePayValue: 90,
        ruleTypeName: "DEFAULT",
        servicepaymentid: "bb8c8fba-8e9f-41b6-bfd5-886485f05883",
        total: "20910",
        units: 410,
        variance: 27.4
    }, {
        acknowledged: false,
        actualPay: 38,
        analysisNotes: "N/A",
        chargedAmount: 194.25,
        claimanalysisid: 10309,
        claimchargeid: "2d829c87-696d-422f-93a3-cc722c971127",
        claimPaidamount: 0,
        comments: "[reese][2023/12/06 01:12 PM]resubmitted claim",
        contract: null,
        contractid: null,
        drg: "",
        drgDescription: null,
        expectedPay: 174.825,
        feeScheduleId: null,
        id: 56327,
        isEapgApplied: false,
        isOppsApplied: false,
        otherAllowable: null,
        patientResponsibility: 0,
        paymentfileid: "4c34530f-e9e6-4a2b-9070-ec974d5298a0",
        payMethodName: "Percentage",
        principalDx: "I7143",
        principalDxDescription: null,
        pxcode: "80048",
        pxcodeDescription: "METABOLIC PANEL TOTAL CA",
        remitDate: "2023-09-14 00:00:00",
        revcode: "0301",
        revcodeDescription: "Chemistry",
        rule: {
            name: "Default",
            payMethod: '',
            payValue: 90,
            ruleType: ''
        },
        ruleid: 168,
        ruleName: "Default",
        rulePayValue: 90,
        ruleTypeName: "DEFAULT",
        servicepaymentid: "d829817b-cbfa-4cef-8d89-314e70c03a31",
        total: "17100",
        units: 450,
        variance: 174.82
    }, {
        acknowledged: false,
        actualPay: 177.28,
        analysisNotes: "N/A",
        chargedAmount: 3285.45,
        claimanalysisid: 10309,
        claimchargeid: "02cd841f-4abf-451d-a205-a3dfc81bc8d9",
        claimPaidamount: 0,
        comments: "[reese][2023/12/06 01:12 PM]resubmitted claim",
        contract: null,
        contractid: null,
        drg: "",
        drgDescription: null,
        expectedPay: 2956.905,
        feeScheduleId: null,
        id: 56328,
        isEapgApplied: false,
        isOppsApplied: false,
        otherAllowable: null,
        patientResponsibility: 177.28,
        paymentfileid: "4c34530f-e9e6-4a2b-9070-ec974d5298a0",
        payMethodName: "Percentage",
        principalDx: "I7143",
        principalDxDescription: null,
        pxcode: "74175",
        pxcodeDescription: "CT ANGIO ABDOM W/O &amp; W/DYE",
        remitDate: "2023-09-14 00:00:00",
        revcode: "0350",
        revcodeDescription: "CT Scan",
        rule: {
            name: "Default",
            payMethod: '',
            payValue: 90,
            ruleType: ''
        },
        ruleid: 168,
        ruleName: "Default",
        rulePayValue: 90,
        ruleTypeName: "DEFAULT",
        servicepaymentid: "b4e87f3d-2afa-4800-bae6-38c8006373d9",
        total: "70912",
        units: 400,
        variance: 2779.62
    }, {
        acknowledged: false,
        actualPay: 539.3,
        analysisNotes: "N/A",
        chargedAmount: 1156.05,
        claimanalysisid: 11531,
        claimchargeid: "bea533f7-39d9-4b0c-8605-fb74f3875cf0",
        claimPaidamount: 539.3,
        comments: "",
        contract: null,
        contractid: null,
        drg: "",
        drgDescription: null,
        expectedPay: 369.864,
        feeScheduleId: 12,
        id: 60873,
        isOppsApplied: false,
        otherAllowable: null,
        patientResponsibility: 0,
        paymentfileid: "56c5c2bd-78b7-46c7-b422-0e98f113de76",
        payMethodName: "Percentage",
        principalDx: "K529",
        principalDxDescription: "&amp;amp;quot;Noninfective gastroenteritis and colitis, unspecified&amp;amp;quot;",
        pxcode: "76700",
        pxcodeDescription: "US EXAM ABDOM COMPLETE",
        remitDate: "2023-12-29 00:00:00",
        revcode: "0402",
        revcodeDescription: "Ultrasound",
        rule: {
            name: "Low Tech Rad",
            payMethod: "",
            payValue: 330,
            ruleType: ""
        },
        ruleid: 149,
        ruleName: "Low Tech Rad",
        rulePayValue: 330,
        ruleTypeName: "RULE",
        servicepaymentid: "ed9033fa-ee5e-45aa-b260-fff1564ffd6b",
        total: "272885.80",
        units: 506,
        variance: -169.44
    }, {
        acknowledged: false,
        actualPay: 55.98,
        analysisNotes: "N/A",
        chargedAmount: 120,
        claimanalysisid: 11531,
        claimchargeid: "1f9e9870-bf5f-4bb5-91fb-baf3f91dc252",
        claimPaidamount: 55.98,
        comments: "",
        contract: null,
        contractid: null,
        drg: "",
        drgDescription: null,
        expectedPay: 116.5416,
        feeScheduleId: 12,
        id: 60874,
        isOppsApplied: false,
        otherAllowable: null,
        patientResponsibility: 0,
        paymentfileid: "56c5c2bd-78b7-46c7-b422-0e98f113de76",
        payMethodName: "Percentage",
        principalDx: "K529",
        principalDxDescription: "&amp;amp;quot;Noninfective gastroenteritis and colitis, unspecified&amp;amp;quot;",
        pxcode: "96374",
        pxcodeDescription: "THER/PROPH/DIAG INJ IV PUSH",
        remitDate: "2023-12-29 00:00:00",
        revcode: "0450",
        revcodeDescription: "Emergency Room (ER)",
        rule: {
            name: "Other Non Surgical",
            payMethod: "",
            payValue: 294,
            ruleType: ""
        },
        ruleid: 152,
        ruleName: "Other Non Surgical",
        rulePayValue: 294,
        ruleTypeName: "RULE",
        servicepaymentid: "8c347de9-b926-4743-ad91-72dfb7e4e2f7",
        total: "28325.88",
        units: 506,
        variance: 60.56
    }]
}, {
    contractState: {
        id: 3,
        name: "Finalized"
    },
    effectiveDate: "2022-05-01 00:00:00",
    claims: [{
        acknowledged: false,
        actualPay: 35,
        analysisNotes: "N/A",
        chargedAmount: 118.65,
        claimanalysisid: 10315,
        claimchargeid: "aa975377-784d-4316-885e-cb85d8cf2984",
        claimPaidamount: 0,
        comments: "",
        contract: null,
        contractid: null,
        drg: "",
        drgDescription: null,
        expectedPay: 35,
        feeScheduleId: null,
        id: 58631,
        isEapgApplied: false,
        isOppsApplied: false,
        otherAllowable: null,
        patientResponsibility: 0,
        paymentfileid: "cb65e786-41c9-468f-a509-e4f0460fa940",
        payMethodName: "Per Unit",
        principalDx: "Z1231",
        principalDxDescription: "Encounter for screening mammogram for malignant neoplasm of breast",
        pxcode: "83036",
        pxcodeDescription: "GLYCOSYLATED HEMOGLOBIN TEST",
        remitDate: "2023-09-15 00:00:00",
        revcode: "0300",
        revcodeDescription: "Laboratory",
        rule: {
            name: "Lab",
            payMethod: '',
            payValue: 35,
            ruleType: ''
        },
        ruleid: 135,
        ruleName: "Lab",
        rulePayValue: 35,
        ruleTypeName: "RULE",
        servicepaymentid: "52853a1b-df0e-4c14-84f2-0aa5b39e0791",
        total: "7000",
        units: 200,
        variance: 35
    }, {
        acknowledged: false,
        actualPay: 20.57,
        analysisNotes: "N/A",
        chargedAmount: 96.31,
        claimanalysisid: 10279,
        claimchargeid: "ccf66ffb-3e2a-4f89-9177-1be9475c585f",
        claimPaidamount: 10.57,
        comments: "",
        contract: null,
        contractid: null,
        drg: "",
        drgDescription: null,
        expectedPay: 72.0399,
        feeScheduleId: null,
        id: 56184,
        isEapgApplied: false,
        isOppsApplied: false,
        otherAllowable: null,
        patientResponsibility: 10,
        paymentfileid: "a2b39f09-3665-4722-8b0a-82400d7e08ac",
        payMethodName: "Percentage",
        principalDx: "M5450",
        principalDxDescription: null,
        pxcode: "97140:GP",
        pxcodeDescription: "MANUAL THERAPY 1/&gt; REGIONS",
        remitDate: "2023-08-30 00:00:00",
        revcode: "0420",
        revcodeDescription: "Physical Therapy",
        rule: {
            name: "All Other Outpatient",
            payMethod: '',
            payValue: 74.8,
            ruleType: ''
        },
        ruleid: 140,
        ruleName: "All Other Outpatient",
        rulePayValue: 74.8,
        ruleTypeName: "DEFAULT",
        servicepaymentid: "9c2265dd-8bdb-46f3-a24a-326f8ab35602",
        total: "7199.5",
        units: 350,
        variance: 51.47
    }],
    id: 11,
    name: "United Healthcare",
    terminationDate: "2023-05-31 00:00:00"
},

{
    contractState: {
        id: 3,
        name: "Finalized"
    },
    effectiveDate: "2021-07-01 00:00:00",
    claims: [{
        acknowledged: false,
        actualPay: 96,
        analysisNotes: "N/A",
        chargedAmount: 30.45,
        claimanalysisid: 9337,
        claimchargeid: "39393f46-bc75-463c-b41e-6db9b2b4790a",
        claimPaidamount: 0,
        comments: null,
        contract: null,
        contractid: null,
        drg: "",
        drgDescription: null,
        expectedPay: 27.405,
        feeScheduleId: null,
        id: 48674,
        isOppsApplied: false,
        otherAllowable: null,
        patientResponsibility: 0,
        paymentfileid: "3c7df0d6-8ac6-44b6-91b2-375e359e1274",
        payMethodName: "Percentage",
        principalDx: "Z01812",
        principalDxDescription: "Encounter for preprocedural laboratory examination",
        pxcode: "36415",
        pxcodeDescription: "ROUTINE VENIPUNCTURE",
        remitDate: "2022-02-08 00:00:00",
        revcode: "0300",
        revcodeDescription: "Laboratory",
        rule: {
            name: "Default",
            payMethod: "",
            payValue: 90,
            ruleType: ""
        },
        ruleid: 168,
        ruleName: "Default",
        rulePayValue: 90,
        ruleTypeName: "DEFAULT",
        servicepaymentid: "9823362f-7756-4152-9c09-8b520a9cb2ea",
        total: "48576",
        units: 506,
        variance: 27.4
    },{
            acknowledged: false,
            actualPay: 87.83,
            analysisNotes: "N/A",
            chargedAmount: 160,
            claimanalysisid: 10283,
            claimchargeid: "1cde3642-0f46-4f7f-9b3e-da84d805f940",
            claimPaidamount: 87.83,
            comments: "",
            contract: null,
            contractid: null,
            drg: "",
            drgDescription: null,
            expectedPay: 144,
            feeScheduleId: null,
            id: 56210,
            isOppsApplied: false,
            otherAllowable: null,
            patientResponsibility: 0,
            paymentfileid: "1994fae3-90f9-43b7-a1e0-0b3f9f9bff7a",
            payMethodName: "Percentage",
            principalDx: "K5909",
            principalDxDescription: "Other constipation",
            pxcode: "99213",
            pxcodeDescription: "OFFICE O/P EST LOW 20-29 MIN",
            remitDate: "2023-08-16 00:00:00",
            revcode: "",
            revcodeDescription: null,
            rule: {
                name: "Default",
                payMethod: "",
                payValue: 90,
                ruleType: ""
            },
            ruleid: 169,
            ruleName: "Default",
            rulePayValue: 90,
            ruleTypeName: "DEFAULT",
            servicepaymentid: "df45f269-6a4f-494f-b0ab-e9a05f9415cd",
            total: "39523.5",
            units: 450,
            variance: 56.17
    }],

    id: 9,
    name: "Humana PPO ERS OF TX",
    terminationDate: "2023-06-30 00:00:00"
}]