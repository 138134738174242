import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { set } from '../../../store/actions/pages';
import ContractsSelectionComponent from './ContractsSearchComponent';
import { createStringDataField } from '../../../util/format';
import { contractsSearch } from '../../../store/actions/contractsv2';



const DEFAULT_SORT = [{ id: 'name', orderBy: 'asc' }];



const selectFields = createSelector(
    () => [
        createStringDataField('name', 'Contract Name', { sortable: true }),
        createStringDataField('path', 'Path'),

    ]
);


const selectSort = createSelector(
    (state) => state.pages['contractsv2-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);





export const ContractsSearchComponent = connect(
    () => {

        const pageName = 'contractsv2-landing';

        const selectData = (state) => state.contractsv2.contractsSearch;

        return (state:any, props) => ({
            data: selectData(state),
            pageName: pageName,
            pending: state.contractsv2.getContractsV2Search.pending,
            fields: selectFields(state as never),
            order: state.pages['contractsv2-landing'].order,
            filter: state.pages['contractsv2-landing'].filter,
            setOrder: (value) => set('contractsv2-landing', 'order', value),
            setSort: (value) => set('contractsv2-landing', 'sort', value),
            sort: selectSort(state),
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            contractsSearch:contractsSearch,
        }, dispatch)
    })
)(injectIntl(ContractsSelectionComponent));