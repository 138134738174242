import { createReducer } from '../../util';
import * as actions from '../actions/hospitalServices';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    hospitalServicesImportsHistorical: [],
    masterHospitalServices: [],
    dependencyDialog: null, //{open:true}
    importDialog: null, //{open:true}
    importConfirmationDialog: null, //{open:true}
    lookupDialog: null, //{open:true}
    lookupImportConfirmationDialog: null, //{open:true}
    deleteBulk: {
        ...initialStatusState,
    },
    getHospitalServicesImport: {
        ...initialStatusState
    },
    getHospitalServicesImportHistorical: {
        ...initialStatusState
    },
    getHospitalServicesfromMaster: {
        ...initialStatusState,
    },
};


export default createReducer(initialState, {
    [actions.HOSPITAL_SERVICES_DELETE_BULK_PENDING]: (state) => ({
        ...state,
        deleteBulk: {
            pending: true,
        },
    }),
    [actions.HOSPITAL_SERVICES_DELETE_BULK_FULFILLED]: (state) => ({
        ...state,
        deleteBulk: {
            ...initialStatusState,
        }
    }),
    [actions.HOSPITAL_SERVICES_DELETE_BULK_REJECTED]: (state, errorMessage) => ({
        ...state,
        deleteBulk: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.HOSPITAL_SERVICES_IMPORT_PENDING]: (state) => ({
        ...state,
        getHospitalServicesImport: {
            pending: true,
        },
    }),
    [actions.HOSPITAL_SERVICES_IMPORT_FULFILLED]: (state) => ({
        ...state,
        getHospitalServicesImport: {
            ...initialStatusState,
        }
    }),
    [actions.HOSPITAL_SERVICES_IMPORT_REJECTED]: (state, errorMessage) => ({
        ...state,
        getHospitalServicesImport: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),
    [actions.HOSPITAL_SERVICES_IMPORT_RESET]: (state) => ({
        ...state,
        getHospitalServicesImportHistorical: {
            ...initialStatusState,
        },
    }),
    [actions.HOSPITAL_SERVICES_SET_DEPENDENCY_DIALOG]: (state, { dependencyDialog }) => ({
        ...state,
        dependencyDialog
    }),
    [actions.HOSPITAL_SERVICES_SET_IMPORT_CONFIRMATION_DIALOG]: (state, { importConfirmationDialog }) => ({
        ...state,
        importConfirmationDialog
    }),
    [actions.HOSPITAL_SERVICES_SET_IMPORT_DIALOG]: (state, { importDialog }) => ({
        ...state,
        importDialog
    }),
    [actions.HOSPITAL_SERVICES_IMPORT_HISTORICAL_PENDING]: (state) => ({
        ...state,
        getHospitalServicesImportHistorical: {
            pending: true,
        },
    }),
    [actions.HOSPITAL_SERVICES_IMPORT_HISTORICAL_FULFILLED]: (state, hospitalServicesImportsHistorical) => ({
        ...state,
        getHospitalServicesImportHistorical: {
            ...initialStatusState,
        },
        hospitalServicesImportsHistorical
    }),
    [actions.HOSPITAL_SERVICES_IMPORT_HISTORICAL_REJECTED]: (state, errorMessage) => ({
        ...state,
        getHospitalServicesImportHistorical: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),
    [actions.HOSPITAL_SERVICES_LOOKUP_MASTER_PENDING]: (state) => ({
        ...state,
        getHospitalServicesfromMaster: {
            pending: true,
        },
    }),
    [actions.HOSPITAL_SERVICES_LOOKUP_MASTER_FULFILLED]: (state, { results }) => ({
        ...state,
        getHospitalServicesfromMaster: {
            ...initialStatusState,
        },
        masterHospitalServices: results
    }),
    [actions.HOSPITAL_SERVICES_LOOKUP_MASTER_REJECTED]: (state, errorMessage) => ({
        ...state,
        getHospitalServicesfromMaster: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),
    [actions.HOSPITAL_SERVICES_LOOKUP_RESET]: (state) => ({
        ...state,
        getHospitalServicesfromMaster: {
            ...initialStatusState,
        },
    }),
    [actions.HOSPITAL_SERVICES_SET_LOOKUP_DIALOG]: (state, { lookupDialog }) => ({
        ...state,
        lookupDialog
    }),
    [actions.HOSPITAL_SERVICES_SET_LOOKUP_CONFIRMATION_DIALOG]: (state, { lookupImportConfirmationDialog }) => ({
        ...state,
        lookupImportConfirmationDialog
    }),
});