import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { CustomLabel, Loadable } from '../../../../common';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function DependencyDialog({intl, open, selectedItems, deleteMRFModifiersPending, handleRefresh, 
    actions: { setSelected, deleteMRFModifiers, setMRFModifiersDependencyDialog }
}) {

    const classes = useStyles();

    const handleDialogClose = () => {
        setMRFModifiersDependencyDialog(null);
        handleRefresh();
    }

    const handleDeleteCallback = () => {
        setMRFModifiersDependencyDialog(null);
        setSelected([]);
        handleRefresh();
    }

    const handleDelete = () => {
        deleteMRFModifiers(selectedItems, handleDeleteCallback);
    }


    return (
        <Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose}>
            <Loadable loading={deleteMRFModifiersPending}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Delete
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                    {selectedItems.length === 0 ? <Grid>
                        <Typography>No Modifiers selected. Please select atleast one for delete.</Typography>
                    </Grid> :
                        <Grid>
                            <Typography>Are you sure you want to delete selected Modifiers?</Typography>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button variant="contained" onClick={handleDialogClose} color="default">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDelete}
                        variant="contained"
                        disabled={selectedItems.length === 0 || deleteMRFModifiersPending}
                        color="primary"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Loadable >
        </Dialog>
    )
}

export default DependencyDialog
