import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { AppBar, Box, Button, Chip, Divider, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { CustomLabel, DataTable, Loadable } from '../../../common';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";

import SwipeableViews from "react-swipeable-views";
import { BackButton } from "../../../common/BackButton";
import { MODULE_TYPE_CONTRACT } from "../../../store/constants/contract";
import { equatorsMap } from "../../../store/constants/pages";
import { asDate } from "../../../util/date";
import { DocumentView } from './DocumentView';
import { ServicesView } from './ServicesView';
import { ContractsBreadCrumb } from "../ContractsBreadCrumb";

const useStyles = makeStyles(theme => {
  return {
    root: {
      color: '#48465b',
    },
    button: {
      margin: theme.spacing(1),
    },
    label: {
      fontSize: '1rem'
    },
    portlet: {
      'box-shadow': 'none',
      "& .kt-portlet__head": {
        borderColor: theme.palette.secondary.main,
      }
    },
    portletSection: {
      'box-shadow': 'none',
      "& .kt-portlet__head": {
        borderColor: theme.palette.secondary.main,
        paddingLeft: 0
      }
    },
    denialGrid: {
      maxHeight: '300px',
      overflowY: 'auto'
    },
    appbar: {
      'box-shadow': 'none',
      borderBottom: `1px solid #b2c3be`,
    },
    w90: {
      width: '90%'
    }
  }
});

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box py={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function ContractView({ contractsGetPending, contractsGetAlertUsersPending, payerInfoGetPending, contract,
  contractId, vendorContactFields, clientId, alertUsers, servicesTab, facilityXRef,directoryId,
  actions: { contractGetAlertUsers, getContract, getConfigs, updateServiceTab, getFacilities,getHierarchy }
}) {
  const classes = useStyles();

  const [tab, setTab] = React.useState(0);

  function usersCallback() {
    getContract(contractId);
  }

  useEffect(() => {
    getFacilities();
  }, [getFacilities])

  useEffect(() => {
    getHierarchy()
}, [getHierarchy])


  useEffect(() => {
    getConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId]);

  useEffect(() => {
    contractGetAlertUsers(clientId, usersCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractGetAlertUsers, contractId]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangeIndex = (index) => {
    setTab(index);
  };

  return (
    <>
      {directoryId && <ContractsBreadCrumb directoryId={directoryId} />
      }    <Loadable loading={payerInfoGetPending || contractsGetPending || contractsGetAlertUsersPending}>
      <Portlet className={classes.portlet}>
        <PortletHeader
          title={contract ? `Contract (${contract?.name})` : `Contract`}
          showTitle={true}
          toolbar={
            <PortletHeaderToolbar>
              <BackButton defaultRoute={`/cms/contract`} />
              <Button
                component={Link}
                to={`/cms/contract/edit/${contractId}${directoryId ? `/${directoryId}` : ''}`}
                variant="contained"
                color="default"
                className='ml-3'
              >
                Edit
                <EditIcon />
              </Button>
            </PortletHeaderToolbar>
          }
        />
        <PortletBody>

          <AppBar color="transparent" className={classes.appbar} position="static">
            <Tabs
              value={tab}
              onChange={handleChange}
              aria-label="Rules View Tab"
            >
              <Tab label="Contracted Services" {...a11yProps(0)} />
              <Tab label="Details" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          {contract &&
            <SwipeableViews
              axis={'x'}
              index={tab}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={tab} index={0} dir={'x'}>
                <Grid container className="">
                  <Grid item xs={12}>
                    {contract.isPayer && (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12} className="">
                            <Portlet className={classes.portletSection}>
                              {/* <PortletHeader
                                title="Contracted Services"
                              /> */}
                              <PortletBody className="px-0">
                                <ServicesView
                                  contractedService={contract.contractedService}
                                  contractId={contractId}
                                  value={servicesTab}
                                  updateServiceTab={updateServiceTab}
                                />
                              </PortletBody>
                            </Portlet>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={1} >
                <Grid className={`${classes.root}  pt-3`} container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <CustomLabel label="Name" data={contract.name} />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomLabel label="Effective Date" data={asDate(contract.effectiveDate)} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <CustomLabel label="Termination Date" data={asDate(contract.terminationDate)} />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomLabel label="Type" data={contract.isPayer ? 'Payer' : 'Vendor'} />
                      </Grid>
                      {contract?.expectedDaysToPay && <Grid item xs={6}>
                        <CustomLabel label="Expected Days to Pay" data={contract?.expectedDaysToPay} />
                      </Grid>}
                      <Grid item xs={6}>
                        <CustomLabel label="Contract State" data={contract?.contractState?.name ?? ''} />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomLabel label="Finalized Date" data={asDate(contract?.finalizedDate, 'date-time') ?? 'N/A'} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <CustomLabel label="Description" data={contract.description} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.label} variant="subtitle2" display="inline" >Alert Receipients : </Typography>
                    <Typography variant="subtitle1" display="inline">
                      {alertUsers && alertUsers.map((user, index) => (
                        <React.Fragment key={index}>
                          <Chip title={user.email} variant="outlined" size="small" color="primary" label={user.name} /> &nbsp;
                        </React.Fragment>
                      ))}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className="mt-2">
                    <Typography className={classes.label} variant="subtitle2" display="inline" >Facilities : </Typography>
                    <Typography variant="subtitle1" display="inline">
                      {facilityXRef && facilityXRef.map((user, index) => (
                        <React.Fragment key={index}>
                          <Chip title={user.email} variant="outlined" size="small" color="primary" label={user.name} /> &nbsp;
                        </React.Fragment>
                      ))}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container>
                      {contract && contract.contractSelectionCriteriaXref && contract && contract.contractSelectionCriteriaXref.length > 0 &&
                        <Grid item xs={6}>
                          <Portlet className={`${classes.portletSection} ${classes.w90}`}>
                            <PortletHeader
                              title="Contract Selection Criteria"
                              showTitle={true}
                              toolbar={<></>}
                            />
                            <PortletBody className="pl-0 pb-0">
                              <Grid item xs={12}>
                                <Grid container>
                                  {contract && contract.contractSelectionCriteriaXref && contract?.contractSelectionCriteriaXref.map(item =>
                                    <Grid item key={item.id} xs={6}>
                                      <CustomLabel label={item.contractSelectionCriteria?.name} data={`${equatorsMap[item.equator]} '${item.value}'`} />
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </PortletBody>
                          </Portlet>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                  <Grid container>
                    {contract && contract.drgconfigurationToContractXref && contract.drgconfigurationToContractXref.length > 0 &&
                      <Grid item xs={6}>
                        <Portlet className={`${classes.portletSection} ${classes.w90}`}>
                          <PortletHeader
                            title="IPPS Configurations"
                            showTitle={true}
                            toolbar={<></>}
                          />
                          <PortletBody className="pl-0 pb-0">
                            <Grid item xs={12}>
                              <Grid container>
                                {contract && contract.drgconfigurationToContractXref && contract?.drgconfigurationToContractXref.map(item =>
                                  <Grid item key={item.id} xs={12}>
                                    <CustomLabel
                                      label={item.drgConfiguration?.name}
                                      data={`(${asDate(item.drgConfiguration?.effectiveFrom)} to ${asDate(item.drgConfiguration?.effectiveTo)}) -- ${asDate(item.effectiveFrom)} -- Percentage: ${item.percentage}`} />
                                  </Grid>
                                )}
                                {contract && contract.drgconfigurationToContractXref.length === 0 && <Typography variant="subtitle2">No IPPS configuration found!</Typography>}
                              </Grid>
                            </Grid>
                          </PortletBody>
                        </Portlet>
                      </Grid>
                    }
                    {contract && contract.contractOppsconfigurationXref && contract.contractOppsconfigurationXref.length > 0 &&
                      <Grid item xs={6}>
                        <Portlet className={`${classes.portletSection} ${classes.w90}`}>
                          <PortletHeader
                            title="OPPS Configurations"
                            showTitle={true}
                            toolbar={<></>}
                          />
                          <PortletBody className="pl-0 pb-0">
                            <Grid item xs={12}>
                              <Grid container>
                                {contract && contract.contractOppsconfigurationXref && contract?.contractOppsconfigurationXref.map(item =>
                                  <Grid item key={item.id} xs={12}>
                                    <CustomLabel label={`${item.oppsConfiguration?.name}`} data={`(${asDate(item.oppsConfiguration?.effectiveFrom)} to ${asDate(item.oppsConfiguration?.effectiveTill)}) -- ${asDate(item.effectiveFrom)}  -- Percentage: ${item.percentage}`} />
                                  </Grid>
                                )}
                                {contract && contract.contractOppsconfigurationXref.length === 0 && <Typography variant="subtitle2">No OPPS configuration found!</Typography>}
                              </Grid>
                            </Grid>
                          </PortletBody>
                        </Portlet>
                      </Grid>
                    }
                    {contract && contract.contractEapgconfigurationXref && contract.contractEapgconfigurationXref.length > 0 &&
                      <Grid item xs={6}>
                        <Portlet className={`${classes.portletSection} ${classes.w90}`}>
                          <PortletHeader
                            title="EAPG Configurations"
                            showTitle={true}
                            toolbar={<></>}
                          />
                          <PortletBody className="pl-0 pb-0">
                            <Grid item xs={12}>
                              <Grid container>
                                {contract && contract.contractEapgconfigurationXref && contract?.contractEapgconfigurationXref.map(item =>
                                  <Grid item key={item.id} xs={12}>
                                    <CustomLabel label={`${item.eapgConfiguration?.name}`} data={`(${asDate(item.eapgConfiguration?.effectiveFrom)} to ${asDate(item.eapgConfiguration?.effectiveTill)}) -- ${asDate(item.effectiveFrom)}  -- Percentage: ${item.percentage}`} />
                                  </Grid>
                                )}
                                {contract && contract.contractEapgconfigurationXref.length === 0 && <Typography variant="subtitle2">No EAPG configuration found!</Typography>}
                              </Grid>
                            </Grid>
                          </PortletBody>
                        </Portlet>
                      </Grid>
                    }
                  </Grid>
                  {contract && contract.contractOtherAllowableXref && contract.contractOtherAllowableXref.filter(x => x.isPR === true).length > 0 &&
                    <Grid item xs={12} className="mt-3">
                      <Portlet className={classes.portletSection}>
                        <PortletHeader
                          title="Patient Responsibility"
                          showTitle={true}
                          toolbar={<></>}
                        />
                        <PortletBody className="pl-0 pb-0">
                          <Grid className={classes.denialGrid}>
                            <ul>
                              {contract.contractOtherAllowableXref.filter(x => x.isPR === true).sort((a, b) => a?.casCode?.code.localeCompare(b?.casCode?.code)).map((option, index) => <li key={option.id}>
                                {`${option?.casCode?.code} - ${option?.casCode?.shortdesc}`}
                              </li>
                              )}
                            </ul>
                          </Grid>
                        </PortletBody>
                      </Portlet>
                    </Grid>
                  }
                  {contract && contract.contractDenialCascodeXref && contract.contractDenialCascodeXref.length > 0 &&
                    <Grid item xs={12}>
                      <Portlet className={classes.portletSection}>
                        <PortletHeader
                          title="Contract Denial Code Selection"
                          showTitle={true}
                          toolbar={<></>}
                        />
                        <PortletBody className="pl-0 pb-0">
                          <Grid className={classes.denialGrid}>
                            <ul>
                              {contract.contractDenialCascodeXref.sort((a, b) => a?.casCode?.code?.localeCompare(b?.casCode?.code, 'en', { numeric: true })).map((option, index) => <li key={option.id}>
                                {`${option?.casCode?.code} - ${option?.casCode?.shortdesc}`}
                              </li>
                              )}
                            </ul>
                          </Grid>
                        </PortletBody>
                      </Portlet>
                    </Grid>
                  }
                  {contract && contract.contractOtherAllowableXref && contract.contractOtherAllowableXref.filter(x => x.isPR === false).length > 0 &&
                    <Grid item xs={12}>
                      <Portlet className={classes.portletSection}>
                        <PortletHeader
                          title="Other Allowable"
                          showTitle={true}
                          toolbar={<></>}
                        />
                        <PortletBody className="pl-0 pb-0">
                          <Grid className={classes.denialGrid}>
                            <ul>
                              {contract.contractOtherAllowableXref.filter(x => x.isPR === false).map((option, index) => <li key={option.id}>
                                {`${option?.casCode?.code} - ${option?.casCode?.shortdesc}`}
                              </li>
                              )}
                            </ul>
                          </Grid>
                        </PortletBody>
                      </Portlet>
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Portlet className={classes.portletSection}>
                          <PortletHeader
                            title="Terms"
                            showTitle={true}
                            toolbar={<></>}
                          />
                          <PortletBody className="pl-0 pb-0">
                            {contract && contract.terms.map(({ renew, alert, notification }, index) => (
                              <React.Fragment key={index} >
                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item xs={6}>
                                      <CustomLabel label="Renew" data={asDate(renew)} />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <CustomLabel label="Alert" data={asDate(alert)} />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item xs={6}>
                                      <CustomLabel label="Nofification" data={notification ? 'On' : 'Off'} />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {index !== (contract.terms.length - 1) && (
                                  <Grid item xs={12} className="my-3">
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Divider />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                              </React.Fragment>
                            ))}
                          </PortletBody>
                        </Portlet>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="">
                        <DocumentView
                          data={contract.documentMetadata}
                          contractId={contractId}
                          module={MODULE_TYPE_CONTRACT}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="">
                        <Portlet className={classes.portletSection}>
                          <PortletHeader
                            title="Payer Contacts"
                            showTitle={true}
                            toolbar={<></>}
                          />
                          <PortletBody className="pl-0">
                            <DataTable
                              fields={vendorContactFields}
                              data={contract.vendorContacts}
                            />
                          </PortletBody>
                        </Portlet>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
            </SwipeableViews>
          }
        </PortletBody>
      </Portlet>
    </Loadable >  
    </>

  );
}
