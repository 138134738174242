import { createReducer } from '../../util';
import * as actions from '../actions/modifiers';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    data: [],
    payMethods: [],
    importedMRFModifiers: [],
    masterModifiers: [],
    dependencyDialog: null, //{open:true}
    importDialog: null, //{open:true}
    importConfirmationDialog: null, //{open:true}
    lookupDialog: null, //{open:true}
    lookupConfirmationDialog: null, //{open:true}
    editDialog: null, //{open:true,modifier:obj,bulk:true}
    list: {
        ...initialStatusState,
    },
    create: {
        ...initialStatusState,
    },
    update: {
        ...initialStatusState,
    },
    get: {
        ...initialStatusState,
    },
    delete: {
        ...initialStatusState,
    },
    getPayMethods: {
        ...initialStatusState,
    },
    importMRFModifiers: {
        ...initialStatusState
    },
    checkExisting: {
        ...initialStatusState
    },
    getModifiersfromMaster: {
        ...initialStatusState,
    },
    importMRFModifiersFromMaster: {
        ...initialStatusState
    },
    deleteMRFModifier: {
        ...initialStatusState,
    },
    createMRFModifier: {
        ...initialStatusState,
    },
    updateMRFModifier: {
        ...initialStatusState,
    },
};

export default createReducer(initialState, {
    [actions.MODIFIERS_LANDING_PENDING]: (state) => ({
        ...state,
        list: {
            pending: true,
        },
        data: []
    }),
    [actions.MODIFIERS_LANDING_FULFILLED]: (state, npiRoles) => ({
        ...state,
        list: {
            ...initialStatusState,
        },
        data: npiRoles,
    }),
    [actions.MODIFIERS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        list: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.MODIFIERS_CREATE_PENDING]: (state) => ({
        ...state,
        create: {
            pending: true,
        },
    }),
    [actions.MODIFIERS_CREATE_FULFILLED]: (state) => ({
        ...state,
        create: {
            ...initialStatusState,
        },
    }),
    [actions.MODIFIERS_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        create: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.MODIFIERS_UPDATE_PENDING]: (state) => ({
        ...state,
        update: {
            pending: true,
        },
    }),
    [actions.MODIFIERS_UPDATE_FULFILLED]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        },
    }),
    [actions.MODIFIERS_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        update: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.MODIFIERS_DELETE_PENDING]: (state) => ({
        ...state,
        delete: {
            pending: true,
        },
    }),
    [actions.MODIFIERS_DELETE_FULFILLED]: (state) => ({
        ...state,
        delete: {
            ...initialStatusState,
        },
    }),
    [actions.MODIFIERS_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        delete: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.MODIFIERS_PAYMETHODS_GET_PENDING]: (state) => ({
        ...state,
        getPayMethods: {
            pending: true,
        },
    }),
    [actions.MODIFIERS_PAYMETHODS_GET_FULFILLED]: (state, payMethods) => ({
        ...state,
        getPayMethods: {
            ...initialStatusState,
        },
        payMethods
    }),
    [actions.MODIFIERS_PAYMETHODS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        getPayMethods: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.MODIFIERS_CREATE_UPDATE_RESET]: (state) => ({
        ...state,
        update: {
            ...initialStatusState,
        },
        create: {
            ...initialStatusState,
        },
    }),

    [actions.MODIFIERS_RESET]: (state) => ({
        ...state,
        data: []
    }),

    [actions.MRF_MODIFIERS_CHECK_EXISTING_PENDING]: (state) => ({
        ...state,
        checkExisting: {
            pending: true,
        },
    }),
    [actions.MRF_MODIFIERS_CHECK_EXISTING_FULFILLED]: (state) => ({
        ...state,
        checkExisting: {
            ...initialStatusState,
        }
    }),
    [actions.MRF_MODIFIERS_CHECK_EXISTING_REJECTED]: (state, errorMessage) => ({
        ...state,
        checkExisting: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),

    [actions.MRF_MODIFIERS_IMPORT_PENDING]: (state) => ({
        ...state,
        importMRFModifiers: {
            pending: true,
        },
    }),
    [actions.MRF_MODIFIERS_IMPORT_FULFILLED]: (state, importedMRFModifiers) => ({
        ...state,
        importMRFModifiers: {
            ...initialStatusState,
        },
        importedMRFModifiers
    }),
    [actions.MRF_MODIFIERS_IMPORT_REJECTED]: (state, errorMessage) => ({
        ...state,
        importMRFModifiers: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),
    [actions.MRF_MODIFIERS_IMPORT_RESET]: (state) => ({
        ...state,
        importMRFModifiers: {
            ...initialStatusState,
        },
    }),

    [actions.MRF_MODIFIERS_SET_DEPENDENCY_DIALOG]: (state, { dependencyDialog }) => ({
        ...state,
        dependencyDialog
    }),

    [actions.MRF_MODIFIERS_SET_IMPORT_DIALOG]: (state, { importDialog }) => ({
        ...state,
        importDialog
    }),
    [actions.MRF_MODIFIERS_SET_IMPORT_CONFIRMATION_DIALOG]: (state, { importConfirmationDialog }) => ({
        ...state,
        importConfirmationDialog
    }),

    [actions.MRF_MODIFIERS_CREATE_PENDING]: (state) => ({
        ...state,
        createMRFModifier: {
            pending: true,
        },
    }),
    [actions.MRF_MODIFIERS_CREATE_FULFILLED]: (state) => ({
        ...state,
        createMRFModifier: {
            ...initialStatusState,
        },
    }),
    [actions.MRF_MODIFIERS_CREATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        createMRFModifier: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.MRF_MODIFIERS_UPDATE_PENDING]: (state) => ({
        ...state,
        updateMRFModifier: {
            pending: true,
        },
    }),
    [actions.MRF_MODIFIERS_UPDATE_FULFILLED]: (state) => ({
        ...state,
        updateMRFModifier: {
            ...initialStatusState,
        },
    }),
    [actions.MRF_MODIFIERS_UPDATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateMRFModifier: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.MRF_MODIFIERS_DELETE_PENDING]: (state) => ({
        ...state,
        deleteMRFModifier: {
            pending: true,
        },
    }),
    [actions.MRF_MODIFIERS_DELETE_FULFILLED]: (state) => ({
        ...state,
        deleteMRFModifier: {
            ...initialStatusState,
        }
    }),
    [actions.MRF_MODIFIERS_DELETE_REJECTED]: (state, errorMessage) => ({
        ...state,
        deleteMRFModifier: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }
    }),
    [actions.MRF_MODIFIERS_SET_EDIT_DIALOG]: (state, { editDialog }) => ({
        ...state,
        editDialog
    }),

});
