import { FormControl, Grid,InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import { PagedTable } from '../../common';
import {
    Portlet,
    PortletBody,
    PortletHeader
} from "../../partials/content/Portlet";

const DEFAULT_SORT = [{ id: 'payerName', direction: 'asc' }];

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },

    icon: {
        fontSize: 17,
        marginTop: 1,
    },
    filterFormControl: {
        margin: theme.spacing(1),
        minWidth: 100,
        maxWidth: 150,
    },
}));

export default function PayerStatisticsPage({
    intl,
    tab, payerListPending, payerData, remitPayerData, payerFields, remitPayerFields, payerContracts, userRole,
    clientFilesData,
    clientFilesFields,clients,
    clientFilesPending,
    selectedClient,
    actions: {
        payersStatsLanding,
        clientFilesStatsLanding,
        remitPayersStatsLanding,
        searchGetByName,
        searchReset,
        resetPagedTable
    }
}) {
    const [refresh, setRefresh] = useState(Promise.resolve());
    const [remitRefresh, setRemitRefresh] = useState(Promise.resolve());
    const [clientFilesRefresh, setClientFilesRefresh] = useState(Promise.resolve());
    const [clientId, setClientId] = useState(null);


    const classes = useStyles();

    useEffect(() => {
        let pageName = {
            name: 'payers-stats-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name);
        }
    }, [searchGetByName]);

    useEffect(() => {
        let pageName = {
            name: 'remit-payers-stats-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name);
        }
    }, [searchGetByName]);

    useEffect(() => {
        if (tab === 4 && clientId) {
            setClientFilesRefresh(Promise.resolve())
        }
    }, [clientId]);
    

    function handleLoad(sort, filter, pagination) {
        payersStatsLanding(pagination, sort, filter);
    }

    function handleRemitLoad(sort, filter, pagination) {
        remitPayersStatsLanding(pagination, sort, filter);
    }

    function handleClientFilesLoad(sort, filter, pagination) {
        clientFilesStatsLanding(pagination, sort, filter,clientId || selectedClient);
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    function handleRemitRefresh() {
        setRemitRefresh(Promise.resolve());
    }
    function handleClientFilesRefresh() {
        setClientFilesRefresh(Promise.resolve());
    }

    const handleFilterChange = (e) => {
        setClientId(e.target.value)
    }

    return (
        <>
            <Helmet title="Payers Statistics" />
            <Portlet>
                <PortletHeader
                    title="Statistics"
                    name={
                        tab === 1
                            ? "payers-stats-landing"
                            : tab === 4 ? "client-files-stats-landing" : "remit-payers-stats-landing"
                    }
                    includeAdvanceFilters={tab === 4}
                    toolbar={
                        tab === 4 ? (
                            <Grid>
                                <FormControl
                                    className={classes.filterFormControl}
                                >
                                    <InputLabel>Client</InputLabel>
                                    <Select
                                        value={
                                            clientId ||
                                            selectedClient
                                        }
                                        onChange={handleFilterChange}
                                        name="clientId"
                                    >
                                        {clients.map((client) => (
                                            <MenuItem
                                                key={client.clientId}
                                                value={client.clientId}
                                            >
                                                {client.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ) : undefined
                    }
                />
                <PortletBody>
                    {tab === 1 && (
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <PagedTable
                                        name="payers-stats-landing"
                                        fields={payerFields}
                                        data={payerData}
                                        onLoad={handleLoad}
                                        refresh={refresh}
                                        defaultSort={DEFAULT_SORT}
                                        title="Claim Payer Stats"
                                        tableLevelLoading={payerListPending}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {tab === 2 && (
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <PagedTable
                                        name="remit-payers-stats-landing"
                                        fields={remitPayerFields}
                                        data={remitPayerData}
                                        onLoad={handleRemitLoad}
                                        refresh={remitRefresh}
                                        defaultSort={DEFAULT_SORT}
                                        title="Remit Payer Stats"
                                        tableLevelLoading={payerListPending}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {tab === 4 && (
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <PagedTable
                                        name="client-files-stats-landing"
                                        fields={clientFilesFields}
                                        data={clientFilesData}
                                        onLoad={handleClientFilesLoad}
                                        refresh={clientFilesRefresh}
                                        defaultSort={[]}
                                        title="Client Files Stats"
                                        tableLevelLoading={clientFilesPending}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </PortletBody>
            </Portlet>
        </>
    );
}