import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import ImportConfirmationDialogComponent from './ImportConfirmationDialog';
import { setMRFModifiersImportConfirmationDialog, setMRFModifiersImportDialog } from '../../../../store/actions/modifiers';

export const ImportConfirmationDialog = connect(
    () => {
        const selectImportConfirmationDialog = (state) => state.modifiers.importConfirmationDialog;

        const selectOpen = createSelector(
            selectImportConfirmationDialog,
            (importConfirmationDialog) => {
                if (importConfirmationDialog && importConfirmationDialog?.open) {
                    return true
                }
                return false
            }
        );

        const selectIsDuplicate = createSelector(
            selectImportConfirmationDialog,
            (importConfirmationDialog) => {
                if (importConfirmationDialog && importConfirmationDialog?.isDuplicate) {
                    return true
                }
                return false
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            isDuplicate: selectIsDuplicate(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setMRFModifiersImportConfirmationDialog: setMRFModifiersImportConfirmationDialog,
            setMRFModifiersImportDialog: setMRFModifiersImportDialog
        }, dispatch)
    })
)(injectIntl(ImportConfirmationDialogComponent));