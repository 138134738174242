export interface RevCodeGroup {
    category: string;
    revCodes: string;
    description: string;
}

const unknown : RevCodeGroup = {
    category: "unknown",
    revCodes: "unknown",
    description: "Unknown"
}

export function getRevCodeGroup( revCode : string ) : RevCodeGroup {
    const subgroupCode = revCode.substring(0,3);
    let subgroup = revCodeMap[ subgroupCode ];

    if( !subgroup ){
        subgroup = revCodeMap[ subgroupCode ] = {
            category: "all",
            revCodes: subgroupCode,
            description: "Unknown"
        };
    }

    return subgroup;
}

const revCodeGrouping = {
    "inpatient": {
        "002X": "Health Insurance Prospective Payment System (HIPPS)",
        "065X": "Hospice Service",
        "069X": "Pre-Hospice/Palliative Care Services",
        "080X": "Inpatient Renal Dialysis"
    },
    "outpatient": {
        "050X": "Outpatient Services",
        "067X": "Outpatient Special Residence Charges",
        "082X": "Hemodialysis - Outpatient or Home",
        "083X": "Peritoneal Dialysis - Outpatient or Home",
        "084X": "Continuous Ambulatory Peritoneal Dialysis (CAPD)- Outpatient or Home",
        "085X": "Continuous Cycling Peritoneal Dialysis (CCPD) - Outpatient or Home",
        "090X": "Behavioral Health Treatments/Services (also see 091X, and extension of 090X)",
        "098X": "Professional Fees (Extension of 096X and 097X)"
    },
    "all": {
        "001X": "Payer Code",
        "010X": "All-inclusive Rate",
        "011X": "Room and Board Private (one bed)",
        "012X": "Room and Board Semi-private (two beds)",
        "013X": "Room and Board (3 and 4 beds)",
        "014X": "Room and Board Deluxe Private",
        "015X": "Room and Board Ward",
        "016X": "Other Room and Board",
        "017X": "Nursery",
        "018X": "Leave of Absence",
        "019X": "Subacute Care",
        "020X": "Intensive Care Unit",
        "021X": "Coronary Care Unit",
        "022X": "Special Charges",
        "023X": "Incremental Nursing Charge",
        "024X": "All-inclusive Ancillary",
        "025X": "Pharmacy (Also see 063X, an extension of 250X)",
        "026X": "IV Therapy",
        "027X": "Medical/Surgical Supplies and Devices (Also see 062X, an extension of 027X)",
        "028X": "Oncology",
        "029X": "Durable Medical Equipment (Other than Renal)",
        "030X": "Laboratory",
        "031X": "Laboratory Pathology",
        "032X": "Radiology Diagnostic",
        "033X": "Radiology Therapeutic and/of Chemotherapy Administration",
        "034X": "Nuclear Medicine",
        "035X": "CT Scan",
        "036X": "Operating Room Services",
        "037X": "Anesthesia",
        "038X": "Blood and Blood Products",
        "039X": "Administration, Processing and Storage for Blood and Blood Components",
        "040X": "Other Imaging Services",
        "041X": "Respiratory Services",
        "042X": "Physical Therapy",
        "043X": "Occupational Therapy",
        "044X": "Speech Therapy Language Pathology",
        "045X": "Emergency Room",
        "046X": "Pulmonary Function",
        "047X": "Audiology",
        "048X": "Cardiology",
        "049X": "Ambulatory Surgical Care",
        "051X": "Clinic",
        "052X": "Freestanding Clinic",
        "053X": "Osteopathic Services",
        "054X": "Ambulance",
        "055X": "Skilled Nursing",
        "056X": "Home Health Medical Social Services",
        "057X": "Home Health Aide",
        "058X": "Home Health Other Visits",
        "059X": "Home Health Units of Service",
        "060X": "Home Health Oxygen",
        "061X": "Magnetic Resonance Technology (MRT)",
        "062X": "Medical/Surgical Supplies - Extension of 027X",
        "063X": "Pharmacy - Extension of 025X",
        "064X": "Home IV Therapy Services",
        "066X": "Respite Care",
        "068X": "Trauma Response",
        "070X": "Cast Room",
        "071X": "Recovery Room",
        "072X": "Labor Room/Delivery",
        "073X": "EKG/ECG Electrocardiogram",
        "074X": "EEG Electroencephalogram",
        "075X": "Gastrointestinal Services",
        "076X": "Specialty Services",
        "077X": "Preventive Services",
        "078X": "Telemedicine",
        "079X": "Extra-Corporeal Shock Wave Therapy (formerly Lithotripsy)",
        "081X": "Acquisition of Body Components",
        "086X": "Magnetoencephalography",
        "087X": "Cell/Gene Therapy",
        "088X": "Miscellaneous Dialysis",
        "089X": "Pharmacy - Extension of 025X and 063X",
        "091X": "Behavioral Health Treatments/Services - Extension of 090X",
        "092X": "Other Diagnostic Services",
        "093X": "Medical Rehabilitation Day Program",
        "094X": "Other Therapeutic Services - See also 095X",
        "095X": "Other Therapeutic Services (Extension of 094X)",
        "096X": "Professional Fees",
        "097x": "Professional Fees (Extension of 096X)",
        "099X": "Patient Convenience Items",
        "100X": "Behavioral Health Accommodations",
        "210X": "Alternative Therapy Services",
        "310X": "Adult Care"
    }
}

const revCodeMap : {
    [revCode: string] : RevCodeGroup
} = {};

for( let group in revCodeGrouping ){
    for( let revCode in revCodeGrouping[group] ){
        revCodeMap[revCode.substring(0,3)] = {
            category: group,
            revCodes: revCode,
            description: revCodeGrouping[group][revCode]
        };
    }
}