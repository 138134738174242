
export const MODIFIERS_LANDING = 'MODIFIERS_LANDING';
export const MODIFIERS_LANDING_PENDING = 'MODIFIERS_LANDING_PENDING';
export const MODIFIERS_LANDING_FULFILLED = 'MODIFIERS_LANDING_FULFILLED';
export const MODIFIERS_LANDING_REJECTED = 'MODIFIERS_LANDING_REJECTED';

export const MODIFIERS_CREATE = 'MODIFIERS_CREATE';
export const MODIFIERS_CREATE_PENDING = 'MODIFIERS_CREATE_PENDING';
export const MODIFIERS_CREATE_FULFILLED = 'MODIFIERS_CREATE_FULFILLED';
export const MODIFIERS_CREATE_REJECTED = 'MODIFIERS_CREATE_REJECTED';

export const MODIFIERS_UPDATE = 'MODIFIERS_UPDATE';
export const MODIFIERS_UPDATE_PENDING = 'MODIFIERS_UPDATE_PENDING';
export const MODIFIERS_UPDATE_FULFILLED = 'MODIFIERS_UPDATE_FULFILLED';
export const MODIFIERS_UPDATE_REJECTED = 'MODIFIERS_UPDATE_REJECTED';

export const MODIFIERS_DELETE = 'MODIFIERS_DELETE';
export const MODIFIERS_DELETE_PENDING = 'MODIFIERS_DELETE_PENDING';
export const MODIFIERS_DELETE_FULFILLED = 'MODIFIERS_DELETE_FULFILLED';
export const MODIFIERS_DELETE_REJECTED = 'MODIFIERS_DELETE_REJECTED';

export const MODIFIERS_PAYMETHODS_GET = 'MODIFIERS_PAYMETHODS_GET';
export const MODIFIERS_PAYMETHODS_GET_PENDING = 'MODIFIERS_PAYMETHODS_GET_PENDING';
export const MODIFIERS_PAYMETHODS_GET_FULFILLED = 'MODIFIERS_PAYMETHODS_GET_FULFILLED';
export const MODIFIERS_PAYMETHODS_GET_REJECTED = 'MODIFIERS_PAYMETHODS_GET_REJECTED';

export const MODIFIERS_CREATE_UPDATE_RESET = 'MODIFIERS_CREATE_UPDATE_RESET';
export const MODIFIERS_RESET = 'MODIFIERS_RESET';

export const MRF_MODIFIERS_CHECK_EXISTING = 'MRF_MODIFIERS_CHECK_EXISTING';
export const MRF_MODIFIERS_CHECK_EXISTING_PENDING = 'MRF_MODIFIERS_CHECK_EXISTING_PENDING';
export const MRF_MODIFIERS_CHECK_EXISTING_FULFILLED = 'MRF_MODIFIERS_CHECK_EXISTING_FULFILLED';
export const MRF_MODIFIERS_CHECK_EXISTING_REJECTED = 'MRF_MODIFIERS_CHECK_EXISTING_REJECTED';

export const MRF_MODIFIERS_IMPORT = 'MRF_MODIFIERS_IMPORT';
export const MRF_MODIFIERS_IMPORT_PENDING = 'MRF_MODIFIERS_IMPORT_PENDING';
export const MRF_MODIFIERS_IMPORT_FULFILLED = 'MRF_MODIFIERS_IMPORT_FULFILLED';
export const MRF_MODIFIERS_IMPORT_REJECTED = 'MRF_MODIFIERS_IMPORT_REJECTED';
export const MRF_MODIFIERS_IMPORT_RESET = 'MRF_MODIFIERS_IMPORT_RESET';

export const MRF_MODIFIERS_CREATE = 'MRF_MODIFIERS_CREATE';
export const MRF_MODIFIERS_CREATE_PENDING = 'MRF_MODIFIERS_CREATE_PENDING';
export const MRF_MODIFIERS_CREATE_FULFILLED = 'MRF_MODIFIERS_CREATE_FULFILLED';
export const MRF_MODIFIERS_CREATE_REJECTED = 'MRF_MODIFIERS_CREATE_REJECTED';

export const MRF_MODIFIERS_UPDATE = 'MRF_MODIFIERS_UPDATE';
export const MRF_MODIFIERS_UPDATE_PENDING = 'MRF_MODIFIERS_UPDATE_PENDING';
export const MRF_MODIFIERS_UPDATE_FULFILLED = 'MRF_MODIFIERS_UPDATE_FULFILLED';
export const MRF_MODIFIERS_UPDATE_REJECTED = 'MRF_MODIFIERS_UPDATE_REJECTED';

export const MRF_MODIFIERS_DELETE = 'MRF_MODIFIERS_DELETE';
export const MRF_MODIFIERS_DELETE_PENDING = 'MRF_MODIFIERS_DELETE_PENDING';
export const MRF_MODIFIERS_DELETE_FULFILLED = 'MRF_MODIFIERS_DELETE_FULFILLED';
export const MRF_MODIFIERS_DELETE_REJECTED = 'MRF_MODIFIERS_DELETE_REJECTED';

export const MRF_MODIFIERS_SET_DEPENDENCY_DIALOG = 'MRF_MODIFIERS_SET_DEPENDENCY_DIALOG';
export const MRF_MODIFIERS_SET_IMPORT_DIALOG = 'MRF_MODIFIERS_SET_IMPORT_DIALOG';
export const MRF_MODIFIERS_SET_IMPORT_CONFIRMATION_DIALOG = 'MRF_MODIFIERS_SET_IMPORT_CONFIRMATION_DIALOG';
export const MRF_MODIFIERS_CREATE_UPDATE_RESET = 'MRF_MODIFIERS_CREATE_UPDATE_RESET';
export const MRF_MODIFIERS_SET_EDIT_DIALOG = 'MRF_MODIFIERS_SET_EDIT_DIALOG';

export const modifiersLanding = () => ({
    type: MODIFIERS_LANDING
});

export const createModifier = (values, callback, shouldReloadGrid) => ({
    type: MODIFIERS_CREATE,
    payload: { values, callback, shouldReloadGrid }
});

export const updateModifier = (values, callback, shouldReloadGrid) => ({
    type: MODIFIERS_UPDATE,
    payload: { values, callback, shouldReloadGrid }
});

export const deleteModifier = (modifierId, callback) => ({
    type: MODIFIERS_DELETE,
    payload: { modifierId, callback }
});

export const createEditReset = () => ({
    type: MODIFIERS_CREATE_UPDATE_RESET
});

export const modifierReset = () => ({
    type: MODIFIERS_RESET
});

export const getModifiersPayMethods = () => ({
    type: MODIFIERS_PAYMETHODS_GET
});

export const checkExistingMRFModifiers = (callback) => ({
    type: MRF_MODIFIERS_CHECK_EXISTING,
    payload: { callback }
});

export const importMRFModifiers = (options, callback) => ({
    type: MRF_MODIFIERS_IMPORT,
    payload: { options, callback }
});

export const importMRFModifiersErrorReset = () => ({
    type: MRF_MODIFIERS_IMPORT_RESET
});

export const setMRFModifiersImportDialog = (importDialog) => ({
    type: MRF_MODIFIERS_SET_IMPORT_DIALOG,
    payload: { importDialog }
});

export const setMRFModifiersImportConfirmationDialog = (importConfirmationDialog) => ({
    type: MRF_MODIFIERS_SET_IMPORT_CONFIRMATION_DIALOG,
    payload: { importConfirmationDialog }
});

export const createMRFModifier = (values, callback, shouldReloadGrid) => ({
    type: MRF_MODIFIERS_CREATE,
    payload: { values, callback, shouldReloadGrid }
});

export const updateMRFModifier = (values, callback, shouldReloadGrid) => ({
    type: MRF_MODIFIERS_UPDATE,
    payload: { values, callback, shouldReloadGrid }
});

export const createEditMRFModifierReset = () => ({
    type: MRF_MODIFIERS_CREATE_UPDATE_RESET
});

export const setMRFModifiersEditDialog = (editDialog) => ({
    type: MRF_MODIFIERS_SET_EDIT_DIALOG,
    payload: { editDialog }
});

export const deleteMRFModifier = (modifiers, callback, shouldReloadGrid) => ({
    type: MRF_MODIFIERS_DELETE,
    payload: { modifiers, callback, shouldReloadGrid },
});

export const setMRFModifiersDependencyDialog = (dependencyDialog) => ({
    type: MRF_MODIFIERS_SET_DEPENDENCY_DIALOG,
    payload: { dependencyDialog }
});