import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import MasterImportComponent from "./MasterImport";

import { set } from '../../../store/actions/pages';
import { integerEquators, StringEquators } from '../../../store/constants/pages';
import { createIconDataField, createNumberDataField, createStringDataField } from '../../../util/format';

import { setV2 } from '../../../store/actions/pagesV2';
import { getByName, reset } from '../../../store/actions/search';
import { selectSessionRole } from '../../../store/selectors/session';
import { hospitalServicesLookupErrorReset, hospitalServicesLookupFromMaster, setHopsitalServicesLookupDialog, setHopsitalServicesLookupImportConfirmationDialog } from "../../../store/actions/hospitalServices";
import { createFilteredSortedDataSelector } from "../../../util/selector";

const DEFAULT_SORT = [{ id: 'drg', orderBy: 'asc' }];
const selectLookupDialog = (state) => state.hospitalServices.lookupDialog;
const selectDRGIds = (state, props) => state.pagesV2['hospitalservices-master-landing'].selected;

const selectFields = createSelector(
    () => [
        createStringDataField('drg', 'DRG', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringDataField('type', 'Type', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createStringDataField('title', 'Description', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
        createNumberDataField('weights', 'Weight', { sortable: true, equators: integerEquators }),
        createIconDataField('isEffective', 'Effective', { hideFromFilter: true, icon: 'fas fa-check-circle', sortable: true }),
        createIconDataField('isDiscontinued', 'Discontinued', { hideFromFilter: false, isStrictDropdown: true, icon: "fas fa-times-circle", checkedClass: 'text-danger', sortable: true }),
    ]
);

const selectData = createSelector(
    (state) => state.hospitalServices.masterHospitalServices,
    (data: any) => {
        return data.map(hospitalService => ({
            ...hospitalService,
            disabledCheckbox: hospitalService?.isDiscontinued || !hospitalService?.isEffective
        }));
    }
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectSort = createSelector(
    (state, props) => state.pages[`hospitalservices-master-landing`].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectFilteredData = createFilteredSortedDataSelector(
    selectFilteredFields,
    (state, props) => state.pages[`hospitalservices-master-landing`].filter,
    selectSort,
    selectData,
);

const selectOpen = createSelector(
    selectLookupDialog,
    (lookupHsDialog) => {
        if (lookupHsDialog && lookupHsDialog?.open) {
            return true
        }
        return false
    }
);

export const MasterImport = connect(
    () => {
        return (state, props) => ({
            lookupDialogOpen: selectOpen(state),
            hospitalServicesMasterData: selectFilteredData(state),
            hospitalServicesMasterFields: selectFields(state as never),
            userRole: selectSessionRole(state as never),
            lookupHospitalServicesPending: state.hospitalServices.getHospitalServicesfromMaster.pending,
            order: state.pages[`hospitalservices-master-landing`].order,
            filter: state.pages[`hospitalservices-master-landing`].filter,
            sort: selectSort(state, props),
            selectedItems: selectDRGIds(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            hospitalServicesLookupFromMaster: hospitalServicesLookupFromMaster,
            hospitalServicesLookupErrorReset: hospitalServicesLookupErrorReset,
            setFilter: (value) => set('hospitalservices-master-landing', 'filter', value),
            setSort: (value) => set('hospitalservices-master-landing', 'sort', value),
            setOrder: (value) => set('hospitalservices-master-landing', 'order', value),
            setSelectedItems: (value) => setV2('hospitalservices-master-landing', 'selected', value),
            setHopsitalServicesLookupDialog: setHopsitalServicesLookupDialog,
            setHopsitalServicesLookupImportConfirmationDialog: setHopsitalServicesLookupImportConfirmationDialog
        }, dispatch)
    })
)(injectIntl(MasterImportComponent));