import { createReducer } from '../../util';
import * as actions from '../actions/contractsv2';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    contracts: [],
    directory: null,
    directoryEditDialog: null,
    moveDialog: null,
    hierarchy: [],
    directoryDependencyDialog: null, 
    directoryDependencies: [],
    contractsSearch: [],
    getContracts: {
        ...initialStatusState
    },
    getHierarchy: {
        ...initialStatusState
    },
    createDirectory: {
        ...initialStatusState
    },
    updateDirectory: {
        ...initialStatusState
    },
    // getDirectory: {
    //     ...initialStatusState
    // },
    // deleteDirectory: {
    //     ...initialStatusState
    // },
    move: {
        ...initialStatusState
    },
    // getHierarchy: {
    //     ...initialStatusState
    // },
    deleteContractsV2Directory: {
        ...initialStatusState
    },
    getContractsV2DirectoryDependencies: {
        ...initialStatusState
    },
    getContractsV2Search: {
        ...initialStatusState
    },

};

export default createReducer(initialState, {
    [actions.CONTRACTSV2_GET_PENDING]: (state) => ({
        ...state,
        getContracts: {
            pending: true,
        },
    }),
    [actions.CONTRACTSV2_GET_FULFILLED]: (state, contracts) => ({
        ...state,
        getContracts: {
            ...initialStatusState,
        },
        contracts,
    }),
    [actions.CONTRACTSV2_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        getContracts: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),


    [actions.CONTRACTSV2_HIERARCHY_PENDING]: (state) => ({
        ...state,
        getHierarchy: {
            pending: true,
        },
    }),
    [actions.CONTRACTSV2_HIERARCHY_FULFILLED]: (state, hierarchy) => ({
        ...state,
        getHierarchy: {
            ...initialStatusState,
        },
        hierarchy
    }),
    [actions.CONTRACTSV2_HIERARCHY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getHierarchy: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTSV2_CREATE_DIRECTORY_PENDING]: (state) => ({
        ...state,
        createDirectory: {
            pending: true,
        },
    }),
    [actions.CONTRACTSV2_CREATE_DIRECTORY_FULFILLED]: (state) => ({
        ...state,
        createDirectory: {
            ...initialStatusState,
        },
    }),
    [actions.CONTRACTSV2_CREATE_DIRECTORY_REJECTED]: (state, errorMessage) => ({
        ...state,
        createDirectory: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTSV2_UPDATE_DIRECTORY_PENDING]: (state) => ({
        ...state,
        updateDirectory: {
            pending: true,
        },
    }),
    [actions.CONTRACTSV2_UPDATE_DIRECTORY_FULFILLED]: (state) => ({
        ...state,
        updateDirectory: {
            ...initialStatusState,
        },
    }),
    [actions.CONTRACTSV2_UPDATE_DIRECTORY_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateDirectory: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTSV2_MOVE_PENDING]: (state) => ({
        ...state,
        move: {
            pending: true,
        },
    }),
    [actions.CONTRACTSV2_MOVE_FULFILLED]: (state) => ({
        ...state,
        move: {
            ...initialStatusState,
        },
    }),
    [actions.CONTRACTSV2_MOVE_REJECTED]: (state, errorMessage) => ({
        ...state,
        move: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),


    [actions.CONTRACTSV2_DIRECTORY_EDIT_DIALOG]: (state, { directoryEditDialog }) => ({
        ...state,
        directoryEditDialog
    }),
    [actions.CONTRACTSV2_MOVE_DIALOG]: (state, { moveDialog }) => ({
        ...state,
        moveDialog
    }),

    [actions.CONTRACTSV2_RESET]: (state) => ({
        ...state,
        contracts: []
    }),
    [actions.CONTRACTSV2_DIRECTORY_SET_DEPENDENCY_DIALOG]: (state, { directoryDependencyDialog }) => ({
        ...state,
        directoryDependencyDialog
    }),

    [actions.CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES_PENDING]: (state) => ({
        ...state,
        getContractsV2DirectoryDependencies: {
            pending: true,
        },
    }),
    [actions.CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES_FULFILLED]: (state, directoryDependencies) => ({
        ...state,
        getContractsV2DirectoryDependencies: {
            ...initialStatusState,
        },
        directoryDependencies
    }),
    [actions.CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES_REJECTED]: (state, { errorMessage }) => ({
        ...state,
        getContractsV2DirectoryDependencies: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTSV2_DIRECTORY_DELETE_PENDING]: (state) => ({
        ...state,
        deleteContractsV2Directory: {
            pending: true,
        },
    }),
    [actions.CONTRACTSV2_DIRECTORY_DELETE_FULFILLED]: (state, types) => ({
        ...state,
        deleteContractsV2Directory: {
            ...initialStatusState,
        },
    }),
    [actions.CONTRACTSV2_DIRECTORY_DELETE_REJECTED]: (state, { errorMessage, innerException, errorDetails }) => ({
        ...state,
        deleteContractsV2Directory: {
            ...initialStatusState,
            error: true,
            errorMessage,
            innerException,
            errorDetails
        },
    }),

    [actions.CONTRACTSV2_SEARCH_PENDING]: (state) => ({
        ...state,
        getContractsV2Search: {
            pending: true,
        },
    }),
    [actions.CONTRACTSV2_SEARCH_FULFILLED]: (state, contractsSearch) => ({
        ...state,
        getContractsV2Search: {
            ...initialStatusState,
        },
        contractsSearch
    }),
    [actions.CONTRACTSV2_SEARCH_REJECTED]: (state) => ({
        ...state,
        getContractsV2Search: {
            ...initialStatusState,
            error: true,
        },
    }),

    
    [actions.CONTRACTSV2_RESET_DIRECTORY_DEPDENDENCY]: (state) => ({
        ...state,
        directoryDependencies: []
    }),


});