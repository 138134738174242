import React, { useState, useEffect } from 'react';
import {
    makeStyles, Tooltip, IconButton, Grid, Button, Dialog, DialogActions, DialogContent, 
    DialogTitle, Typography, TablePagination
} from '@material-ui/core';
import Helmet from 'react-helmet';
import { DataFilter, DataTable, TablePaginationActions } from '../../../common';
import { ImportDialog } from './ImportDialog';
import CloseIcon from '@material-ui/icons/Close';

const DEFAULT_SORT = [{ id: 'drg', direction: 'asc' }];
const SHOW_SEARCH_FILTERS = true;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },

    icon: {
        fontSize: 17,
        marginTop: 1,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogContent: {
        padding: '0px',
    }
}));

export default function MasterImport({
    intl, lookupDialogOpen, hospitalServicesMasterData, hospitalServicesMasterFields, 
    userRole, order, filter, sort, lookupHospitalServicesPending, handleRefresh, selectedItems,
    actions: {
        hospitalServicesLookupFromMaster,
        hospitalServicesLookupErrorReset,
        setHopsitalServicesLookupDialog,
        setHopsitalServicesLookupImportConfirmationDialog,
        setOrder, setSort, setFilter,
        setSelectedItems
    }
}) {

    useEffect(() => {
        if (lookupDialogOpen) {
            setFilter('');
            hospitalServicesLookupFromMaster();
        }
        return function cleanup() {
            hospitalServicesLookupErrorReset();
            setSelectedItems([]);
        };
    }, [lookupDialogOpen]);

    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const classes = useStyles();

    function handleImport() {
        setHopsitalServicesLookupImportConfirmationDialog({open: true});
    }

    function handleLookupDialogClose() {
        setHopsitalServicesLookupDialog(null);
        handleRefresh();
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchInput = (value) => {
        setFilter(value);
        setPage(0);
    }

    return (
        <>
            <Dialog fullWidth maxWidth='lg' open={lookupDialogOpen} onClose={handleLookupDialogClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">Lookup DRGs</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <DataFilter
                            onChange={handleSearchInput}
                            placeholder="Search"
                            //cssClass={classes.textField}
                            filter={filter}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleLookupDialogClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <DataTable
                    pageName='hospitalservices-master-landing'
                    fields={hospitalServicesMasterFields}
                    data={hospitalServicesMasterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    order={order}
                    onOrder={setOrder}
                    sort={sort}
                    onSort={setSort}
                    loading={lookupHospitalServicesPending}
                    showPagination={true}
                    hasCheckbox={true}
                    showSelectAll={hospitalServicesMasterData.some(record => record.disabledCheckbox === true) ? false : true}
                    footerPagination={() => (
                        <TablePagination
                            rowsPerPageOptions={[10, 50, 100, 250, 500, { label: 'All', value: -1 }]}
                            colSpan={hospitalServicesMasterFields.length + 2}
                            count={hospitalServicesMasterData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleLookupDialogClose} variant='contained' color="default">
                    Cancel
                </Button>
                <Button
                    disabled={selectedItems?.length === 0 || lookupHospitalServicesPending}
                    variant='contained'
                    onClick={handleImport}
                    color="primary">
                    Import DRGs
                </Button>
            </DialogActions>
            </Dialog>
            <ImportDialog handleRefresh={handleRefresh} />
        </>
    );
}