import { Immutable } from "@pure-ptr/react";
import { Claim, ResponseItem } from "./mock-data";
import { getRevCodeGroup, RevCodeGroup } from "./rev-code-grouping";

export class Summary extends Immutable {
    total = 0;
    variance = 0;
    increase = 0;

    static fromTotals( totals : number[] ) : Summary[] {
        return Summary.array( totals, ( total, i ) => ({
             total,
             variance : i > 0 ? total - totals[i - 1] : 0,
             increase : i > 0 ? ( total - totals[i - 1] ) / totals[i - 1] : 0
        }));
    }
}

export class Forecast extends Immutable {
    units = null as number
    rate = null as number

    static getFilledLastValue( forecasts : Forecast[] ) : Forecast[] {
        let units = 0, rate = 0;

        return Forecast.array( forecasts, forecast => {
            units = forecast.units ?? units,
            rate = forecast.rate ?? rate;

            return { units, rate };
        })
    }
}

class ClaimForecast extends Immutable {
    claim : Claim = null
    forecast = [] as Forecast[]
    revCodeGroup : RevCodeGroup = null

    // Computed properties...
    filledForecast = [] as Forecast[]
    summary = [] as Summary[]

    initialize(){
        this.filledForecast = Forecast.getFilledLastValue( this.forecast );
        this.summary = Summary.fromTotals( 
            this.filledForecast.map( x => x.units * x.rate ) 
        );
    }

    static fromClaim( claim : Claim, years : number ) : ClaimForecast {
        return ClaimForecast.object({
            claim,
            revCodeGroup : getRevCodeGroup( claim.revcode ),
            forecast : [ 
                Forecast.object({
                    units : claim.units, 
                    rate : claim.actualPay 
                }),
                ...Array( years ).fill( Forecast.object({}) )
            ]
        });
    }
}

function aggregateSummary( items : ({ summary : Summary[] }[] ) ) : Summary[] {
    return Summary.fromTotals( 
        items.reduce( 
            ( acc, x ) => 
                acc.map( ( total, i ) => total + x.summary[i].total ),

            Array( items[0].summary.length ).fill( 0 )
        )
    );
}

export class RevCodeGroupForecast extends Immutable {
    claims : ClaimForecast[] = []

    initialize(){
        this.revCodeGroup = this.claims[0]?.revCodeGroup;
        this.summary = aggregateSummary( this.claims );
    }

    // Computed properties...
    summary = [] as Summary[]
    revCodeGroup : RevCodeGroup = null

    get id(){
        return this.revCodeGroup.revCodes;
    }
}

export class AnnualForecast extends Immutable {
    groups : RevCodeGroupForecast[] = []
    years = [] as string[]

    // Computed properties...
    summary = [] as Summary[]

    initialize(){
        this.summary = aggregateSummary( this.groups );
    }

    static fromResponse( response : ResponseItem, years : number ) : AnnualForecast {
        // Parse claims...
        const claims = response.claims.map(
            claim => ClaimForecast.fromClaim( claim, years )
        );

        // Group claims...
        const groups = claims.reduce( ( acc, x ) => {
            const group = acc[ x.revCodeGroup.revCodes ] ?? ( acc[ x.revCodeGroup.revCodes ] = [] );
            group.push( x );
            return acc;
        }, {} as { [subgroup : string] : ClaimForecast[] });

        return AnnualForecast.object({
            years : Array( years ).fill( 0 ).map( ( _, i ) => `${i + 1} Year`),
            groups : RevCodeGroupForecast.array( 
                Object.values( groups ), 
                x => ({ claims : x }) 
            )
        })
    }
}