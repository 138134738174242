import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import ContractsV2BreadCrumbComponent from './ContractsV2BreadCrumb';
import { createSelector } from 'reselect';
import { findNodeInTree, findParents } from '../../../util/tree';
import { setV2 } from '../../../store/actions/pagesV2';
import { moveDirectoryOrContracts } from '../../../store/actions/contractsv2';

export const ContractsBreadCrumb = connect(
    () => {
        const selectBreadcrumbItems = createSelector(
            (state, props) => props.directoryId,
            (state) => state.contractsv2.hierarchy,
            (currentDirectory, hierarchy) => {
                return findParents(parseInt(currentDirectory), hierarchy);
            }
        );

        const selectCurrentDirectory = createSelector(
            (state, props) => props.directoryId,
            (state) => state.contractsv2.hierarchy,
            (currentDirectory, hierarchy) => {
                if (currentDirectory === 'root') {
                    return null;
                }
                return findNodeInTree(parseInt(currentDirectory), hierarchy);
            }
        );

        const selectContractsIds:any = (state, props) => state.pagesV2['contractsv2-landing'].selected;

        return (state, props) => ({
            breadcrumbItems: selectBreadcrumbItems(state, props),
            currentDirectory: selectCurrentDirectory(state, props),
            selectContractsIds: selectContractsIds(state)

        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            moveDirectoryOrContracts: moveDirectoryOrContracts,
            resetSelectedContracts: () => setV2('contractsv2-landing', 'selected', [])
        }, dispatch)
    })
)(injectIntl(ContractsV2BreadCrumbComponent as any));