import React from "react";
import { FieldArray } from "formik";

import {
    FormControl, InputLabel,
    makeStyles, Select, Input,
    TextField, MenuItem, FormHelperText,
    Grid,
    IconButton,
    Popover,
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Autocomplete } from "@material-ui/lab";
import { useState } from "react";
import { RuleModifierMultiProcedureFieldArrayDialog } from "./RuleModifierMultiProcedureFieldArrayDialog";
import { Portlet, PortletHeader } from "../../../partials/content/Portlet";
import { useRef } from "react";
import { LightDataTable } from "../../../common";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '85%',
        '& .MuiInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
          },
          }
    },
   
    formControl: {
        width: '85%',
        marginTop: theme.spacing(2),
    },
    autoComplete: {
        width: '85%',
    },
    button: {
        margin: theme.spacing(1)
    },
    iconButton: {
        marginTop: theme.spacing(3),
        marginLeft: 4,
        padding: 8
    },
    percentArrayRequired: {
        color: '#fd397a',
        textDecoration: 'underline'
    },
    percentageText: {
        top: '100%',
        right: -4,
        position: 'absolute',
        marginTop: -5
    },
    buttonRow: {
        position: 'relative',
        width: 'max-content'
    },
    portlet: {
        'box-shadow': 'none',
        marginBottom: 0,
        "& .kt-portlet__head": {
            borderColor: theme.palette.secondary.main,
            // marginLeft: theme.spacing(3.5),
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        marginTop: theme.spacing(2),
        pointerEvents: 'auto',
        width: 300,
    },
}));

const PERCENTAGE_POPOVER_LABELS = [{ id: 'occurance', name: 'Occurence' }, { id: 'value', name: 'Value' }];

export default function RuleModifierFieldArray(props) {
    const {
        values, handleBlur, handleChange, touched, errors, modifiersPayMethods, modifiers,
        setFieldValue, isPayMethodMultiProcedure
    } = props;

    const [isMultiProcedureOpen, setMultiProcedureOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const anchorEl = useRef(null);

    const classes = useStyles();

    function getHelperText(key, index) {
        if (touched.ruleToModifierXref) {
            if (touched.ruleToModifierXref[index]) {
                return touched.ruleToModifierXref[index][key];
            }
            return false;
        }
        return false;
    }

    function getError(key, index) {
        if (errors.ruleToModifierXref) {
            if (errors.ruleToModifierXref[index]) {
                return errors.ruleToModifierXref[index][key];
            }
            return false;
        }
        return false;
    }

    function handleModifierChange(value, index) {
        setFieldValue(`ruleToModifierXref.${index}.modifierId`, value)
    }

    function handlePayMethodChange(value, index) {
        setFieldValue(`ruleToModifierXref.${index}.payMethodId`, value);

        if (isPayMethodMultiProcedure(value)) {
            // MultiProcedure Percentage Array for Specific PayMethod 
            const modifierMultiProcedurePercentage = [
                {
                    occurance: '',
                    value: ''
                }
            ];
            setFieldValue(`ruleToModifierXref.${index}.modifierMultiProcedurePercentage`, modifierMultiProcedurePercentage);
            setFieldValue(`ruleToModifierXref.${index}.value`, 0);
        } else {
            setFieldValue(`ruleToModifierXref.${index}.modifierMultiProcedurePercentage`, undefined);
        }
    }

    function handleMultiProcedureDialogClose() {
        setMultiProcedureOpen(false);
    }
    function handleMultiProcedureDiaogOpen(index) {
        setMultiProcedureOpen(true);
        setSelectedIndex(index)
    }

    function handlePercentageDataHover(index) {
        setIsPopoverOpen(true);
        setSelectedIndex(index);
    }

    function popoverLeave() {
        setIsPopoverOpen(false);
    }

    function paperEnter() {
        setIsPopoverOpen(true);
    }

    return (
        <>
            <FieldArray
                name="ruleToModifierXref"
                render={({ remove, insert }) => (
                    <Grid container>
                        <Grid item xs={12}>
                            {values.ruleToModifierXref.map((modifierItem, index) => {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Autocomplete
                                                    className={classes.autoComplete}
                                                    value={modifierItem.modifierId}
                                                    disableClearable={false}
                                                    onChange={(e, value) => { handleModifierChange(value, index) }}
                                                    getOptionSelected={(option, value) => option === value}
                                                    // name={`ruleToModifierXref.${index}.modifierId`}
                                                    options={modifiers}
                                                    getOptionLabel={(option) => `${option.modifier} (${option.modfierType})`}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            name={`ruleToModifierXref.${index}.modifierId`}
                                                            label="Modifier"
                                                            helperText={getHelperText('modifierId', index) && getError('modifierId', index)}
                                                            error={Boolean(getHelperText('modifierId', index) && getError('modifierId', index))}
                                                            margin="normal" />}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel htmlFor="payMethodId">Pay Method</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        required
                                                        value={modifierItem.payMethodId}
                                                        onChange={(e) => { handlePayMethodChange(e.target.value, index) }}
                                                        input={<Input id={`ruleToModifierXref.${index}.payMethodId`} />}
                                                        inputProps={{
                                                            name: `ruleToModifierXref.${index}.payMethodId`,
                                                            id: `ruleToModifierXref.${index}.payMethodId`,
                                                        }}
                                                        error={Boolean(getHelperText('payMethodId', index) && getError('payMethodId', index))}
                                                    >
                                                        {modifiersPayMethods.map(paymethod => (
                                                            <MenuItem
                                                                key={paymethod.id}
                                                                value={paymethod.id}
                                                            >
                                                                {paymethod.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText error>{getHelperText('payMethodId', index) && getError('payMethodId', index)}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    disabled={isPayMethodMultiProcedure(modifierItem.payMethodId)}
                                                    type="number"
                                                    label="Value"
                                                    name={`ruleToModifierXref.${index}.value`}
                                                    className={classes.textField}
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={modifierItem.value}
                                                    helperText={getHelperText('value', index) && getError('value', index)}
                                                    error={Boolean(getHelperText('value', index) && getError('value', index))}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Grid className={classes.buttonRow} container alignItems="center">
                                                    {index === 0 && (
                                                        <IconButton
                                                            edge="start"
                                                            color="primary"
                                                            aria-label="Add"
                                                            onClick={() => insert(values.ruleToModifierXref.length, {
                                                                payMethodId: '',
                                                                value: '',
                                                                modifierId: null
                                                            })}
                                                            className={classes.iconButton}>
                                                            <AddCircleIcon />
                                                        </IconButton>
                                                    )}
                                                    {index !== 0 && values.ruleToModifierXref.length > 1 && (
                                                        <IconButton
                                                            edge="start"
                                                            aria-label="Delete"
                                                            onClick={() => remove(index)}
                                                            className={classes.iconButton}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                    {isPayMethodMultiProcedure(modifierItem.payMethodId) &&
                                                        <>
                                                            <IconButton
                                                                ref={anchorEl}
                                                                edge="start"
                                                                onClick={() => handleMultiProcedureDiaogOpen(index)}
                                                                className={`${classes.iconButton} ${getError('modifierMultiProcedurePercentage', index) && classes.percentArrayRequired}`}
                                                                aria-owns="percentage-mouse-over-popover"
                                                                aria-haspopup="true"
                                                                onMouseEnter={() => handlePercentageDataHover(index)}
                                                                onMouseLeave={popoverLeave}
                                                            >
                                                                <SettingsIcon />
                                                            </IconButton>
                                                            <FormHelperText className={classes.percentageText} error>{getError('modifierMultiProcedurePercentage', index) && 'Required'}</FormHelperText>
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                )
                }
            />
            <RuleModifierMultiProcedureFieldArrayDialog
                open={isMultiProcedureOpen}
                handleClose={handleMultiProcedureDialogClose}
                selectedModifierIndex={selectedIndex}
                selectedModifierMultiProcedurePercentage={values?.ruleToModifierXref[selectedIndex]?.modifierMultiProcedurePercentage}

                handleSetArrayValue={setFieldValue}
            />
            <Popover
                id="percentage-mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={isPopoverOpen}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                PaperProps={{
                    onMouseEnter: paperEnter,
                    onMouseLeave: popoverLeave
                }}
                disableRestoreFocus
            >
                <Portlet className={classes.portlet}>
                    <PortletHeader
                        title='MultiProcedure Percentage'
                        showTitle={true}
                        toolbar={<></>}
                    />
                    <Grid xs={12} className='px-3'>
                        <LightDataTable
                            labels={PERCENTAGE_POPOVER_LABELS}
                            data={values?.ruleToModifierXref[selectedIndex]?.modifierMultiProcedurePercentage}
                        />
                    </Grid>
                </Portlet>
            </Popover>
        </>
    );
}