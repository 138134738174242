import React, { useEffect } from 'react';
import {
    Dialog, DialogContent, DialogTitle, makeStyles, Grid,
    IconButton,
    Typography,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    Input,
    MenuItem,
    FormHelperText,
    InputAdornment,
} from '@material-ui/core';

import { Loadable } from '../../../../common';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        marginTop: theme.spacing(2),
        width: '100%'
    },
    autoComplete: {
        width: '100%',
    },
    textField: {
        width: '90%',
        marginLeft: theme.spacing(3),
    },
}));

export default function ModifierEditDialog({ open, handleRefresh, initialValues, intl, mode,
    createModifierPending, updateModifierPending, masterModifiers, payerContracts, visitTypes, 
    getModifiersPending, getPayerContractsPending, 
    actions: { createModifier, updateModifier, createEditReset, setEditDialog, getModifiers,
    getPayerContractsTiny
}}) {

    const classes = useStyles();
    


    useEffect(() => {
        if (open) {
            getModifiers();
        }
        return function cleanup() {
            createEditReset();
        };
    }, [open, createEditReset]);

    useEffect(() => {
        getPayerContractsTiny();
    }, [getPayerContractsTiny]);

    const handleClose = (shouldReloadGrid) => {
        setEditDialog(null);
        if(shouldReloadGrid) {
            handleRefresh();
        }
    }

    function handleModifierChange(value, setFieldValue) {
        let modifier = masterModifiers.find(x=> x.id == value.id);
        setFieldValue('name', modifier?.modifier);
        setFieldValue('description', modifier?.description ? modifier?.description : modifier?.modfierType);
    }

    function handlePercentageChange(value, setFieldValue) {
        setFieldValue('percentage', value);
        setFieldValue('paymentDescription', `${value}% payment adjustment for the item or service to which the modifier is appended.`);
    }

    return (
        <Dialog fullWidth open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6">{mode === 'edit' ? 'Edit' : 'Add'} MRF Modifier </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Loadable loading={createModifierPending || updateModifierPending || getModifiersPending || getPayerContractsPending}>
                    <Grid>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validate={values => {
                                const errors : any = {};
                                
                                if (!values.name) {
                                    errors.name = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                                if (!values.percentage) {
                                    errors.percentage = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                                if (!values.description) {
                                    errors.description = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                                if (!values.visitType) {
                                    errors.visitType = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                                if (!values.payerContract) {
                                    errors.payerContract = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }
                                if (!values.paymentDescription) {
                                    errors.paymentDescription = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                let shouldReloadGrid = true;
                                let payload = {
                                    name: values.name,
                                    description: values.description,
                                    percentage: values.percentage,
                                    visitType: values.visitType,
                                    paymentDescription: values.paymentDescription,
                                    payerContractId: values.payerContract?.id ?? null,
                                    id: undefined
                                }
                                if (mode === 'create') {
                                    createModifier(payload, handleClose, shouldReloadGrid);
                                }
                                else {
                                    payload = {
                                        ...payload,
                                        id: values.id,
                                    }
                                    updateModifier(payload, handleClose, shouldReloadGrid);
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                isSubmitting
                            }) => (
                                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                    <div>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                className={classes.autoComplete}
                                                value={values.masterModifierId}
                                                disableClearable={false}
                                                onChange={(e, value) => { handleModifierChange(value, setFieldValue) }}
                                                getOptionSelected={(option, value) => option === value}
                                                options={masterModifiers}
                                                getOptionLabel={(option) => `${option?.modifier || ''} (${option?.modfierType || ''})`}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        name={`masterModifierId`}
                                                        label="Master Modifier"
                                                        margin="normal" />}
                                            />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    required
                                                    label="Modifier"
                                                    name="name"
                                                    margin="normal"
                                                    value={values.name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.name && errors.name)}
                                                    helperText={touched.name && errors.name}
                                                    inputProps={{ maxLength: 50 }}
                                                />
                                            </Grid>
                                            
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    required
                                                    minRows={2}
                                                    multiline
                                                    label="Description"
                                                    name="description"
                                                    margin="normal"
                                                    value={values.description}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.description && errors.description)}
                                                    helperText={touched.description && errors.description}
                                                    inputProps={{ maxLength: 500 }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel required htmlFor="role">Visit</InputLabel>
                                                    <Select
                                                        required
                                                        value={values.visitType}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.visitType && errors.visitType)}
                                                        input={<Input id="visitType" />}
                                                        inputProps={{
                                                            name: "visitType",
                                                            id: "visitType"
                                                        }}
                                                    >
                                                        {visitTypes.map(type => (
                                                            <MenuItem
                                                                key={type}
                                                                value={type}
                                                            >
                                                                {type}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText error>{touched.visitType && errors.visitType}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    required
                                                    type="number"
                                                    className={classes.textField}
                                                    label="Percentage"
                                                    name='percentage'
                                                    margin="normal"
                                                    value={values.percentage}
                                                    onBlur={handleBlur}
                                                    //onChange={(event) => { handlePercentageChange(event.target.value, setFieldValue) }}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.percentage && errors.percentage)}
                                                    helperText={touched.percentage && errors.percentage}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <Autocomplete
                                                    value={values.payerContract}
                                                    disableClearable={false}
                                                    onChange={(e, value) => setFieldValue("payerContract", value)}
                                                    getOptionSelected={(option, value) => option === value}
                                                    options={payerContracts}
                                                    getOptionLabel={(option) => option?.displayName || ''}
                                                    getOptionDisabled={option => {
                                                        if (!option.includeInMachineReadable) {
                                                            return true;
                                                        }
                                                        return false;
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            required
                                                            name="payerContract"
                                                            label="Payer Contract"
                                                            error={Boolean(touched.payerContract && errors.payerContract)}
                                                            helperText={touched.payerContract && errors.payerContract}
                                                            margin="normal" />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    required
                                                    minRows={2}
                                                    multiline
                                                    label="Payment Description"
                                                    name="paymentDescription"
                                                    margin="normal"
                                                    value={values.paymentDescription}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.paymentDescription && errors.paymentDescription)}
                                                    helperText={touched.paymentDescription && errors.paymentDescription}
                                                    inputProps={{ maxLength: 500 }}
                                                />
                                            </Grid>
                                            
                                        </Grid>
                                        <hr />
                                        <Grid container justify="flex-end">
                                            <Button
                                                onClick={() => handleClose(false)}
                                                variant="contained"
                                                color="default">
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={`ml-3`}
                                                type="submit"
                                            >
                                                {(createModifierPending || updateModifierPending) ? 'Saving Changes' : 'Save'}
                                            </Button>
                                        </Grid>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Loadable>
            </DialogContent>
        </Dialog>
    )
}