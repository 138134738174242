import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import DependencyDialogComponent from './DependencyDialog';
import { setV2 } from '../../../store/actions/pagesV2';
import { deleteBulk, setHopsitalServicesDependencyDialog } from '../../../store/actions/hospitalServices';

export const DependencyDialog = connect(
    () => {
        const selectHSIds = (state, props) => state.pagesV2['hospitalservices-landing'].selected;
        const selectDependencyDialog = (state) => state.hospitalServices.dependencyDialog;

        const selectOpen = createSelector(
            selectDependencyDialog,
            (dependencyDialog) => {
                if (dependencyDialog && dependencyDialog?.open) {
                    return true
                }
                return false
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            selectedItems: selectHSIds(state, props),
            deleteHospitalServicesPending: state.hospitalServices.deleteBulk.pending,
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setSelected: (value) => setV2('hospitalservices-landing', 'selected', value),
            deleteHospitalServices: deleteBulk,
            setHopsitalServicesDependencyDialog: setHopsitalServicesDependencyDialog
        }, dispatch)
    })
)(injectIntl(DependencyDialogComponent));