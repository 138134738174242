import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import { hospitalServicesImportErrorReset, importHospitalServicesFromHistorical, setHopsitalServicesImportDialog } from '../../../store/actions/hospitalServices';
import HistoricalImportComponent from './HistoricalImport';
import { createDateField, createIconDataField, createNumberDataField, createStringDataField } from '../../../util/format';
import { dateEquators, integerEquators, StringEquators } from '../../../store/constants/pages';
import { set } from '../../../store/actions/pages';
import { createFilteredSortedDataSelector } from '../../../util/selector';

export const HistoricalImport = connect(
    () => {

        const DEFAULT_SORT = [{ id: 'drg', orderBy: 'asc' }];

        const selectFields = createSelector(
            () => [
                createStringDataField('drg', 'DRG', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
                createStringDataField('type', 'Type', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
                createStringDataField('title', 'Description', { sortable: true, equators: StringEquators, whiteSpace: 'break-spaces' }),
                createNumberDataField('weights', 'Weight', { sortable: true, equators: integerEquators }),
                createDateField('effectiveTo', 'Effective To', { sortable: true, equators: dateEquators }),
            ]
        );
        
        const selectData = createSelector(
            (state) => state.hospitalServices.hospitalServicesImportsHistorical,
            (data) => {
                return data;
            }
        );

        const selectFilteredFields = createSelector(
            selectFields,
            (fields) => {
                return fields.map(field => field.id);
            }
        );
        
        const selectSort = createSelector(
            (state, props) => state.pages[`drg-import-landing`].sort,
            (sort) => sort || DEFAULT_SORT,
        );
        
        const selectFilteredData = createFilteredSortedDataSelector(
            selectFilteredFields,
            (state, props) => state.pages[`drg-import-landing`].filter,
            selectSort,
            selectData,
        );

        const selectImportDialog = (state) => state.hospitalServices.importDialog;

        const selectOpen = createSelector(
            selectImportDialog,
            (importHsDialog) => {
                if (importHsDialog && importHsDialog?.open) {
                    return true
                }
                return false
            }
        );

        return (state, props) => ({
            open: selectOpen(state),
            fields: selectFields(state as never),
            filteredData: selectFilteredData(state),
            importHospitalServicesPending: state.hospitalServices.getHospitalServicesImportHistorical.pending,
            order: state.pages[`drg-import-landing`].order,
            filter: state.pages[`drg-import-landing`].filter,
            sort: selectSort(state, props)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            importHospitalServicesFromHistorical: importHospitalServicesFromHistorical,
            hospitalServicesImportErrorReset: hospitalServicesImportErrorReset,
            setList: (value) => set('drg-import-landing', 'list', value),
            setFilter: (value) => set('drg-import-landing', 'filter', value),
            setSort: (value) => set('drg-import-landing', 'sort', value),
            setOrder: (value) => set('drg-import-landing', 'order', value),
            setHopsitalServicesImportDialog: setHopsitalServicesImportDialog
        }, dispatch)
    })
)(injectIntl(HistoricalImportComponent));