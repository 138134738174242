import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import ResetPasswordConfirmation from './Confirmation';


import { selectVerifyTokenError, selectResetPasswordError, selectVerifyTokenErrorMessage, selectResetPasswordErrorMessage } from '../../../../store/selectors/forgotPassword';

export const ResetPasswordConfirmationPage = injectIntl(
    connect(
        (state) => ({
            resetPasswordError: selectResetPasswordError(state),
            verifyTokenError: selectVerifyTokenError(state),
            verifyTokenErrorMessage: selectVerifyTokenErrorMessage(state),
            resetPasswordErrorMessage: selectResetPasswordErrorMessage(state),
        }),
    )(ResetPasswordConfirmation));