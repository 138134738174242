import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { set, setMulti,setOpenAdvanceFiltersDialog } from '../../../store/actions/pages';

import ClaimAnalysisTopFiltersComponent from './ClaimAnalysisTopFilters';
import { setRelativeFilter } from '../../../store/actions/claimAnalysis';

export const ClaimAnalysisTopFilters = connect(
    () => {

        const selectSignificance = createSelector(
            (state) => state.pages['claimAnalysis-landing'],
            (table) => {
                return table.significance;
            }
        );

        const selectCustomFilter = createSelector(
            (state) => state.pages['claimAnalysis-landing'],
            (table) => {
                return table.customFilter;
            }
        );

        const selectPending = createSelector(
            (state) => state.pages,
            (state) => state.search.getByName.pending,
            (pages, searchPending) => {
                return pages['claimAnalysis-landing'].pending || searchPending
            }
        );

        return (state:any, props) => ({
            significanceFlag: selectSignificance(state),
            customFilter: selectCustomFilter(state),
            relativeFilterValue: state.claimAnalysis.relativeFilterValue,
            tableFilters:state.pages['claimAnalysis-landing']?.filter,
            pending: selectPending(state),
            open: state.pages.open

        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            set: (value) => set('claimAnalysis-landing', 'significance', value),
            setMulti: obj => setMulti('claimAnalysis-landing', obj),
            setCustomFilter: (value) => set('claimAnalysis-landing', 'customFilter', value),
            setRelativeFilter: setRelativeFilter,
            setOpenAdvanceFiltersDialog
        }, dispatch)
    })
)(injectIntl(ClaimAnalysisTopFiltersComponent));