import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';

import {
    Portlet, PortletBody,
    PortletHeader, PortletHeaderToolbar
} from "../../partials/content/Portlet";

import {
    Box, Grid,
    IconButton,
    makeStyles,
    Tooltip
} from "@material-ui/core";

import { PagedTable } from '../../common';
// import { ClaimDrilldownDialog } from './ClaimDrilldownDialog';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { AcknowledgeDialog } from './AcknowledgeDialog';
import { FlagDialog } from './FlagDialog';

import { UpdateOutlined } from '@material-ui/icons';
import FlagIcon from '@material-ui/icons/Flag';
import GetAppIcon from '@material-ui/icons/GetApp';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { asDate } from '../../util/date';
import { ClaimAnalysisTopFilters } from './ClaimAnalysisTopFilters';
import { ClaimStatistics } from './ClaimStatistics';
import { ExecutionErrorDialog } from './ExecutionErrorDialog';
import { ProcessClaimDialog } from './ProcessDialog';
import { UpdateStatusDialog } from './UpdateStatusDialog';

const DEFAULT_SORT = [{ id: 'variance', direction: 'desc' }];

const DEFAULT_HIDDEN_FIELDS = ['payer.name', 'updatedAt', 'recoveredAmount', 'physicianLastname', 'age'];

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        zIndex: 100,
    },
    formControl: {
        margin: theme.spacing(1),
        width: 200,
    },
    formControlClaim: {
        margin: theme.spacing(1),
        width: 71
    },
    formControlStatus: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 4,
        marginRight: 4,
        width: 140,
        '@media (max-width: 1440px)': {
            width: 110,
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        pointerEvents: 'auto',
        width: 300,
    },
    switch: {
        marginRight: 0,
        marginTop: 20,
        marginLeft: 0
    },
    gridMDMargin: {
        '@media (max-width: 1023px)': {
            paddingLeft: 10,
        }
    },
    IconButton: {
        padding: 6
    },
    statisticsContainer:{
        padding:'20px',
        paddingBottom:0
    }
}));

export default function ClaimAnalysisPage(props) {
    const classes = useStyles();
    const firstUpdate = useRef(true);
    const [refresh, setRefresh] = useState(Promise.resolve());

    const {
        fields,
        claimsData,
        claimAnalysisDownloadReportPending, claimPageRecord,
        sort, selectedClientName, filter,
        hasClaimProcessorAccess, defaultCustomFilters, relativeFilter,
        significance,
        claimStatusList,
        isUnpaid,
        rawFilters,
        actions: {
            claimAnalysisLanding,
            // getClaimAnalysisStats,
            claimAnalysisSetDrilldown,
            downloadAnalysisReport,
            searchGetByName,
            searchReset,
            resetSearchCriteria,
            flagClaims,
            getTinyContracts,
            getPayersTiny,
            getClaimStatuses,
            getNpiRoles,
            resetClaimProcessError,
            resetPagedTable,
            resetCriteriaWaiting,
            setOpenAcknowledgeDialog,
            setOpenFlagDialog,
            setFlagToggle,
            setBulkFlagged,
            setOpenPlayDialog,
            setFilter,
            resetSearchAPICompleted,
            setSelectedClaims,
            setBulkAcknowledge,
            setClaimUpdateStatusDialog,
            setCustomFilter,
            setMulti
        }
    } = props;

    useEffect(() => {
        getTinyContracts();
    }, [getTinyContracts]);

    useEffect(() => {
        getClaimStatuses();
    }, [getClaimStatuses]);

    useEffect(() => {
        getPayersTiny();
    }, [getPayersTiny]);
    useEffect(() => {
        getNpiRoles()
    }, [getNpiRoles]);

    useEffect(() => {
        const pageName = {
            name: 'claimAnalysis-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            // setCustomFilter([]);
            resetPagedTable();
            searchReset();
            // resetSearchCriteria(null);
            // resetCriteriaWaiting();
            // resetSearchAPICompleted();

            claimAnalysisSetDrilldown({
                open: false,
            });
        }
    }, [searchGetByName]);


    function handleLoad(sort, filter, pagination) {

        let newOperands=filter?.operands?.map(operand => {
            const newFilters = operand?.filters?.map(filter => {
                if (filter.id === 'contract.name' && filter.value.includes(',') && filter.equator.includes('equal')) {
                    return {
                        ...filter,
                        value: `"${filter.value}"`
                    };
                }
                return filter;
            });
            return {
                ...operand,
                filters: newFilters
            };
        });
        let commaEscapedFilters={...filter,operands:newOperands} || null;

        claimAnalysisLanding(pagination, sort,!filter ? null : commaEscapedFilters, significance);
        // HACK - stats will be called inside landing API to avoid render.
        // getClaimAnalysisStats(pagination, sort, filter);
    }

    const handleClickColumn = (fieldName, rowData, index) => {
        props.history.push(`/cms/claimAnalysis/view/${rowData.id}`);
    }

    const handleAcknowledge = ({ id, claimnumber }) => {
        setBulkAcknowledge(false);
        setSelectedClaims([id]);
        setOpenAcknowledgeDialog(true);
    }

    const handleAcknowledgeClose = (shouldReloadGrid) => {
        setOpenAcknowledgeDialog(false);
        setOpenFlagDialog(false);
        setFlagToggle(false);

        if (shouldReloadGrid) {
            resetPagedTable();
            handleRefresh();
        }
    }

    function handleRefresh() {
        setRefresh(Promise.resolve());
        // const pageName = {
        //     name: 'claimAnalysis-landing'
        // }
        // searchGetByName(pageName);
    }

    function handleBulkAcknowledge() {
        setOpenAcknowledgeDialog(true);
        setBulkAcknowledge(true);
    }

    // function handleSelectAll(selectedRows) {
    //     // const selectedIds = selectedRows.map(x => x.id);
    //     // setSelectedClaims(selectedIds);
    // }

    // const handleCheckbox = (selectedRows) => {
    //     // const selectedIds = selectedRows.map(x => x.id);
    //     // setSelectedClaims(selectedIds);
    // };

    function handleDowloadTemplate() {
        let operandsWithoutBoth = filter.operands.filter(operand =>
            !operand.filters.some(filter => filter.value === 'both')
        );
        let filterWithoutBoth = {
            ...filter,
            operands: operandsWithoutBoth
        };
        const payload = {
            page: null,
            filter: filterWithoutBoth || null,
            sort: sort || [],
        };
        downloadAnalysisReport(payload,significance, downloadTemplateCallback);
    }

    function downloadTemplateCallback(blob) {
        const displayName = `${selectedClientName.replace(/ /g, "_")}_ClaimAnalysisReport`;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${displayName.replace(/\s/g, '')}.csv`;
        a.click();
    }

    const handleProcessClaims = () => {
        setOpenPlayDialog(true);
    }

    const handleProcessClaimsClose = () => {
        setOpenPlayDialog(false);
        resetClaimProcessError();
    }

    const handleProcessClaimCallback = () => {
        setOpenPlayDialog(false);
    }

    const handleUpdateClaimStatusOpen=()=>{
        setClaimUpdateStatusDialog(true)
    }

    const handleUpdateClaimStatusClose=()=>{
        setClaimUpdateStatusDialog(false)
    }

    const claimAnalysisTableAction = () => {
        return (
            <>

                        <Grid item>
                        <Tooltip title="Update Status">
                            <IconButton aria-label="process" size='small' onClick={handleUpdateClaimStatusOpen} disabled={claimAnalysisDownloadReportPending}>
                                <UpdateOutlined />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                {hasClaimProcessorAccess && (
                    <Grid item>
                        <Tooltip title="Process Claims">
                            <IconButton aria-label="process" size='small' onClick={handleProcessClaims} disabled={claimAnalysisDownloadReportPending}>
                                <PlayCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}
                <Grid item>
                    <Tooltip title="Export">
                        <IconButton aria-label="export" size='small' onClick={handleDowloadTemplate} disabled={claimAnalysisDownloadReportPending}>
                            <GetAppIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Follow">
                        <IconButton aria-label="follow" size='small' onClick={handleBulkFlag}>
                            <FlagIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Acknowledge">
                        <IconButton aria-label="acknowledge" size='small' onClick={handleBulkAcknowledge}>
                            <CheckCircleIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    }

    const hoverData = (claim) => {
        const hoverColumns = new Map();
        const contractNameColumns = [
            {
                label: "Name",
                data: claim?.contract?.name,
            }, {
                label: "Effective Date",
                data: asDate(claim?.contract?.effectiveDate),
            }, {
                label: "Termination Date",
                data: asDate(claim?.contract?.terminationDate),
            }, {
                type: "LINK",
                label: "More Details",
                data: `/cms/contract/view/${claim?.contract?.id}`,
            },
        ];

        hoverColumns.set('contract.name', contractNameColumns);

        return hoverColumns;
    }

    const handleSingleFlag = (flag, { id }) => {
        // add optional date if flag is true
        if (flag) {
            setOpenFlagDialog(true);
            setFlagToggle(flag); // pre set in Flag Dialog
            setBulkFlagged(false);
            setSelectedClaims([id]);
        } else {
            // directly call api on flag is false
            let payload = {
                analysisIds: [id],
                flag: flag,
                followupAlert: ''
            }
            flagClaims(payload, handleAcknowledgeClose, true);
        }
    }

    const handleBulkFlag = () => {
        setOpenFlagDialog(true);
        setBulkFlagged(true);
    }

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        handleRefresh();
    }, [significance]);

    function claimActions(rowData) {
        return <div className='d-flex'>
            <IconButton
                color="primary"
                className={classes.IconButton}
                aria-label="Follow"
                onClick={() => handleSingleFlag(!rowData.flag, rowData)}
            >
                <Tooltip title={rowData.flag ? 'Unfollow' : 'Follow'}>
                    {rowData.flag ? (
                        <i className="fa fa-flag text-danger"></i>
                    ) : (
                        <i className="fa fa-flag text-secondary"></i>
                    )}
                </Tooltip>
            </IconButton>
            <IconButton
                color="primary"
                className={classes.IconButton}
                aria-label="Acknowledge"
                onClick={() => handleAcknowledge(rowData)}
            >
                <Tooltip title={rowData.acknowledged ? 'Unacknowledge' : 'Acknowlege'}>
                    {rowData.acknowledged ? (
                        <i className="fas fa-check-circle text-success"></i>
                    ) : (
                        <i className="fas fa-check-circle text-secondary"></i>
                    )}
                </Tooltip>
            </IconButton>
            {rowData.hasExecutionError && <ExecutionErrorDialog executionError={rowData.executionError} />}
        </div>
    }

    function actionLevelGrid() {
        return <Grid container>
            <Grid item md={12}>
            <h6 className="">{`Total Records : ${(claimsData?.length > 0 && claimPageRecord?.totalRecords) ? claimPageRecord?.totalRecords : 0}`}</h6>
            </Grid>
        </Grid>
    }

    function handleFilterClear() {
        
            setMulti({
            filter: [],
            selectedFilter: null,
            customFilter: defaultCustomFilters,
            list: [],
            significance:true
            });
            localStorage.setItem("isCriteriaCleared", "true")
            handleRefresh()
    }

    return (
        <>
            <Helmet title="Claim Analysis" />
            <Portlet>
                <Box className={classes.statisticsContainer}>
            <ClaimStatistics />
            </Box>
                <PortletHeader
                    title="Claim Analysis"
                    name="claimAnalysis-landing"
                    handleRefresh={handleRefresh}
                    showSearchFilters={true}
                    handleFilterClear={handleFilterClear}
                    includeAdvanceFilters={true}
                    toolbar={
                        <PortletHeaderToolbar>
                            <ClaimAnalysisTopFilters handleRefresh={handleRefresh} relativeFilter={relativeFilter} />
                        </PortletHeaderToolbar>
                    }
                />
                <PortletBody>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <PagedTable
                                    name="claimAnalysis-landing"
                                    fields={fields}
                                    data={claimsData}
                                    onLoad={handleLoad}
                                    refresh={refresh}
                                    defaultSort={DEFAULT_SORT}
                                    handleClickColumn={handleClickColumn}
                                    criteriasSaveEnabled={true}
                                    hasCheckbox={true}
                                    showSelectAll={true}
                                    defaultCustomFilter={defaultCustomFilters}
                                    tableLevelActions={claimAnalysisTableAction}
                                    tableLevelLoading={claimAnalysisDownloadReportPending}
                                    hoverData={hoverData}
                                    // onCheckbox={handleCheckbox}
                                    // onSelectAll={handleSelectAll}
                                    defaultHiddenFields={DEFAULT_HIDDEN_FIELDS}
                                    hasActions={true}
                                    renderActionColumn={claimActions}
                                    actionLevelGrid={actionLevelGrid}
                                    showSearchFilters={true}
                                    showFilterColumnsDialog={true}
                                    showReorderColumnsDialog={true}
                                    shouldRenderLazy={true}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </PortletBody>
            </Portlet>
            {/* <ClaimDrilldownDialog handleRefresh={handleRefresh} handleNextClaim={handleNextClaim} handlePreviousClaim={handlePreviousClaim} /> */}
            <AcknowledgeDialog
                handleClose={handleAcknowledgeClose}
            />

            <FlagDialog
                handleClose={handleAcknowledgeClose}
            />
            <ProcessClaimDialog
                handleClose={handleProcessClaimsClose}
                handleProcessClaimCallback={handleProcessClaimCallback}
                claimsData={claimsData}
                filter={filter}
            />
            <UpdateStatusDialog handleClose={handleUpdateClaimStatusClose} claimStatusList={claimStatusList?.map((item)=>({label:item,name:item,value:item}))} handleRefresh={handleRefresh}/>
        </>
    );
}