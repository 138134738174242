import { combineReducers } from 'redux';
import pages from './pages';
import session from './session';
import clients from './clients';
import users from './users';
import roles from './roles';
import dashboards from './dashboards';
import dashboardFilters from './dashboardFilters';
import forgotpassword from './forgotPassword';
import statistics from './statistics';
import dashboardDataSources from './dashboardDataSources';
import dashboardTypes from './dashboardTypes';
import partners from './partners';
import contracts from './contracts';
import contractsFamily from './contractFamily';
import services from './services';
import rules from './rules';
import payers from './payers';
import claimAnalysis from './claimAnalysis';
import settings from './settings';
import facilities from './facilities';
import search from './search';
import vendors from './vendors';
import chargeMaster from './chargeMaster';
import shoppables from './shoppables';
import pricers from './pricers';
import associated from './associated';
import allowables from './allowables';
import brand from './brand';
import pricerPayers from './pricerPayers';
import feeScheduleItems from './feeScheduleItems';
import npiRoles from './npiRoles';
import executions from './executions';
import modifiers from './modifiers';
import uploads from './uploads';
import controlRoom from './controlRoom';
import modeling from './modeling';
import drg from './drg';
import opps from './opps';
import pagesV2 from './pagesV2';
import apikey from './apikey';
import eapg from './eapg';
import feeScheduleV2 from './feeScheduleV2';
import appId from './appId';
import hospitalServices from './hospitalServices';
import contractsv2 from './contractsv2';


import { metronic } from "../../../_metronic";

export const rootReducer = combineReducers({
    session,
    i18n: metronic.i18n.reducer,
    builder: metronic.builder.reducer,
    clients,
    users,
    roles,
    dashboards,
    statistics,
    pages,
    forgotpassword,
    dashboardFilters,
    dashboardDataSources,
    dashboardTypes,
    partners,
    contracts,
    services,
    rules,
    payers,
    claimAnalysis,
    settings,
    facilities,
    search,
    chargeMaster,
    shoppables,
    pricers,
    associated,
    allowables,
    brand,
    pricerPayers,
    feeScheduleItems,
    vendors,
    npiRoles,
    executions,
    modifiers,
    uploads,
    controlRoom,
    modeling,
    pagesV2,
    drg,
    opps,
    apikey,
    eapg,
    feeScheduleV2,
    contractsFamily,
    appId,
    hospitalServices,
    contractsv2
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;