import React, { useState } from 'react';
import { Grid, makeStyles, Box, Button } from '@material-ui/core';
import { toAbsoluteUrl } from '../../../_metronic/utils/utils';

const useStyles = makeStyles(theme => {
    return {
        date: {
            fontSize: '11px',
        },
        text: {
            lineHeight: "1.3"
        },
        hr: {
            margin: "5px"
        },
        previewBox: {
            border: '1px solid #9a9a9a', 
            borderRadius: '5px', 
            minHeight: '60px', 
            textAlign: 'center', 
            verticalAlign: 'center',
            width: '200px',
            float: 'right'
        },
        previewImage: {
            paddingTop: '4px',
            maxHeight: '50px'
        },
        fileInputField: {
            display: 'none'
        },
        fileInputLabel: {
            borderBottom: '1px solid #9a9a9a', 
            width:'90%', 
            marginTop: '25px'
        },
        fileInputButton: {
            marginBottom: '3px'
        }
    }
});

function ImageUploader(props) {
    const {
        logoPath ,resizedImage, setResizedImage, disabled, pricerBucket, brandLogoPath,
    } = props;

    const classes = useStyles();
    const [fileName, setFileName] = useState('');

    //const [resizedImage, setResizedImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            resizeImage(file);
            setFileName(file.name);
        }
    };

    const resizeImage = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Calculate aspect ratio
            const aspectRatio = img.width / img.height;
            let width = 200;
            let height = 50;

            if (aspectRatio > width / height) {
                height = width / aspectRatio;
            } else {
                width = height * aspectRatio;
            }

            // Set canvas dimensions
            canvas.width = width;
            canvas.height = height;

            // Draw the resized image
            ctx.drawImage(img, 0, 0, width, height);

            // Convert canvas back to a file
            canvas.toBlob((blob) => {
                const resizedFile = new File([blob], file.name, {
                    type: file.type,
                });
                setResizedImage(resizedFile);
            }, file.type);
        };
        img.src = event.target.result;
        };
        reader.readAsDataURL(file);
    };

    return (
        <Box>
            <Grid container>
            <Grid item xs={6} md={8}>
                <input
                    accept="image/*"
                    className={classes.fileInputField}
                    id="file-input"
                    type="file"
                    onChange={handleImageChange}
                    disabled={disabled}
                />
                <label htmlFor="file-input" disabled={disabled}
                className={classes.fileInputLabel}>
                    <Button variant="contained" component="span" 
                    className={classes.fileInputButton} disabled={disabled}>
                        Choose file
                    </Button>
                    &nbsp; &nbsp; {disabled ? '' : fileName}
                </label>
            </Grid>
            <Grid item xs={6} md={4}>
                <Box className={classes.previewBox}>
                    <img className={classes.previewImage} src={disabled ? (brandLogoPath ? `https://${pricerBucket}.s3.amazonaws.com/${brandLogoPath}?q=${Date.now()}` : '/media/brand/200x50.svg') 
                        : (resizedImage ? URL.createObjectURL(resizedImage) :  logoPath ? 
                        logoPath.includes('media/logos/') ? '/media/brand/200x50.svg' : toAbsoluteUrl(`${logoPath}?q=${Date.now()}`) 
                        : '/media/brand/200x50.svg')} alt="Image Preview" />
                </Box>
            </Grid>
            </Grid>
        </Box>
    );
};

export default ImageUploader;