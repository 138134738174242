import { Box, Divider, styled } from "@material-ui/core";
import React from "react";

export const ContentRow = ({ children, right = undefined, spacing = 1 }) =>
    <Box display="flex" flexGrow={1} flexDirection="row" margin="8px 0px" alignItems="center">
        <Box display="flex" gridGap={8 * spacing}>
            {children}
        </Box>
        <Box flexGrow={1} />
        <Box display="flex" gridGap={8 * spacing}>
            {right}
        </Box>
    </Box>

export const PortletDivider = styled(Divider)({
    margin: '4px -16px'
});