import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';

import ImportConfirmationDialogComponent from './ImportConfirmationDialog';
import { setHopsitalServicesImportConfirmationDialog, setHopsitalServicesImportDialog } from '../../../store/actions/hospitalServices';

export const ImportConfirmationDialog = connect(
    () => {
        const selectImportConfirmationDialog = (state) => state.hospitalServices.importConfirmationDialog;

        const selectOpen = createSelector(
            selectImportConfirmationDialog,
            (importHsDialog) => {
                if (importHsDialog && importHsDialog?.open) {
                    return true
                }
                return false
            }
        );

        return (state, props) => ({
            open: selectOpen(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setHopsitalServicesImportConfirmationDialog: setHopsitalServicesImportConfirmationDialog,
            setHopsitalServicesImportDialog: setHopsitalServicesImportDialog
        }, dispatch)
    })
)(injectIntl(ImportConfirmationDialogComponent));