import React, { forwardRef, ComponentProps, useEffect, useState } from "react";
import FilterListIcon from '@material-ui/icons/FilterList';
import clsx from "clsx";
import { isFragment } from "react-is";
import { useDispatch, useSelector } from "react-redux";
import { SearchTitle } from "../../common";
import { setOpenAdvanceFiltersDialog } from '../../store/actions/pages';
import { Grid, Button, makeStyles, styled, IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { RootState } from "../../store/reducers";



const useStyles = makeStyles(theme => ({
  advancedFilterBtn: {
    height: 'fit-content',
    marginTop: '25px',
    marginBottom: '8px',
    minWidth: "fit-content",
    marginLeft: "10px"
  },
  justify: {
    justifyContent: 'flex-end !important'
  },
  trashIcon: {
    color: '#d9534f',

  }
}));

export const PortletHeaderIcon = forwardRef<HTMLSpanElement, ComponentProps<'span'>>(({ className }, ref) => (
  <span ref={ref} className={clsx("kt-portlet__head-icon", className)} />
));

export const PortletHeaderTitle = forwardRef<HTMLHeadingElement, ComponentProps<'h3'>>(({ className, ...props }, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h3
    {...props}
    ref={ref}
    className={clsx("kt-portlet__head-title", className)}
  />
));

export const PortletHeaderToolbar = forwardRef<HTMLDivElement, {
  fullWidth?: boolean } & ComponentProps<'div'>>(
  ({ className, fullWidth, ...props }, ref) => 
(
    <div
      {...props}
      ref={ref}
      className={clsx("kt-portlet__head-toolbar", className, fullWidth ? "kt-portlet__head-toolbar--full" : undefined)}
    />
  )
);

interface PortletHeaderProps extends ComponentProps<'div'> {
  icon?: React.ReactNode;
  title?: string;
  toolbar?: React.ReactNode;
  name?: string;
  handleRefresh?: () => void;
  showSearchFilters?: boolean;
  showSearchFiltersDropdown?: boolean;
  shouldNotSetDefault?: boolean;
  showTitle?: boolean;
  sticky?: boolean;
  labelRef?: React.Ref<HTMLDivElement>;
  includeAdvanceFilters?: any
  handleFilterClear?: () => void
}

export const PortletHeader = forwardRef<HTMLDivElement, PortletHeaderProps>(
  (
    { icon, title, toolbar, name,
      handleRefresh, className, showSearchFilters = false, showSearchFiltersDropdown = true,
      shouldNotSetDefault = false, showTitle = false, handleFilterClear,
      sticky = false, labelRef, includeAdvanceFilters, ...props },
    ref
  ) => {
    const [top, setTop] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    const classes = useStyles();
    const dispatch = useDispatch()

    const filter = useSelector<RootState, any[]>(state => state.pages[name]?.filter || []);

    useEffect(() => {
      handleResize();

      function handleResize() {
        setWindowHeight(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    });

    useEffect(() => {
      // Skip if sticky is disabled or on initial render when we don't know about window height.
      if (!sticky || windowHeight === 0) {
        return;
      }

      const headerElement = document.querySelector(".kt-header") as HTMLElement;
      const subheaderElement = document.querySelector(".kt-subheader") as HTMLElement;
      const headerMobileElement = document.querySelector(".kt-header-mobile") as HTMLElement;


      let nextMarginTop = 0;

      // mobile header
      if (window.getComputedStyle(headerElement).height === "0px") {
        nextMarginTop = headerMobileElement.offsetHeight;
      } else {
        // desktop header
        if (document.body.classList.contains("kt-header--minimize-topbar")) {
          // hardcoded minimized header height
          nextMarginTop = 60;
        } else {
          // normal fixed header
          if (document.body.classList.contains("kt-header--fixed")) {
            nextMarginTop += headerElement.offsetHeight;
          }

          if (document.body.classList.contains("kt-subheader--fixed")) {
            nextMarginTop += subheaderElement.offsetHeight;
          }
        }
      }

      setTop(nextMarginTop);
    }, [sticky, windowHeight]);

    return (
      <div
        ref={ref}
        className={`kt-portlet__head ${title === "Statistics" && classes.justify}`}
        style={
          !sticky
            ? undefined
            : { top, position: "sticky", backgroundColor: "#fff" }
        }
      >
        {title != null && (
          <div
            ref={labelRef}
            className={`${clsx("kt-portlet__head-label", className)} ${title === "Claim Analysis" && 'mr-auto'}`}
          >
            {icon}

            {/* Wrap string and fragments in PortletHeaderTitle */
              typeof title === "string" || isFragment(title) ? (
                <PortletHeaderTitle>
                  {showSearchFilters ? (
                    <SearchTitle
                      title={title}
                      handleFilterClear={handleFilterClear}
                      name={name}
                      handleRefresh={handleRefresh}
                      shouldNotSetDefault={shouldNotSetDefault}
                      showSearchCriteriaDropdown={showSearchFiltersDropdown}
                    />
                  ) : <>
                    {showTitle && <Grid className='py-2'>{title}</Grid>}
                  </>}
                </PortletHeaderTitle>
              ) : (
                //
                <>
                  {showTitle && <h1>{title}</h1>}
                </>
              )}
          </div>
        )}
        {toolbar}
        {(!toolbar || includeAdvanceFilters) && <Button
          className={classes.advancedFilterBtn}
          onClick={() => dispatch(setOpenAdvanceFiltersDialog(true))}
          variant="contained" color='primary'

          startIcon={

            !filter.length ? <FilterListIcon /> : <></>
          }
          endIcon={
            filter.length ? <> ({<NumberChip>{filter.length}</NumberChip>})</> : <></>

          }
        >Advanced Filters</Button>}
        
        {name === "claimAnalysis-landing" && 
        <Tooltip title="Reset All Filters">
          <Button variant="outlined" className={`${classes.advancedFilterBtn} ${classes.trashIcon}`} onClick={handleFilterClear}> 
            <DeleteIcon />
          </Button>
        </Tooltip>
        }

      </div>
    );
  }
);

interface PortletBodyProps extends ComponentProps<'div'> {
  fit?: boolean | string;
  fluid?: boolean;
  className?: string;
}

export const PortletBody = forwardRef<HTMLDivElement, PortletBodyProps>(
  ({ fit, fluid, className, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      className={clsx(
        "kt-portlet__body",
        {
          "kt-portlet__body--fit": fit,
          "kt-portlet__body--fluid": fluid
        },
        className
      )}
      style={{ padding: "15px" }}
    />
  )
);

interface PortletFooterProps extends ComponentProps<'div'> {
  className?: string;
}

export const PortletFooter = forwardRef<HTMLDivElement, PortletFooterProps>(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={clsx("kt-portlet__foot", className)} />
));

interface PortletProps extends ComponentProps<'div'> {
  fluidHeight?: boolean;
  className?: string;
  fit?: string | boolean
  fluid?: string | boolean

}

export const Portlet = forwardRef<HTMLDivElement, PortletProps>(
  ({ fluidHeight, className, children, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      className={clsx(
        "kt-portlet",
        { "kt-portlet--height-fluid": fluidHeight },
        className
      )}
    >
      {children}
    </div>
  )
);


const NumberChip = styled('div')({
  fontSize: '12px !important',
  color: 'white',
  padding: '0 2.5px 0 2.5px',
  position: "relative",
  bottom: "1px"
})


// Set display names for debugging.
if (process.env.NODE_ENV !== "production") {
  Portlet.displayName = "Portlet";

  PortletHeader.displayName = "PortletHeader";
  PortletHeaderIcon.displayName = "PortletHeaderIcon";
  PortletHeaderTitle.displayName = "PortletHeaderTitle";
  PortletHeaderToolbar.displayName = "PortletHeaderToolbar";

  PortletBody.displayName = "PortletBody";
  PortletFooter.displayName = "PortletFooter";
}
