import React, { useState, useEffect } from 'react';
import {
    makeStyles, Tooltip, IconButton, Grid,
    Menu,
    MenuItem,
} from '@material-ui/core';
import Helmet from 'react-helmet';

import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../../partials/content/Portlet";
import { PagedTable } from '../../../common';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { ROLE_ADMIN, ROLE_PARTNER_ADMIN, ROLE_SUPERADMIN } from '../../../store/constants/roles';
import EditIcon from '@material-ui/icons/Edit';
import { ImportConfirmationDialog } from './ImportConfirmationDialog';
import { ModifierEditDialog } from './ModifiersEditPage';
import { DependencyDialog } from './DependencyDialog';

const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];
const SHOW_SEARCH_FILTERS = true;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },

    icon: {
        fontSize: 17,
        marginTop: 1,
    }
}));

export default function MRFModifiersPage({
    intl, MRFModifiersData, MRFModifiersFields, userRole, checkExistingPending, 
    actions: {
        MRFModifiersLanding,
        searchGetByName,
        searchReset,
        resetPagedTable,
        setList,
        setSelectedItems,
        setMRFModifiersImportConfirmationDialog,
        setMRFModifiersDependencyDialog,
        setEditDialog,
        checkExistingMRFModifiers
    }
}) {

    const [refresh, setRefresh] = useState(Promise.resolve());

    const classes = useStyles();

    function handleBulkDelete() {
        setMRFModifiersDependencyDialog({ open: true });
    }

    function handleImportMRFModifiers() {
        checkExistingMRFModifiers(handleExistingCallback);
    }

    function handleExistingCallback(isDuplicate) {
        setMRFModifiersImportConfirmationDialog({open:true, isDuplicate: isDuplicate});
    }

    function handleLoad(sort, filter, pagination) {
        MRFModifiersLanding(pagination, sort, filter);
    }

    function handleRefresh() {
        setList([]);
        setRefresh(Promise.resolve());
    }

    useEffect(() => {
        const pageName = {
            name: 'mrf-modifiers-landing'
        }
        searchGetByName(pageName);

        return function cleanup() {
            searchReset();
            resetPagedTable(pageName.name);
        }
    }, [searchGetByName]);

    const handleAddModifier = () => {
        setEditDialog({ open: true, modifier: null });
    }

    const handleEditModifier = (rowData) => {
        setEditDialog({ open: true, modifier: rowData });
    }

    function tableLevelActions() {
        return (
            <>
            <Grid item>
                    <Tooltip title="Import Modifiers">
                        <IconButton aria-label="upload" size='small'
                            onClick={handleImportMRFModifiers}
                        >
                            <i className="fas fa-upload"></i>
                        </IconButton>
                    </Tooltip>
                </Grid>
                {/* <Grid item>
                    <Tooltip title="Lookup from Master">
                        <IconButton aria-label="lookup" size='small'
                            onClick={handleLookupMRFModifiers}
                        >
                            <i className="fas fa-search"></i>
                        </IconButton>
                    </Tooltip>
                </Grid> */}
                {(userRole === ROLE_SUPERADMIN || userRole === ROLE_PARTNER_ADMIN || userRole === ROLE_ADMIN) &&
                    <Grid item>
                        <Tooltip title="Delete MRF Modifiers">
                            <IconButton aria-label="delete" size='small'
                                onClick={handleBulkDelete}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
            </>
        );
    }

    function modifierAction(rowData) {
        return <div className='d-flex'>
            <IconButton
                color="primary"
                className='p-1'
                aria-label="Edit"
                onClick={() => handleEditModifier(rowData)}
            >
                <Tooltip title="Edit">
                    <EditIcon />
                </Tooltip>
            </IconButton>
            {/* <DeleteConfirmation handleDelete={() => handleDeleteModifier(rowData)} /> */}
        </div>
    }

    return (
        <>
            <Helmet title="MRF Modifiers" />
            <Portlet>
                <PortletHeader
                    title="MRF Modifiers"
                    handleRefresh={handleRefresh}
                    showSearchFilters={SHOW_SEARCH_FILTERS}
                    showSearchFiltersDropdown={false}
                    name="mrf-modifiers-landing"
                />
                <PortletBody>
                    <div className="row">
                        <div className="col-md-12">
                            <PagedTable
                                name="mrf-modifiers-landing"
                                fields={MRFModifiersFields}
                                data={MRFModifiersData}
                                onLoad={handleLoad}
                                refresh={refresh}
                                defaultSort={DEFAULT_SORT}
                                tableLevelActions={tableLevelActions}
                                hasCheckbox={true}
                                showSelectAll={true}
                                title="MRF Modifier"
                                criteriasSaveEnabled={true}
                                showSearchFilters={SHOW_SEARCH_FILTERS}
                                onAdd={handleAddModifier}
                                hasActions={true}
                                renderActionColumn={modifierAction}
                                tableLevelLoading={checkExistingPending}
                            />
                        </div>
                    </div>
                </PortletBody>
            </Portlet>
            <DependencyDialog handleRefresh={handleRefresh} />
            <ModifierEditDialog handleRefresh={handleRefresh} />
            <ImportConfirmationDialog handleRefresh={handleRefresh} />
        </>
    );
}