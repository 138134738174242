import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { CustomLabel, Loadable } from '../../../../common';
import CloseIcon from '@material-ui/icons/Close';
import { EllipsisText } from '../../../../common/EllipsisText';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function ImportDialog({intl, open, selectedItems, importHospitalServicesPending, handleRefresh, 
    actions: { setSelected, importHospitalServices, setHopsitalServicesLookupImportConfirmationDialog, setHopsitalServicesLookupDialog }
}) {

    const classes = useStyles();

    const handleDialogClose = () => {
        setHopsitalServicesLookupImportConfirmationDialog(null);
    }

    const handleImportCallback = () => {
        setHopsitalServicesLookupImportConfirmationDialog(null);
        setHopsitalServicesLookupDialog(null);
        setSelected([]);
        handleRefresh();
        //getBackPath(currentLocation, { history, defaultRoute });
    }

    const handleImport = () => {
        importHospitalServices(selectedItems, handleImportCallback);
    }


    return (
        <Dialog maxWidth='sm' fullWidth open={open} onClose={handleDialogClose}>
            <Loadable loading={importHospitalServicesPending}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h6">
                                Confirmation
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent dividers>
                    {selectedItems.length === 0 ? <Grid>
                        <Typography>No DRGs selected. Please select atleast one for import</Typography>
                    </Grid> :
                        <Grid>
                            <Typography>Are you sure you want to import selected DRGs?</Typography>
                            <Typography>It will impacted on all shoppables and negotiated rates?</Typography>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button variant="contained" onClick={handleDialogClose} color="default">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleImport}
                        variant="contained"
                        disabled={selectedItems.length === 0 || importHospitalServicesPending}
                        color="primary"
                    >
                        Import
                    </Button>
                </DialogActions>
            </Loadable>
        </Dialog>
    )
}

export default ImportDialog
