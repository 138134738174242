import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import ContractsDroppableComponent from './ContractsDroppable';
import { moveDirectoryOrContracts } from '../../../store/actions/contractsv2';

import { setV2 } from '../../../store/actions/pagesV2';

export const ContractsDroppable = connect(
    () => {
        const selectContractIds:any = (state, props) => state.pagesV2['contractsv2-landing'].selected;

        return (state, props) => ({
            selectContractIds: selectContractIds(state)
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            moveDirectoryOrContracts: moveDirectoryOrContracts,
            resetSelectedContracts: () => setV2('contractsv2-landing', 'selected', [])
        }, dispatch)
    })
)(injectIntl(ContractsDroppableComponent as any));